import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
import arTranslation from './locales/ar.json';

const savedLanguage = localStorage.getItem('language') || 'ar';


i18n
  .use(initReactI18next) 
  .init({
    resources: {
      en: {
        translation: enTranslation, 
      },
      ar: {
        translation: arTranslation, 
      },
    },
    lng: savedLanguage, 
    fallbackLng: 'ar', 
    interpolation: {
      escapeValue: false, 
    },
  });
  i18n.on('languageChanged', (lng) => {
    localStorage.setItem('language', lng);
  });
export default i18n;
