import { Button, Col, Row } from 'antd'
import EmptyData from 'components/Shared/EmptyData';
import { autoRedeem, fetchRewards } from 'features/offers/offersSlice';
import { fetchProfile } from 'features/profile/profileSlice';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

const Rewards = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { profile } = useSelector((state) => state.profile);
  const { rewards , redeem } = useSelector((state) => state.offers);
  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchRewards());
  }, [i18n.language]);

  const redemptions =()=>{
    dispatch(autoRedeem());
  }
  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('rewards_center')}</title>
      </Helmet>
      <div>
        <h2 className='font-bold flex items-center mb-4'><i className='icon-trophy me-2'></i> {t('rewards_center')}</h2>
        <Row className='mb-8'>
          <Col span={24}>
            <div className='border-primary rounded-lg p-4 border'>
              <h2 className='font-bold flex items-center'><span class="icon-coins me-2"><span class="path1"></span><span class="path2"></span></span> {t('your_points_information')}</h2>
              <div className='flex items-center justify-between mt-4'>
                <h2>{t('your_current_points')} :</h2>
                <h2 className='flex items-center'><span className='font-bold text-primary-2 me-3 flex'>{profile?.loading ? <Skeleton style={{ width: "50px" }} /> : profile?.data?.current_points}</span>{t('point')}</h2>
              </div>
              <div className='flex items-center justify-between mt-4'>
                <h2>{t('level')} :</h2>
                <h2><span className='font-bold text-primary-2 me-3'>{profile?.loading ? <Skeleton style={{ width: "90px" }} /> : profile?.data?.level}</span></h2>
              </div>
              <div className='flex items-center justify-end mt-4'>
                <Button loading={redeem.loading} className='bg-primary-2 text-white h-auto font-size-20 font-bold py-2 px-4' onClick={redemptions}>{t('redeem_points')}</Button>
              </div>
              {/* <div className='flex items-center justify-between'>
              <h2>مستواك</h2>
              <h2><span className='font-bold text-primary-2 me-3'>متوسط</span></h2>
            </div> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className='border-primary rounded-lg p-4 border'>
              <h2 className='font-bold flex items-center mb-4'><span class="icon-coins me-2"><span class="path1"></span><span class="path2"></span></span> {t('available_offers')}</h2>
              <div className='border border-primary'>
                <Row className='bg-primary px-2 py-1 mb-2'>
                  <Col lg={6}><h2 className='text-white'>{t('number_points')}</h2></Col>
                  <Col lg={18}><h2 className='text-white'>{t('offer_description')}</h2></Col>
                </Row>
                {rewards?.loading ? <div className='p-3'><Skeleton height={20} className='mb-2' /><Skeleton height={20} style={{ width: "50%" }} /></div> : rewards?.data?.content?.length === 0 ? <EmptyData /> :
                  <>
                    {rewards?.data?.content?.map((reward) => (
                      <Row className='px-2 py-1 mb-2'>
                        <Col lg={6}><h2>{reward?.points_balance}</h2></Col>
                        <Col lg={18}><h2>{reward?.money_balance}</h2></Col>
                      </Row>
                    ))}
                  </>
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>

  )
}

export default Rewards