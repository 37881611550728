import React, { useEffect, useState } from "react";
import StepsIndicator from "./StepsIndicator.jsx";
import { Button, Col, Form, Image,  Row } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { postBooking , showBooking } from "features/booking/bookingSlice.js";
import { useDispatch, useSelector } from "react-redux";
import Profile from "components/Shared/Profile/index.jsx";
import Login from "components/Shared/Login/index.jsx";
import arrowRight2 from 'public/images/arrow-right2.svg'
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import FavoriteToggle from "components/Shared/FavoriteToggle/index.jsx";
import { Helmet } from "react-helmet";
const Step6 = () => {
  const navigate = useNavigate();
  const { t , i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { booking } = useSelector((state) => state.booking);
  
  const [statusUser, setStatusUser] = useState(false);
  const { user } = useSelector((state) => state.user);


  useEffect(() => {
    if (user?.data || localStorage.getItem("user")) {
      setStatusUser(true)
      const savedData = localStorage.getItem("bookingFormData");
      const parsedData = JSON.parse(savedData);
      dispatch(postBooking(parsedData));
    }
  }, [user])


  useEffect(() => {
    const savedData = localStorage.getItem("bookingFormData");
    const parsedData = JSON.parse(savedData);
    if (parsedData && parsedData.eye===true) {
      dispatch(showBooking(parsedData.id));
    }else{
      dispatch(postBooking(parsedData));
    }
  }, [location, navigate  , i18n.language])


  const createPayment = (id)=>{
    localStorage.setItem("bookingCheckout" , id);
    navigate('/checkout')
  }


  if (!localStorage.getItem("bookingFormData")) {
    return <Navigate to="/booking/step1" />
  }
  else if (!JSON.parse(localStorage.getItem("bookingFormData")).tourevent_id) {
    return <Navigate to="/booking/step5" />
  }

  return (
    <>
    <Helmet>
      <title>{t('nbt')} | {t('customize_your_travel_package')}</title>
    </Helmet>
    <section className="py-8">
      <div className="container">
        <StepsIndicator currentStep={5} />

        {statusUser ?
          <>
            <h2 className="text-primary-2 font-size-30 font-bold mb-3"> {t('nabat_website_pay_confirm_reservation_process')} </h2>
            {/* <Profile user={booking?.data?.user} /> : */}
            <Profile />
          </>
          :
          <>
            <h2 className="text-primary-2 font-size-30 font-bold mb-3"> {t('you_complete_reservation_process')}: </h2>
            <Login booking={true} />
          </>
        }

        <div className="py-5">
          <Row>
            <Col span={24} lg={12}>
              <h2 className="text-primary-2 font-size-24 font-bold mb-3"> <i className="icon-jewel me-2"></i> {t('summary_package')} </h2>
            </Col>
            <Col span={24} lg={12}>
              <div className="text-end">
                <FavoriteToggle
                  cardId={booking?.data?.id} // Use item.id if favorable.id is missing
                  isFavorited={booking?.data?.isFavorited} // Default to false if missing
                  loading={booking?.data.loading}
                  favorable_type={"App\\Models\\Booking"}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={`bg-light-primary p-5`}>
                {booking.loading ?
                  <>
                    <Skeleton style={{ width: "30%" }} />
                    <Skeleton style={{ width: "60%" }} />
                    <Skeleton style={{ width: "100%" }} />
                  </>
                  :
                  <>
                    <div className="mb-8">
                      <h3 className="text-primary-2 font-bold mb-7">{t('time_people_information')}</h3>
                      <Row gutter={10}>
                        <Col span={24} lg={12} className="mb-5">
                          <Row gutter={10}>
                            <Col span={15} lg={12}>
                              <div className="flex items-center">
                                <i className="icon-calendar-date w-12 font-size-24"></i>
                                <h2> {t('booking_date')}</h2>
                              </div>
                            </Col>
                            <Col span={9} lg={12}>
                              <h2>{booking?.data?.booking_date}</h2>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24} lg={12} className="mb-5">
                          <Row gutter={10}>
                            <Col span={15} lg={12}>
                              <div className="flex items-center">
                                <i className="icon-users w-12 font-size-24"></i>
                                <h2> {t('number_people_Trip')}</h2>
                              </div>
                            </Col>
                            <Col span={9} lg={12}>
                              <h2>{booking?.data?.num_adults} {t('person')}</h2>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24} lg={12} className="mb-5">
                          <Row gutter={10}>
                            <Col span={15} lg={12}>
                              <div className="flex items-center">
                                <i className="icon-users w-12 font-size-24"></i>
                                <h2> {t('estimated_arriva_Time')}</h2>
                              </div>
                            </Col>
                            <Col span={9} lg={12}>
                              <h2>{booking?.data?.arrival_time}</h2>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24} lg={12} className="mb-5">
                          <Row gutter={10}>
                            <Col span={15} lg={12}>
                              <div className="flex items-center">
                                <i className="icon-kids w-12 font-size-24"></i>
                                <h2> {t('number_cildren_the_trip')} </h2>
                              </div>
                            </Col>
                            <Col span={9} lg={12}>
                              <h2>{booking?.data?.num_children || t('not_specified')}</h2>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24} lg={12} className="mb-5">
                          <Row gutter={10}>
                            <Col span={15} lg={12}>
                              <div className="flex items-center">
                                <i className="icon-time w-12 font-size-24"></i>
                                <h2> {t('trip_duration')} </h2>
                              </div>
                            </Col>
                            <Col span={9} lg={12}>
                              <h2>{booking?.data?.trip_duration || t('not_specified')}</h2>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-8">
                      <Row>
                        <Col span={24} lg={12}>
                          <Row gutter={10}>
                            <Col span={24}>
                              <Row gutter={10}>
                                <Col><h3 className="text-primary-2 font-bold mb-7">{t('destination_information')}</h3></Col>
                              </Row>
                              <Row gutter={10} className="mb-3">
                                <Col span={15} lg={12}>
                                  <div className="flex items-center">
                                    <i className="icon-marker text-primary w-12 font-size-24"></i>
                                    <h2> {t('trip_destination')}</h2>
                                  </div>
                                </Col>
                                <Col span={9} lg={12}>
                                  <h2>{booking?.data?.destination?.name}</h2>
                                </Col>
                              </Row>
                              <Row gutter={10} className="mb-3">
                                <Col span={15} lg={12}>
                                  <div className="flex items-center">
                                    <i className="w-12 font-size-24"></i>
                                    <h2>{t('category')}</h2>
                                  </div>
                                </Col>
                                <Col span={9} lg={12}>
                                  <h2>{booking?.data?.trip_type || t('not_specified')}</h2>
                                </Col>
                              </Row>
                              <Row gutter={10} className="mb-3">
                                <Col span={15} lg={12}>
                                  <div className="flex items-center">
                                    <i className="w-12 font-size-24"></i>
                                    <h2> {t('trip_duration')} </h2>
                                  </div>
                                </Col>
                                <Col span={9} lg={12}>
                                  <h2>{booking?.data?.trip_duration}</h2>
                                </Col>
                              </Row>
                              <Row gutter={10} className="mt-12">
                                <Col><h3 className="text-primary-2 font-bold mb-7">{t('vehicle_information')}</h3></Col>
                              </Row>
                              <Row gutter={10} className="mb-7">
                                <Col span={15} lg={12}>
                                  <div className="flex items-center">
                                    <i className="icon-car text-primary w-12 font-size-24"></i>
                                    <h2>{t('car_description')}</h2>
                                  </div>
                                </Col>
                                <Col span={9} lg={12}>
                                  <h2>{booking?.data?.vehicle_type} {booking?.data?.vehicle_description || t('not_specified')}</h2>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24} lg={12}>
                          <Row gutter={10}>
                            <Col span={24} className="mb-5">
                              <Row gutter={10}>
                                <Col><h3 className="text-primary-2 font-bold mb-7">{t('accommodation_food_information')}</h3></Col>
                              </Row>
                              <Row gutter={10} className="mb-7">
                                <Col span={15} lg={12}>
                                  <div className="flex items-center">
                                    <i className="icon-hotel text-primary w-12 font-size-24"></i>
                                    <h2>{t('accommodation_name')}</h2>
                                  </div>
                                </Col>
                                <Col span={9} lg={12}>
                                  <h2>{booking?.data?.accommodation?.title}</h2>
                                </Col>
                              </Row>
                              <Row gutter={10} className="mb-3">
                                <Col span={15} lg={12}>
                                  <div className="flex items-center">
                                    <i className="icon-guest-room text-primary w-12 font-size-24"></i>
                                    <h2> {t('room_type')}</h2>
                                  </div>
                                </Col>
                                <Col span={9} lg={12}>
                                  <h2>{booking?.data?.room_type || t('not_specified')}</h2>
                                </Col>
                              </Row>
                              <Row gutter={10} className="mb-7">
                                <Col span={15} lg={12}>
                                  <div className="flex items-center">
                                    <i className="w-12 font-size-24"></i>
                                    <h2> {t('number_rooms')} </h2>
                                  </div>
                                </Col>
                                <Col span={9} lg={12}>
                                  <h2>{booking?.data?.num_rooms}</h2>
                                </Col>
                              </Row>
                              <Row gutter={10} className="mb-3">
                                <Col span={15} lg={12}>
                                  <div className="flex items-center">
                                    <i className="icon-food w-12 font-size-24"></i>
                                    <h2> {t('number_meals')} </h2>
                                  </div>
                                </Col>
                                <Col span={9} lg={12}>
                                  <h2>{booking?.data?.num_meals || t('not_specified')}</h2>
                                </Col>
                              </Row>
                              <Row gutter={10} className="mb-3">
                                <Col span={15} lg={12}>
                                  <div className="flex items-center">
                                    <i className="w-12 font-size-24"></i>
                                    <h2> {t('meal_notes')} </h2>
                                  </div>
                                </Col>
                                <Col span={9} lg={12}>
                                  <h2>{booking?.data?.meal_notes || t('not_specified')}</h2>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                    </div>
                    <div className="mb-8">
                      <h3 className="text-primary-2 font-bold mb-7">{t('activities_participated')}</h3>
                      <Row gutter={10}>
                        {booking?.data?.events?.content?.map((event) => (
                          <Col span={12} md={8} lg={6} className="mb-5" key={event.id}>
                            <div className="flex items-center">
                              <span className="icon-map w-12 font-size-24">
                                <span class="path1"></span>
                                <span class="path2"></span>
                              </span>
                              <h2> {event?.title}</h2>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                    <div className="mb-8">
                      <h3 className="text-primary-2 font-bold mb-7">{t('destination_events')}</h3>
                      <Row gutter={10}>
                        <Col span={15} lg={5}>
                          <div className="flex items-center">
                            <i className="icon-calendar-date w-12 font-size-24"></i>
                            <h2> {booking?.data?.tourevent?.name}</h2>
                          </div>
                        </Col>
                        <Col span={9} lg={19}>
                          <h2>{booking?.data?.tourevent?.body}</h2>
                        </Col>
                      </Row>
                    </div>
                    <Button  onClick={()=>createPayment(booking?.data?.id)} disabled={user?.data === null} className="h-auto bg-primary-2 py-3 font-size-20 text-white text-center w-full block">{t('book_trip')} {booking?.data?.price} {t('sar')}</Button>

                  </>
                }
              </div>
            </Col>
          </Row>
        </div>


        <Row justify="center" className="mt-14">
          <Col span={24} lg={12}>
            <div className="flex items-center justify-around w-full">
              <Form.Item>
                <Button className="text-primary border-primary font-bold px-8 py-3 h-auto font-size-18"
                  onClick={() => navigate("/booking/step5")}
                >
                  <Image src={arrowRight2} alt="arrowLeft" preview={false} className="me-1 translate-scale-1-ltr" />
                  {t('prev')}
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </div>

    </section>
    </>

  );
};

export default Step6;
