import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/error/errorSlice';

// Utility to safely handle localStorage
const safeLocalStorage = {
  getItem: (key) => {
    if (typeof window !== 'undefined') {
      try {
        const item = localStorage?.getItem(key);
        if (item) {
          try {
            return JSON.parse(item);  
          } catch (error) {
            return null;  
          }
        }
        return null;  
      } catch (error) {
        return null;
      }
    }
    return null;  
  },
  setItem: (key, value) => {
    if (typeof window !== 'undefined') {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
      }
    }
  },
};

export const sendAuth = createAsyncThunk(
  'data/sendAuth',
  async ({ values, callback }, { rejectWithValue ,dispatch}) => {
    try {
      const response = await axiosApp.post('/login', values);
      callback?.(response.data);
      return { type: 'user', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      // dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const postVerify = createAsyncThunk(
  'data/postVerify',
  async ({ values, callback }, { rejectWithValue ,dispatch}) => {
    try {
      const response = await axiosApp.post('/verify', values);
      callback?.(response.data);
      return { type: 'verify', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      // dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const logout = createAsyncThunk(
  'data/logout',
  async ({ callback }, { rejectWithValue ,dispatch}) => {
    try {
      const response = await axiosApp.post('/logout');
      callback?.(response.data.message);
      return { type: 'logout', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const logoutAll = createAsyncThunk(
  'data/logoutAll',
  async ({ callback }, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.post('/logoutFromAllDevices');
      callback?.(response.data.message);
      return { type: 'logoutAll', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial State
const initialState = {
  auth: {
    loading: false,
    error: null,
  },
  user: {
    loading: false, 
    data: safeLocalStorage.getItem('user'), 
    error: null,
  },
  logout: {
    loading: false,
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendAuth.pending, (state) => {
        state.auth.loading = true;
        state.user.error = null;
      })
      .addCase(sendAuth.fulfilled, (state, action) => {
        state.auth.loading = false;
        state.auth.error = null
        // state.user.data = action.payload.data;
        // state.user.error = null;

        // safeLocalStorage.setItem('user', state.user.data.data.user);
        // safeLocalStorage.setItem('token', `Bearer ${state.user.data.data.token}`);
      })
      .addCase(sendAuth.rejected, (state, action) => {
        state.auth.loading = false;
        state.auth.error = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.logout.loading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.logout.loading = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.logout.loading = false;
        state.logout.error = action.payload;
      })
      // postVerify
      .addCase(postVerify.pending, (state) => {
        state.user.loading = true;
      })
      .addCase(postVerify.fulfilled, (state, action) => {
        state.user.loading = false;
        state.user.data = action.payload.data;
        safeLocalStorage.setItem('user', state.user.data.data.user);
        safeLocalStorage.setItem('token', `Bearer ${state.user.data.data.token}`);

      })
      .addCase(postVerify.rejected, (state, action) => {
        state.user.loading = false;
        state.user.error = action.payload;
      })
      ;
  },
});

export default dataSlice.reducer;
