import { Col, Row } from "antd";
import { Navigate } from "react-router-dom";
import bg from "public/images/bg-layer.png";
import Login from "components/Shared/Login";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Signin() {

  const {t} = useTranslation();

  if(localStorage.getItem("user")){
    return <Navigate to="/profile" />
  }

  return (
    <div>
      <Helmet>
        <title>{t('nbt')} | {t('login')}</title>
      </Helmet>
      <section className="py-10 lg:py-20">
        <div className="container">
          <Row justify="center" className="mb-10">
            <Col lg={20} className="text-center">
              <div
                style={{
                  backgroundImage: `url(${bg})`,
                  backgroundSize: '100%',
                  backgroundPosition: 'center',
                  backgroundRepeat: "no-repeat",
                }}
              >
                <h2 className="font-size-30 font-bold text-primary-2 py-20">
                  {t('find_next_trip_easily')}
                </h2>
              </div>
            </Col>
          </Row>
          <div className="text-center mb-16">
            <h2 className="font-size-26 font-bold text-primary-2">
              {t('to_log_create_new_account_need_phone_number_process_take_minute')}
            </h2>
          </div>
          <Login/>
        </div>
      </section>
    </div>
  );
}
