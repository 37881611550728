import { notification } from 'antd';
import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/error/errorSlice';


export const fetchNotifications = createAsyncThunk(
  'data/fetchNotifications',
  async ({ params }, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get('/notifications', {
        params,
        headers: {
          perpage: 5, 
        },
      });
      return { type: 'notifications', data: response.data }; 

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchNotificationsHeader = createAsyncThunk(
  'data/fetchNotificationsHeader',
  async ({ params }, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get('/notifications', {
        params,
        headers: {
          perpage: 5, 
        },
      });
      return { type: 'notificationsHeader', data: response.data }; 

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchNotificationsSeen = createAsyncThunk(
  'data/fetchNotificationsSeen',
  async ({ params }, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get('/notifications/seen', {
        params,
        headers: {
          perpage: 5, 
        },
      });
      return { type: 'notifications', data: response.data }; 

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchNotificationsNotSeen = createAsyncThunk(
  'data/fetchNotificationsNotSeen',
  async ({ params }, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get('/notifications/not-seen', {
        params,
        headers: {
          perpage: 5, 
        },
      });
      return { type: 'notifications', data: response.data }; 

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const postMarkAsRead = createAsyncThunk(
  'data/postMarkAsRead',
  async (id, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.post('/notifications/markAsRead', { UUID: id });
      notification.success({
        description: response.data.message,
        duration: 3,
      });
      return { type: 'markAsRead', data: response.data }; 

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const postMarkAllAsRead = createAsyncThunk(
  'data/postMarkAllAsRead',
  async (_, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.post('/notifications/markAllAsRead');
      notification.success({
        description: response.data.message,
        duration: 3, 
      });
      return { type: 'markAllAsRead', data: response.data }; 

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchActivitiesHeader = createAsyncThunk(
  'data/fetchActivitiesHeader',
  async ({ params }, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get('/activities', {
        params: {
          'filters[date][0]': params.date_first, 
          'filters[date][1]': params.date_second, 
          filter_operator: "like", 
        },
        headers: {
          perpage: 5, 
        },
      });
      return { type: 'activitiesHeader', data: response.data };

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchActivities = createAsyncThunk(
  'data/fetchActivities',
  async ({ params }, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get('/activities', {
        params: {
          'filters[date][0]': params.date_first, 
          'filters[date][1]': params.date_second, 
          filter_operator: "like", 
          page:params?.page
        },
        headers: {
          perpage: 5, 
        },
      });
      return { type: 'activities', data: response.data };

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const postMarkAsReadActivity = createAsyncThunk(
  'data/postMarkAsReadActivity',
  async (id, { rejectWithValue ,dispatch}) => {
    try {
      const response = await axiosApp.post('/activities/markAsRead', { id: id });
      notification.success({
        description: response.data.message,
        duration: 3,
      });
      return { type: 'markAsRead', data: response.data }; 

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);


export const postMarkAllAsReadActivity = createAsyncThunk(
  'data/postMarkAllAsReadActivity',
  async (_, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.post('/activities/markAllAsRead');
      notification.success({
        description: response.data.message,
        duration: 3, 
      });
      return { type: 'markAllAsRead', data: response.data }; 

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial State
const initialState = {
  notifications: {
    loading: false,
    data: [],
    error: null,
    pagination: null
  },
  notificationsSeen: {
    loading: false,
    data: [],
    error: null,
  },
  notificationsNotSeen: {
    loading: false,
    data: [],
    error: null,
  },
  activities: {
    loading: false,
    data: [],
    error: null,
  },
  markAllAsRead: {
    loading: false,
    data: [],
    error: null,
  },
  unread_notifications_count: {
    loading: false,
    data: [],
    error: null,
  },
  markAllAsReadActivity: {
    loading: false,
    data: [],
    error: null,
  },
  unread_activitys_count: {
    loading: false,
    data: [],
    error: null,
  },
  activitiesHeader: {
    loading: false,
    data: [],
    error: null,
  },
  notificationsHeader: {
    loading: false,
    data: [],
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    toggleReadStatus: (state, action) => {
      const { type, notificationId } = action.payload;

      if (type === 'notification' && Array.isArray(state.notifications.data)) {
        const notificationIndex = state.notifications.data.findIndex(notif => notif.id === notificationId);
        if (notificationIndex !== -1) {
          state.notifications.data[notificationIndex].read_at = state.notifications.data[notificationIndex].read_at ? null : 'some_value';
        }
      } 
    },
   
    toggleReadStatusNotSeen: (state, action) => {
      const { notificationId } = action.payload;
      if (Array.isArray(state.notificationsNotSeen.data)) {
        const notification = state.notificationsNotSeen.data.find((notif) => notif.id === notificationId);
        if (notification) {
          notification.read_at = notification.read_at ? null : new Date().toISOString();
        }
      } 
    },
    toggleReadStatusSeen: (state, action) => {
      const { notificationId } = action.payload;
      if (Array.isArray(state.notificationsSeen.data)) {
        const notification = state.notificationsSeen.data.find((notif) => notif.id === notificationId);
        if (notification) {
          notification.read_at = notification.read_at ? null : new Date().toISOString();

        }
      } 
    },
    toggleActivities: (state, action) => {
      const { activitieId } = action.payload;
      if (Array.isArray(state.activities.data)) {
        const activitie = state.activities.data.find((activitie) => activitie.id === activitieId);
        if (activitie) {
          activitie.read_at = activitie.read_at ? null : new Date().toISOString();

        }
      } 
    },
    toggleReadStatusActivity: (state, action) => {
      const { type, activitieId } = action.payload;

      if (type === 'activitie' && Array.isArray(state.activities.data)) {
        const activitieIndex = state.activities.data.findIndex(notif => notif.id === activitieId);
        if (activitieIndex !== -1) {
          state.activities.data[activitieIndex].read_at = state.activities.data[activitieIndex].read_at ? null : 'some_value';
        }
      } 
    },
  },


  extraReducers: (builder) => {
    builder

      .addCase(fetchNotificationsHeader.pending, (state) => {
        state.notificationsHeader.loading = true;
      })
      .addCase(fetchNotificationsHeader.fulfilled, (state, action) => {
        state.notificationsHeader.loading = false;
        state.notificationsHeader.data = action.payload.data.data;
      })
      .addCase(fetchNotificationsHeader.rejected, (state, action) => {
        state.notificationsHeader.loading = false;
        state.notificationsHeader.error = action.payload;
      })
      
      .addCase(fetchActivitiesHeader.pending, (state) => {
        state.activitiesHeader.loading = true;
      })
      .addCase(fetchActivitiesHeader.fulfilled, (state, action) => {
        state.activitiesHeader.loading = false;
        state.activitiesHeader.data = action.payload.data.data;
      })
      .addCase(fetchActivitiesHeader.rejected, (state, action) => {
        state.activitiesHeader.loading = false;
        state.activitiesHeader.error = action.payload;
      })

      .addCase(postMarkAllAsRead.pending, (state) => {
        state.markAllAsRead.loading = true;
      })
      .addCase(postMarkAllAsRead.fulfilled, (state, action) => {
        state.markAllAsRead.loading = false;

        if (Array.isArray(state.notifications.data)) {
          state.notifications.data = state.notifications.data.map((notif) => ({
            ...notif,
            read_at: new Date().toISOString(),
          }));
        }
        state.unread_notifications_count = 0;
      })
      .addCase(postMarkAllAsRead.rejected, (state, action) => {
        state.markAllAsRead.loading = false;
        state.markAllAsRead.error = action.payload;
      })




      // 




      .addCase(postMarkAllAsReadActivity.pending, (state) => {
        state.markAllAsReadActivity.loading = true;
      })
      .addCase(postMarkAllAsReadActivity.fulfilled, (state, action) => {
        state.markAllAsReadActivity.loading = false;

        if (Array.isArray(state.notifications.data)) {
          state.markAllAsReadActivity.data = state.markAllAsReadActivity.data.map((notif) => ({
            ...notif,
            read_at: new Date().toISOString(),
          }));
        }
        state.unread_activitys_count = 0;
      })
      .addCase(postMarkAllAsReadActivity.rejected, (state, action) => {
        state.markAllAsReadActivity.loading = false;
        state.markAllAsReadActivity.error = action.payload;
      })







      // 

      .addCase(fetchActivities.pending, (state, action) => {
        state.activities.loading = true;

        if (action.meta?.arg?.values?.activitie_id) {
          const activitieId = action.meta.arg.values.activitie_id;
          const activitieArray = state.activities.data;
          if (Array.isArray(activitieArray)) {
            const activitieIndex = activitieArray.findIndex((notif) => notif.id === activitieId);
            if (activitieIndex !== -1) {
              activitieArray[activitieIndex].loading = true;
            }
          }
        }
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.activities.loading = false;
        state.activities.pagination = action.payload.data.data.pagination;
        state.activities.data = Array.isArray(action.payload.data.data.content) ? action.payload.data.data.content : [];

        if (action.meta?.arg?.values?.activitie_id) {
          const activitieId = action.meta.arg.values.activitie_id;
          const activitieArray = state.activities.data;

          if (Array.isArray(activitieArray)) {
            const activitieIndex = activitieArray.findIndex((notif) => notif.id === activitieId);
            if (activitieIndex !== -1) {
              activitieArray[activitieIndex].read_at = action.payload.read_at;
              activitieArray[activitieIndex].loading = false;
            }
          }
        }
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        state.activities.loading = false;
        state.activities.error = action.payload;

        if (action.meta?.arg?.values?.activitie_id) {
          const activitieId = action.meta.arg.values.activitie_id;
          const activitieArray = state.activities.data;

          if (Array.isArray(activitieArray)) {
            const activitieIndex = activitieArray.findIndex((notif) => notif.id === activitieId);
            if (activitieIndex !== -1) {
              activitieArray[activitieIndex].loading = false;
            }
          }
        }
      })




      // 

      .addCase(fetchNotificationsNotSeen.pending, (state, action) => {
        state.notificationsNotSeen.loading = true;

        if (action.meta?.arg?.values?.notification_id) {
          const notificationId = action.meta.arg.values.notification_id;
          const notificationArray = state.notificationsNotSeen.data;
          if (Array.isArray(notificationArray)) {
            const notificationIndex = notificationArray.findIndex((notif) => notif.id === notificationId);
            if (notificationIndex !== -1) {
              notificationArray[notificationIndex].loading = true;
            }
          }
        }
      })
      .addCase(fetchNotificationsNotSeen.fulfilled, (state, action) => {
        state.notificationsNotSeen.loading = false;
        state.notificationsNotSeen.pagination = action.payload.data.data.pagination;
        state.notificationsNotSeen.data = Array.isArray(action.payload.data.data.content) ? action.payload.data.data.content : [];

        if (action.meta?.arg?.values?.notification_id) {
          const notificationId = action.meta.arg.values.notification_id;
          const notificationArray = state.notificationsNotSeen.data;

          if (Array.isArray(notificationArray)) {
            const notificationIndex = notificationArray.findIndex((notif) => notif.id === notificationId);
            if (notificationIndex !== -1) {
              notificationArray[notificationIndex].read_at = action.payload.read_at;
              notificationArray[notificationIndex].loading = false;
            }
          }
        }
      })
      .addCase(fetchNotificationsNotSeen.rejected, (state, action) => {
        state.notificationsNotSeen.loading = false;
        state.notificationsNotSeen.error = action.payload;

        if (action.meta?.arg?.values?.notification_id) {
          const notificationId = action.meta.arg.values.notification_id;
          const notificationArray = state.notificationsNotSeen.data;

          if (Array.isArray(notificationArray)) {
            const notificationIndex = notificationArray.findIndex((notif) => notif.id === notificationId);
            if (notificationIndex !== -1) {
              notificationArray[notificationIndex].loading = false;
            }
          }
        }
      })



      // 





      // 

      .addCase(fetchNotificationsSeen.pending, (state, action) => {
        state.notificationsSeen.loading = true;

        if (action.meta?.arg?.values?.notification_id) {
          const notificationId = action.meta.arg.values.notification_id;
          const notificationArray = state.notificationsSeen.data;
          if (Array.isArray(notificationArray)) {
            const notificationIndex = notificationArray.findIndex((notif) => notif.id === notificationId);
            if (notificationIndex !== -1) {
              notificationArray[notificationIndex].loading = true;
            }
          }
        }
      })
      .addCase(fetchNotificationsSeen.fulfilled, (state, action) => {
        state.notificationsSeen.loading = false;
        state.notificationsSeen.pagination = action.payload.data.data.pagination;
        state.notificationsSeen.data = Array.isArray(action.payload.data.data.content) ? action.payload.data.data.content : [];

        if (action.meta?.arg?.values?.notification_id) {
          const notificationId = action.meta.arg.values.notification_id;
          const notificationArray = state.notificationsSeen.data;

          if (Array.isArray(notificationArray)) {
            const notificationIndex = notificationArray.findIndex((notif) => notif.id === notificationId);
            if (notificationIndex !== -1) {
              notificationArray[notificationIndex].read_at = action.payload.read_at;
              notificationArray[notificationIndex].loading = false;
            }
          }
        }
      })
      .addCase(fetchNotificationsSeen.rejected, (state, action) => {
        state.notificationsSeen.loading = false;
        state.notificationsSeen.error = action.payload;

        if (action.meta?.arg?.values?.notification_id) {
          const notificationId = action.meta.arg.values.notification_id;
          const notificationArray = state.notificationsSeen.data;

          if (Array.isArray(notificationArray)) {
            const notificationIndex = notificationArray.findIndex((notif) => notif.id === notificationId);
            if (notificationIndex !== -1) {
              notificationArray[notificationIndex].loading = false;
            }
          }
        }
      })



      // 


      .addCase(fetchNotifications.pending, (state, action) => {
        state.notifications.loading = true;

        if (action.meta?.arg?.values?.notification_id) {
          const notificationId = action.meta.arg.values.notification_id;
          const notificationArray = state.notifications.data;
          if (Array.isArray(notificationArray)) {
            const notificationIndex = notificationArray.findIndex((notif) => notif.id === notificationId);
            if (notificationIndex !== -1) {
              notificationArray[notificationIndex].loading = true;
            }
          }
        }
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications.loading = false;
        state.notifications.pagination = action.payload.data.data.pagination;
        state.notifications.data = Array.isArray(action.payload.data.data.content) ? action.payload.data.data.content : [];

        if (action.meta?.arg?.values?.notification_id) {
          const notificationId = action.meta.arg.values.notification_id;
          const notificationArray = state.notifications.data;

          if (Array.isArray(notificationArray)) {
            const notificationIndex = notificationArray.findIndex((notif) => notif.id === notificationId);
            if (notificationIndex !== -1) {
              notificationArray[notificationIndex].read_at = action.payload.read_at;
              notificationArray[notificationIndex].loading = false;
            }
          }
        }
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.notifications.loading = false;
        state.notifications.error = action.payload;

        if (action.meta?.arg?.values?.notification_id) {
          const notificationId = action.meta.arg.values.notification_id;
          const notificationArray = state.notifications.data;

          if (Array.isArray(notificationArray)) {
            const notificationIndex = notificationArray.findIndex((notif) => notif.id === notificationId);
            if (notificationIndex !== -1) {
              notificationArray[notificationIndex].loading = false;
            }
          }
        }
      });
    ;
  },
});
export const { toggleReadStatus, toggleReadStatusNotSeen, toggleReadStatusSeen , toggleActivities  , toggleReadStatusActivity} = dataSlice.actions;

export default dataSlice.reducer;
