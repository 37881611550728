import { Input, Form, Button, Select, Flex, Image, Upload } from 'antd';
import iconTicketQuestion from "public/images/ticket-question.svg"
import iconCamal from "public/images/camal.svg"
import ModalComponent from 'components/Ui/ModalComponent';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import logo from "public/images/logo.svg";
import { fetchCategoriesTicket, postTickets } from 'features/support/supportSlice';
import { fetchBookings } from 'features/booking/bookingSlice';
import style from "../index.module.scss"
import { Helmet } from 'react-helmet';

function NewTicket() {
  const dispatch = useDispatch();
  const { t ,i18n } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { categories, postTicket } = useSelector((state) => state.support);
  const [categorie, setCategorie] = useState(null);
  const { booking } = useSelector((state) => state.booking);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchCategoriesTicket())
  }, [i18n.language])


  const showModal = () => {
    if (categorie) {
      setIsModalVisible(true);
      dispatch(fetchBookings({
        params: { perpage: '' },
      }))
    }
  };
  const handleClose = () => {
    setIsModalVisible(false);
  };
  const handleChange = (value) => {
    setCategorie(value)
  };

  const onFinish = (values) => {
    values = {
      ...values,
      category_id: categorie
    }
    dispatch(postTickets({
      values, callback: () => {
        setIsModalVisible(false);
        form.resetFields();
        setCategorie(null);
      },
    }))
  };


  const beforeUpload = (file) => {
    return false;
  }
  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('open_support_ticket')} </title>
      </Helmet>
    <div>
      <Flex className='mb-7' align='center'>
        <Image src={iconCamal} alt='icon' />
        <h4 className='ms-4'>{t('technical_support_description')}.</h4>
      </Flex>
      <Flex className='mb-5' align='center'>
        <Image src={iconTicketQuestion} alt='icon' />
        <h4 className='ms-4'>{t('fault_tracking_resolution_system')}</h4>
      </Flex>
      <h3 className='mb-2'>{t('problem_type')}:</h3>
      <Select value={categorie} onChange={handleChange} loading={categories?.loading} disabled={categories?.loading} style={{ width: "100%" }} className='h-auto mb-4' size='large' placeholder={t('please_specify_type_problems_facing_need_open_ticket')}>
        {categories?.data?.content?.map((item) => (
          <Select.Option key={item.key} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Button disabled={!categorie} block onClick={showModal} type='primary' className='py-3 h-auto text-white'> {t('open_ticket')} </Button>
      <ModalComponent width={1000}
        isVisible={isModalVisible}
        onClose={handleClose}
        title={false}
        footer={false}>
        <div className="cursor-pointer absolute top-9 border border-primary rounded-lg px-7 py-3 font-bold text-primary inline-flex items-center font-size-16" onClick={() => handleClose()} style={{ insetInlineStart: "25px", zIndex: "2" }}> <FontAwesomeIcon icon={faArrowRight} className="me-2" /> {t('back')}</div>

        <div className="text-center mb-5">
          <Image src={logo} alt="logo" height={80} preview={false} className="mx-auto" />
        </div>

        <h2 className='font-bold'>{t('fault_tracking_resolution_system')} </h2>
        <h3 className='text-gray-400'>  {t('fill_fields_describe_issue')} </h3>


        <Form form={form} layout="vertical" size="large" onFinish={onFinish}>

          <Form.Item
            label={t('problem_description')}
            name="body"
            rules={[{ required: true, message: t('field_required') }]}
          >
            <Input.TextArea placeholder={t('please_provide_description_issue_experiencing')} rows={5} />
          </Form.Item>
          <Form.Item
            label={t('reservation_number')}
            name="booking_id"
            rules={[{ required: true, message: t('field_required') }]}
          >
            <Select placeholder={t('please_enter_booking_number_associated_flight_having_issue')}>

              {booking?.data?.content?.map((item) => (
                <Select.Option key={item.key} value={item.id}>
                  {item.reservation_number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t('add_attachments')}
            name="attachments"
            valuePropName="fileList"
            getValueFromEvent={(e) => Array.isArray(e) ? e : e?.fileList}
            rules={[{ required: false, message: t('field_required') }]}
          >
            <Upload className={` ${style.uploadNbt} w-full`}
              action="/upload"
              listType="picture-card"
              accept=".jpg,.jpeg,.png,.pdf"
              maxCount={5}
              showUploadList={{ showPreviewIcon: false, showRemoveIcon: true }}
              beforeUpload={beforeUpload}
            >
              <Button className='w-full'>{t('upload_files')}</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button loading={postTicket.loading} className="bg-primary-2 text-white font-bold px-8 py-3 h-auto font-size-18" htmlType="submit" block>
              {t('send')}
            </Button>
          </Form.Item>
        </Form>

      </ModalComponent>
    </div>
    </>

  );
};
export default NewTicket;