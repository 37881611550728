
import { Button, Space, Spin, Table } from 'antd';
import { fetchInvoices } from 'features/invoices/invoicesSlice';
import useAppParams from 'hooks/useAppParams';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import download from 'public/images/download.svg';
import eye from 'public/images/eye.svg';
import ModalComponent from 'components/Ui/ModalComponent';

const Invoices = () => {
  const dispatch = useDispatch();
  const {t , i18n} = useTranslation();
  const { handleSearch, UrlParams } = useAppParams({});
  const [data, setData] = useState();
  const { invoices } = useSelector((state) => state.invoices);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(""); 
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (UrlParams) {
      dispatch(fetchInvoices({
        params: {
          ...UrlParams,
          page: Number(UrlParams?.page || 1),
        },
      }))
    }
  }, [UrlParams , i18n.language]);

  useEffect(() => {
    if (invoices) {
      setData(invoices?.data?.content || []);
    }
  }, [invoices]);

  const columns = [
    {
      title: t('invoice_number'),
      dataIndex: ['invoice_number'],
      key: 'invoice_number',
    },
    {
      title: t('date'),
      dataIndex: ['created_at'],
      key: 'created_at',
    },
    {
      title: t('service_activity'),
      dataIndex: ['booking' , 'destination'],
      key: 'booking.destination',
    },
    {
      title: t('total'),
      dataIndex: ['total_with_tax'],
      key: 'total_with_tax',
      render: (value, record) => {
        return (
          <span>{record.total_with_tax} {t('sar')}</span>
        );
      },
    },
    {
      title: t('actions'),
      dataIndex: 'action',
      key: 'action',
      render: (value, record) => {
        return (
          <Space size="small" className='items-center'>
            <Button onClick={()=>handleDownload(record.pdf_path)} className="border-0 px-1 bg-transparent">
              <img src={download} alt="download" />
            </Button>
            <Button onClick={()=>showModal(record.pdf_path)} className="border-0 px-1 bg-transparent">
              <img src={eye} alt="eye" />
            </Button>
          </Space>
        );
      },
    },
  ];


  const handleDownload = (pdf_path) => {
    const pdfUrl = pdf_path; 
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "sample.pdf"; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const showModal = (record) => {
    setIsModalVisible(true);  
    setPdfUrl(record);
    setIsLoading(true);  
  }

  
  const handleClose = () => {
    setIsModalVisible(false);
    setPdfUrl("");
    setIsLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('payment_billing_history')} </title>
      </Helmet>
      <div>
        <h2 className='font-bold flex items-center mb-7'><i className='icon-card-pay me-3'></i> {t('payment_billing_history')}</h2>
        <Table
          columns={columns}
          loading={invoices.loading}
          locale={{ emptyText : t('no_data') }}
          dataSource={data}
          pagination={{
            pageSize: 6,
            current: Number(UrlParams?.page ? UrlParams?.page : 1),
            total: invoices?.data?.pagination?.total,
            onChange: (page) => {
              handleSearch({ fields: { page: page } });
            },
          }}
        />
      </div>
      <ModalComponent
        width={900}
        isVisible={isModalVisible}
        onClose={handleClose}
        title={null}
        footer={false}
      >

          {isLoading && (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <Spin size="large" />
          </div>
        )}

        {pdfUrl && (
          <iframe 
            src={pdfUrl} 
            width="100%" 
            height="500px" 
            style={{ border: "none" }}
            onLoad={() => setIsLoading(false)}
          />
        )}
      </ModalComponent>
    </>
  )
}

export default Invoices