import { setError } from 'features/error/errorSlice';
import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchInvoices = createAsyncThunk(
  'data/fetchInvoices',
  async (_, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/invoices`);
      return { type: 'invoices', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial State
const initialState = {
  invoices:{
    loading: false,
    data: [], 
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchInvoices
      .addCase(fetchInvoices.pending, (state) => {
        state.invoices.loading = true;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.invoices.loading = false;
        state.invoices.data = action.payload.data.data;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.invoices.loading = false;
        state.invoices.error = action.payload;
      })
      ;
  },
});

export default dataSlice.reducer;
