import React, { useEffect, useState } from 'react';
import imageSuccess from 'public/images/success.svg';
import imageError from 'public/images/error.svg';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';

const PaymentCallback = () => {
  const [paymentResult, setPaymentResult] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const result = urlParams.get('Result');
    const paymentId = urlParams.get('payment_id');

    if (result === 'Successful') {
      setPaymentResult('Successful');
      localStorage.removeItem("bookingFormData");
      localStorage.removeItem("bookingCheckout");
    } else if (result === 'Failure') {
      setPaymentResult('Failure');
    } else if (paymentId) {
      setPaymentResult('Successful');
      localStorage.removeItem("bookingFormData");
      localStorage.removeItem("bookingCheckout");
    }else{
      navigate('/')
    }

  }, []);

  
  return (
    <div className='container'>
      <div className='bg-light-primary p-5 my-12 rounded-lg'>
        <Row justify="center">
          <Col span={24} lg={12}>
            <div className='text-center'>
              <img
                src={paymentResult === 'Successful' ? imageSuccess : paymentResult === 'Failure' ? imageError : null}
                className='mt-10 mx-auto mb-6'
              />
              <h1 className='text-primary font-bold mb-4'>
                {paymentResult === 'Successful' ? t('thank_choosing_book_trip') : paymentResult === 'Failure' ? t('there_appears_problem_payment_process') : null}
              </h1>
              <h4 className='mb-8'>
                {paymentResult === 'Successful'
                  ? t('technical_support_team_will_contact_complete_order_review_special_requests_trip')
                  : paymentResult === 'Failure' ? t('please_check_information_entered_during_payment_process_try_again') : null}
              </h4>
              <Link to="/" className='btn-primary-2 btn w-100 py-2 h-auto block rounded-lg'>{t('home')}</Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PaymentCallback;
