"use client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchLandmarksSingle } from "features/home/homeSlice";
import TitlePage from "components/Shared/TitlePage";
import { Col, Row } from "antd";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";

const LandmarksSingle = () => {
  const { id } = useParams();
  const { t  , i18n} = useTranslation();
  const dispatch = useDispatch();
  const { loading: landmarksLoading ,data: landmarksSingle } = useSelector(
    (state) => state.data.landmarksSingle
  );

  useEffect(() => {
    dispatch(fetchLandmarksSingle(id));
  }, [i18n.language, id]);

  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('landmarks')}</title>
      </Helmet>
      <section className="pt-5">
        <TitlePage title={landmarksSingle?.title} />
        <div className="py-14">
          <div className="container">
            {landmarksLoading ? <><Skeleton height={30} className="mb-4" /> <Skeleton height={30} className="mb-4" /></> : 
            
            <>
            <h2 className="font-bold text-primary mb-4">{landmarksSingle?.title}</h2>
            <h4 className="mb-6">{landmarksSingle?.body}</h4>
            <Row gutter={30}>
              {landmarksSingle?.images?.content?.map((item) => (
                <Col span={12} lg={8} className="mb-6" key={item.id}>
                  <img src={item?.image} alt={item?.id} />
                </Col>
              ))}
            </Row>
            </>
            
            }
            
          </div>
        </div>
      </section>
    </>
  );
};

export default LandmarksSingle;
