import { faArrowLeft, faArrowRight, faCheck, faEye, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Image, Pagination, Row, Spin, Tabs } from "antd";
import { fetchDestinations, fetchDestinationsSingle } from "features/home/homeSlice";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
import ModalComponent from "components/Ui/ModalComponent";
import Title from "antd/es/typography/Title";
import { useLocation, useNavigate } from "react-router-dom";
import TabPane from "antd/es/tabs/TabPane";
import logo from 'public/images/logo.svg'
import GoogleMapComponent from "components/Shared/Map";
import useAppParams from "hooks/useAppParams";
import bg from "public/images/bg-layer.png";
import { fetchFavorites } from "features/favorites/favoritesSlice";
import FavoriteToggle from "components/Shared/FavoriteToggle";
import SliderThumb from "components/Shared/SliderThumb";
import TestimonialSingle from "components/Shared/TestimonialSingle";
import EmptyData from "../EmptyData";
import DetailsDestinations from "./details";
import CustomLazyImage from "hooks/customLazyImage";
const Destinations = ({ booking, onCardSelect, selectedCardId, dashboard, pagination, arrow, numberCard, favorable_type }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status1, setStatus1] = useState(1);
  const { loading: destinationsLoading, data: destinations, pagination: paginations } = useSelector((state) => state.data.destinations);
  const { loading: destinationsSingleLoading, data: destinationsSingle } = useSelector((state) => state.data.destinationsSingle);

  const { UrlParams, handleSearch } = useAppParams({});
  const [activeCard, setActiveCard] = useState(null);
  const { favorites } = useSelector((state) => state.favorites);

  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    if (!favorable_type && pagination === true && UrlParams) {
      dispatch(fetchDestinations({
        params: {
          ...UrlParams,
          perpage: numberCard,
          page: Number(UrlParams?.page ? UrlParams?.page : 1),
        },
      }));
    } else if (!favorable_type && !pagination && UrlParams) {
      dispatch(fetchDestinations({
        params: { page: status1, perpage: numberCard },
      }));
    }
  }, [status1, i18n.language, UrlParams])

  useEffect(() => {
    if (favorable_type === "Destination") {
      dispatch(
        fetchFavorites({
          params: {
            page: currentPage,
            favorable_type: favorable_type,
            perpage: numberCard,
          },
        })
      )
    }
  }, [status1, i18n.language, currentPage, favorable_type])


  const handleIncrement1 = () => {
    if (!destinationsLoading && destinations?.pagination?.has_next !== false) {
      setStatus1((prevStatus) => prevStatus + 1);
    }
  };

  const handleDecrement1 = () => {
    if (!destinationsLoading && status1 > 1) {
      setStatus1((prevStatus) => prevStatus - 1);
    }
  };

  const showModal = (id) => {
    setIsModalVisible(true);
    dispatch(fetchDestinationsSingle(id));
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };


  const containerStyle = {
    width: "100%",
    height: "300px",
    border: "1px solid #B57134",
    borderRadius: "11px"
  };

  const pageinate = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };

  const handleCardClick = (id) => {
    setActiveCard(id);
    onCardSelect(id);
  };

  useEffect(() => {
    if (selectedCardId) {
      setActiveCard(selectedCardId);
    }
  }, [selectedCardId]);


  const saveStorage = (id) => {
    if(location.pathname === "/booking/step2"){
      setIsModalVisible(false);
      handleCardClick(id)
    }else{
      const storedData = {
        destination_id: id,
      };
      localStorage.setItem("bookingFormData", JSON.stringify(storedData));
      navigate("/booking/step1")
    }
  }


  return (
    <>

      {favorable_type === "Destination" ?
        <>
          {(favorites?.loading) ? (
            <Row gutter={25}>
              {window.innerWidth > 992 ? <>{Array.from({ length: 3 }).map((_, index) => (
                <Col span={24} lg={8} md={12} key={index}>
                  <Skeleton className="mb-4" style={{ borderRadius: "16px" }} height={dashboard ? 358 : 404} />
                </Col>
              ))}</> : <>{Array.from({ length: 1 }).map((_, index) => (
                <Col span={24} lg={8} md={12} key={index}>
                  <Skeleton className="mb-4" style={{ borderRadius: "16px" }} height={dashboard ? 358 : 404} />
                </Col>
              ))}</>}

            </Row>
          ) : (favorites?.data?.content?.length > 0) ? (
            <>
              <Row gutter={25}>
                {(favorites?.data?.content || []).map((item) => (
                  <Col span={24} lg={8} md={12} key={item.id}>
                    <div className={`relative ${style.widget_itemDestnation} ${activeCard === item.id ? style.selected : ''} mb-4 ${booking ? `${style.booking} cursor-pointer` : ''}`} onClick={() => booking ? handleCardClick(item.id) : null}>
                      {localStorage.getItem("user") && (
                        <FavoriteToggle
                          cardId={item?.favorable?.id} // Use item.id if favorable.id is missing
                          isFavorited={item?.favorable?.isFavorited} // Default to false if missing
                          loading={item.loading}
                          favorable_type={"App\\Models\\Destination"}
                          dashboard={dashboard}
                        />
                      )}

                      {booking && (
                        <div className={`${activeCard === item.id ? style.checked : ''} ${style.widget_itemCheck} bg-white top-[30px] w-[40px] h-[40px] rounded-full absolute flex items-center justify-center font-size-26 z-2`}>
                          {activeCard === item.id ? <FontAwesomeIcon icon={faCheck} /> : ''}
                        </div>
                      )}

                      <div className={`${style.widget_itemImage}`}>
                        <CustomLazyImage
                          src={item?.favorable?.image} 
                          alt={item?.favorable?.name}
                          height={dashboard ? 136 : 230}
                        />
                      </div>

                      <div className="widget_item-content px-5 py-5">
                        <div className="text-center">
                          <Title className="font-size-18 font-bold text-primary">
                            {item?.favorable?.name}
                          </Title>
                        </div>

                        <div className="flex justify-between items-center mb-4">
                          <h6 className="px-2 py-1 font-size-12 bg-primary text-white rounded-full">{item?.favorable?.category?.name}</h6>
                          <h6 className="text-primary font-bold">
                            {item?.favorable?.rating}
                            <FontAwesomeIcon icon={faStar} className="text-primary font-size-12" />
                          </h6>
                        </div>

                        <Title className={`${style.widget_itemText} font-size-16 font-bold`}>
                          {item?.favorable?.body}  {/* تحقق من النص في favorable أولًا ثم item */}
                        </Title>

                        {!booking ? (
                          <div className="text-end mt-5">
                            <Button onClick={() => showModal(item?.favorable?.id)} className={`${dashboard ? 'font-size-10' : ''} btn btn-primary p-2 rounded-md h-auto`}>
                              <FontAwesomeIcon className="ms-1" icon={faEye} /> {t('more_read')}
                            </Button>
                          </div>
                        ) : null}
                      </div>

                      {booking ? (
                        <Button onClick={() => showModal(item?.favorable?.id)} className="btn btn-primary p-3 h-auto w-full">
                          {t('more_read')}
                        </Button>
                      ) : (
                        <Button onClick={() => saveStorage(item.id)} className={`${style.widget_itemAction} ${dashboard ? 'py-[8px]' : 'py-[15px]'} btn btn-primary-2 w-full rounded-none flex items-center justify-center hover-btn-primary-2 text-white`}>
                          {t('book_now')}
                        </Button>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
              <Pagination
                align="end"
                className="my-3"
                onChange={(page) => setCurrentPage(page)}
                defaultCurrent={1}
                current={currentPage}
                total={favorites?.data?.pagination?.total}
                pageSize={numberCard}
              />
            </>

          ) : (
            <EmptyData />
          )}
        </> : <>
          {(destinationsLoading) ? (
            <Row gutter={25}>
              {Array.from({ length: 3 }).map((_, index) => (
                <Col span={24} lg={8} md={12} key={index}>
                  <Skeleton className="mb-4" style={{ borderRadius: "16px" }} height={dashboard ? 358 : 404} />
                </Col>
              ))}
            </Row>
          ) : (destinations?.length > 0) ? (
            <Row gutter={25}>
              {(destinations?.length > 0 ? destinations : []).map((item) => (
                <Col span={24} lg={8} md={12} key={item.id}>
                  <div className={`relative ${style.widget_itemDestnation} ${activeCard === item.id ? style.selected : ''} mb-4 ${booking ? `${style.booking} cursor-pointer` : ''}`} onClick={() => booking ? handleCardClick(item.id) : null}>
                    {localStorage.getItem("user") && (
                      <FavoriteToggle
                        cardId={item.id} // Use item.id if favorable.id is missing
                        isFavorited={item?.isFavorited} // Default to false if missing
                        loading={item.loading}
                        favorable_type={"App\\Models\\Destination"}
                        dashboard={dashboard}
                      />
                    )}

                    {booking && (
                      <div className={`${activeCard === item.id ? style.checked : ''} ${style.widget_itemCheck} bg-white top-[30px] w-[40px] h-[40px] rounded-full absolute flex items-center justify-center font-size-26 z-2`}>
                        {activeCard === item.id ? <FontAwesomeIcon icon={faCheck} /> : ''}
                      </div>
                    )}

                    <div className={`${style.widget_itemImage}`}>
                      <CustomLazyImage
                        src={item?.image} 
                        alt={item?.name}
                        height={dashboard ? 136 : 230}
                      />
                    </div>

                    <div className="widget_item-content px-5 py-5">
                      <div className="text-center">
                        <Title className="font-size-18 font-bold text-primary">
                          {item?.name}
                        </Title>
                      </div>

                      <div className="flex justify-between items-center mb-4">
                        <h6 className="px-2 py-1 font-size-12 bg-primary text-white rounded-full">{item?.category?.name}</h6>
                        <h6 className="text-primary font-bold">
                          {item?.rating || 0}
                          <FontAwesomeIcon icon={faStar} className="text-primary font-size-12" />
                        </h6>
                      </div>

                      <Title className={`${style.widget_itemText} font-size-16 font-bold`}>
                        {item?.body} 
                      </Title>

                      {!booking ? (
                        <div className="text-end mt-5">
                          <Button onClick={() => showModal(item.id)} className={`${dashboard ? 'font-size-10' : ''} btn btn-primary p-2 rounded-md h-auto`}>
                            <FontAwesomeIcon className="ms-1" icon={faEye} /> {t('more_read')}
                          </Button>
                        </div>
                      ) : null}
                    </div>

                    {booking ? (
                      <Button 
                      onClick={(e) => {
                        e.stopPropagation();  
                        showModal(item.id); 
                      }} 
                       className="btn btn-primary p-3 h-auto w-full">
                        {t('more_read')}
                      </Button>
                    ) : (
                      <Button onClick={(e) => { e.stopPropagation(); saveStorage(item.id)}} className={`${style.widget_itemAction} ${dashboard ? 'py-[8px]' : 'py-[15px]'} btn btn-primary-2 w-full rounded-none flex items-center justify-center hover-btn-primary-2 text-white`}>
                        {t('book_now')}
                      </Button>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <EmptyData />
          )}</>}

      {/* {favorites.loading ?  : null} */}



      {destinationsLoading ? '' : pagination ? <Pagination
        align="end"
        className="my-3"
        onChange={(number) => pageinate(number)}
        defaultCurrent={1}
        current={UrlParams?.page ? Number(UrlParams?.page) : 1}
        total={paginations?.total}
        pageSize={numberCard}
      /> : arrow ? <Row>
        <Col span={24}>
          <div className="flex justify-center items-center mt-10">
            <button
              onClick={handleDecrement1}
              disabled={status1 === 1 || destinationsLoading}
              className="prev-pageination bg-primary text-white w-12 h-12 rounded-tl-xl rounded-br-xl flex items-center justify-center mx-10 cursor-pointer"
            >
              <FontAwesomeIcon className="text-lg" icon={faArrowRight} />
            </button>
            <button
              onClick={handleIncrement1}
              disabled={destinations?.pagination?.has_next === false || destinationsLoading}
              className="next-pageination bg-primary text-white w-12 h-12 rounded-tr-xl rounded-bl-xl flex items-center justify-center mx-10 cursor-pointer"
            >
              <FontAwesomeIcon className="text-lg" icon={faArrowLeft} />
            </button>
          </div>
        </Col>
      </Row> : null
      }





      <ModalComponent width={1000}
        isVisible={isModalVisible}
        onClose={handleClose}
        title={false}
        footer={false}
      >
        <div className="cursor-pointer absolute font-bold top-9 border border-primary rounded-lg px-7 py-3  text-primary inline-flex items-center font-size-16" onClick={() => handleClose()} style={{ insetInlineStart: "25px", zIndex: "2" }}> <FontAwesomeIcon icon={faArrowRight} className="me-2" /> {t('back')}</div>

        <div className="text-center mb-5">
          <Image src={logo} alt="logo" height={80} preview={false} className="mx-auto" />
        </div>
        {destinationsSingleLoading ? <div className="text-center"><Spin /> </div> : (
          <div className="p-4" style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: '80%',
            backgroundPosition: 'center',
            backgroundRepeat: "no-repeat",
          }}>
            <div className="lg:flex items-center justify-between mb-3">
              <h2 className="mb-3 lg:mb-0 font-bold">{t('detailed_information_about')}  <span className="text-primary">{destinationsSingle?.name} :</span></h2>
              <div className="justify-start items-center gap-[2.96px] inline-flex">
                <h2 className="text-primary font-bold">{destinationsSingle?.rating} <FontAwesomeIcon icon={faStar} /> </h2>
              </div>
            </div>
            <Tabs defaultActiveKey="1" centered className="tab-napt mb-6">
              <TabPane tab={t('general_information')} key="1">
                <Row align="middle">
                  <Col span={24} lg={17} className="mb-5 lg:mb-0" dir="rtl">
                    <SliderThumb count={9} images={destinationsSingle?.images?.content} />
                  </Col>
                  <Col span={24} lg={7} className="lg:ps-6">
                    <DetailsDestinations name={destinationsSingle?.name} weather_description={destinationsSingle?.weather_description}
                      amenities={destinationsSingle?.amenities} annual_visitors_min={destinationsSingle?.annual_visitors_min}
                      annual_visitors_max={destinationsSingle?.annual_visitors_max} recommended_visit_season={destinationsSingle?.recommended_visit_season} />
                    {/* <h2 className="font-bold text-black mb-7">{destinationsSingle?.name}</h2>
                    <div className="flex items-center mb-5 lg:mb-7">
                      <Image src={weather} alt="weather" />
                      <div className="ms-3">
                        <h6 className="font-size-14">{t('weather')}</h6>
                        <h6 className="font-size-14">{destinationsSingle?.weather_description}</h6>
                      </div>
                    </div>
                    <div className="flex items-center mb-5 lg:mb-7">
                      <Image src={workplace} alt="weather" />
                      <div className="ms-3">
                        <h6 className="font-size-14">{t('available_facilities')}</h6>
                        <h6 className="font-size-14">{destinationsSingle?.amenities}</h6>
                      </div>
                    </div>
                    <div className="flex items-center mb-5 lg:mb-7">
                      <Image src={people} alt="weather" />
                      <div className="ms-3">
                        <h6 className="font-size-14">{t('average_annual_visitors')}</h6>
                        <h6 className="font-size-14">{destinationsSingle?.annual_visitors_min} <span className="mx-1 inline-block"></span> {destinationsSingle?.annual_visitors_max}</h6>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Image src={like} alt="weather" />
                      <div className="ms-3">
                        <h6 className="font-size-14"> {t('visit_during_summer')} </h6>
                        <h6 className="font-size-14">{destinationsSingle?.recommended_visit_season}</h6>
                      </div>
                    </div> */}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={t('venue_details')} key="2">
                <div className="mb-4">
                  <div dangerouslySetInnerHTML={{ __html: destinationsSingle?.description }} />
                </div>
              </TabPane>
              <TabPane tab={t('map_ratings')} key="3">
                <Row gutter={20} className="mt-3" justify="space-between">
                  <Col span={24} lg={10} className="mb-5 lg:mb-0">
                    <h6 className="text-primary mb-4 font-bold">{t('location_map')}</h6>
                    <GoogleMapComponent lat={destinationsSingle?.lat} lng={destinationsSingle?.lng} containerStyle={containerStyle} />
                  </Col>
                  <Col span={24} lg={13}>
                    <h6 className="text-primary mb-4 font-bold">{t('reviews')}</h6>
                    <TestimonialSingle testimonial={destinationsSingle?.testimonials?.content} limit={true} />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
            <Button onClick={() => saveStorage(destinationsSingle.id)} className="h-auto w-full bg-primary-2 block rounded-lg py-3 font-bold font-size-18 text-white text-center">{t('select_venue')}</Button>
          </div>
        )}
      </ModalComponent>
    </>
  );
};

export default Destinations;
