import { setError } from 'features/error/errorSlice';
import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAbout = createAsyncThunk(
  'data/fetchAbout',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApp.get(`/about_us`);
      return { type: 'about', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchNumber = createAsyncThunk(
  'data/fetchNumber',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApp.get(`/numbers`);
      return { type: 'numbers', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

// الحالة الأولية
const initialState = {
  about: { loading: false, data: [], error: null },
  number: { loading: false, data: [], error: null },
};

// Slice
const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // عن الشركة
      .addCase(fetchAbout.pending, (state) => {
        state.about.loading = true;
        state.about.error = null;
      })
      .addCase(fetchAbout.fulfilled, (state, action) => {
        state.about.loading = false;
        state.about.data = action.payload.data.data;
      })
      .addCase(fetchAbout.rejected, (state, action) => {
        state.about.loading = false;
        state.about.error = action.payload;
      })
      // الأرقام
      .addCase(fetchNumber.pending, (state) => {
        state.number.loading = true;
        state.number.error = null;
      })
      .addCase(fetchNumber.fulfilled, (state, action) => {
        state.number.loading = false;
        state.number.data = action.payload.data.data;
      })
      .addCase(fetchNumber.rejected, (state, action) => {
        state.number.loading = false;
        state.number.error = action.payload;
      });
  },
});

export default dataSlice.reducer;
