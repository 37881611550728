
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Form, Image, Input, Modal, notification, Row, Select } from "antd";
import { useEffect, useState } from "react";
import logo from "public/images/logo.svg";
import { sendAuth , postVerify } from "features/signin/signinSlice";
import { useNavigate } from "react-router-dom";
import { fetchCountries } from "features/profile/profileSlice";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
const { Option } = Select;

const Login = ({booking ,  blog}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { user , auth } = useSelector((state) => state.user); 
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countryId, setCountryId] = useState();
  const [stateUser, setStateUser] = useState();
  const { countries } = useSelector((state) => state.profile);
  const [otpValue, setOtpValue] = useState(["", "", "", ""]);
  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  const onFinish = (values) => {
    values ={
      ...values,
      country_id:countryId
    }
    dispatch(sendAuth({
      values, callback: (response) => {
        setIsModalOpen(true);
        setStateUser(response.data)
        setOtpValue(response.data.valid_code)
        notification.success({
          // message: 'Success!',
          description: response.message,
          duration: 3, 
        });
        // if(booking){
          
        // }else{
        //   navigate("/booking/step1"); 
        // }
      },
    }));
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCountryChange = (value, option) => {
    setCountryId(option.key); 
  };
    

  useEffect(() => {
    if (stateUser?.valid_code) {
      setOtpValue(stateUser.valid_code.split("")); 
    } else {
      setOtpValue(["", "", "", ""]); 
    }
  }, [stateUser]);
  

  const onInput = (value) => {
    setOtpValue(Array.isArray(value) ? value : ["", "", "", ""]);
  };

  const isButtonDisabled = !Array.isArray(otpValue) || otpValue.some((val) => val === "") || otpValue.length < 4;


  const sharedProps = {
    onInput,
  };

  const onFinishOtp = ()=>{
  const values={
    code: otpValue.join(""), 
    phone: stateUser.phone,
    country_id:stateUser.country_id,
    prefix:stateUser.prefix,
  }
  dispatch(postVerify({
    values,
    callback:(response)=>{
    notification.success({
      // message: 'Success!',
      description: response.message,
      duration: 3, 
    });
    if(booking){
          
    }else{
      navigate("/booking/step1"); 
      // localStorage.removeItem("bookingFormData");
    }
    }
  }))
  }
  return (
    <>
      {auth?.error ? <Alert message={auth?.error} type="error" /> : null}
      <Form
        size="large"
        name="basic"
        labelCol={{
          span: 24,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={15}>
          <Col span={24}>
            {blog ? <h2 className="text-white font-size-30 mb-3">{t('login_comment_article')}</h2> : null}
            
            <h4 className={`mb-3 mt-4 ${blog ? 'text-white' : ''}`}>{t('login_with_your_phone_number')}</h4>
          </Col>
          <Col span={8} lg={4}>
            <Form.Item
              label=""
              name="prefix"
              rules={[
                {
                  required: true,
                  message: t('field_required'),
                },
              ]}
            >
              <Select
                placeholder={t('country')}
                loading={countries?.loading}
                disabled={countries?.loading}
                style={{ width: '100%' }}
                onChange={handleCountryChange}
              >
                {countries?.data?.content?.length > 0 ? (
                  countries.data.content.map((country) => (
                    <Select.Option key={country?.id} value={country?.prefix}>
                      <span className="flex items-center">
                      <img
                        src={country?.flag} 
                        alt={`${country?.name} flag`}
                        style={{
                          width: '20px',
                          height: '15px',
                          marginInlineEnd: '8px',
                          verticalAlign: 'middle',
                        }}
                      />
                      {country.prefix}
                      </span>
                    </Select.Option>
                  ))
                ) : (
                  <Option disabled>{t('no_countries_available')}</Option>
                )}
              </Select>

            </Form.Item>
          </Col>
          <Col span={16} lg={20}>
            <Form.Item
              label=""
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('field_required'),
                },
                {
                  type: "number",
                  message: t('enter_valid_number'),
                  transform: (value) => (value ? Number(value) : undefined), 
                },
              ]}
            >
              <Input placeholder={t('enter_phone_number_login')} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <h6 className={`mb-2 text-end ${blog ? 'text-white' : ''}`}>{t('digit_verification_code_will_sent_number')}</h6>
            <h4 className={`mb-7 font-bold ${blog ? 'text-white' : 'text-primary-2'}`}>
              {t('clicking_continue_agree_terms_privacy_policy')}.
            </h4>
          </Col>
        </Row>

        <Form.Item>
          <Button loading={auth?.loading}
            htmlType="submit"
            className="w-full bg-primary-2 py-4 border-0 text-white rounded-lg font-bold h-auto"
          >
            {t('continue')}
          </Button>
        </Form.Item>
      </Form>
      {/* <button
            className="btn btn-primary w-100 rounded-pill font-12 px-3"
            onClick={showModal}
          >
            jgb
          </button> */}
      <Modal
        title={false}
        footer={false}
        width={800}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="cursor-pointer absolute font-bold top-9 border border-primary rounded-lg px-7 py-3  text-primary inline-flex items-center font-size-16" onClick={() => handleCancel()} style={{ insetInlineStart: "25px", zIndex: "2" }}> <FontAwesomeIcon icon={faArrowRight} className="me-2" /> {t('back')}</div>
        
        <div className="text-center mb-10">
          <img className="mx-auto mb-10" src={logo} alt="logo" />
          <h2 className="font-bold text-primary text-center">
           {t('enter_digit_code_sent_number')}
          </h2>
        </div>
        {user?.error ? <Alert message={user?.error} type="error" /> : null}
        <div className="mb-12">
          <Input.OTP value={stateUser?.valid_code} length={4} {...sharedProps} />
        </div>

        <Button loading={user?.loading} disabled={isButtonDisabled} onClick={onFinishOtp} size="large" block className="w-full bg-primary-2 py-4 border-0 text-white rounded-lg font-bold h-auto">
            {t('send')}
        </Button>
      </Modal>
    </>
  );
};

export default Login;
