import { Button, Col, Form, Image, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from "react-redux";
import logo from 'public/images/logo-white.svg'
import { fetchDataPages } from "features/pages/dataPagesSlice";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { postSubscribe } from "features/home/homeSlice";
import { useTranslation } from "react-i18next";
import SocialMedia from "components/Shared/SocialMedia";
const Footer = () => {

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { dataPages } = useSelector((state) => state.dataPages);
  const { subscribe } = useSelector((state) => state.data);
  const { settings } = useSelector((state) => state.settings);


  const [copyright_en, setCopyright_en] = useState([]);
  const [copyright_ar, setCopyright_ar] = useState([]);



  useEffect(() => {
    dispatch(fetchDataPages())
  }, [i18n.language]);

  const onFinish = (values) => {
    dispatch(postSubscribe({
      values, callback: (message) => {
        notification.success({
          // message: 'Success!',
          description:message,
          duration: 3,
        });
        form.resetFields();
      },
    }));
  };

  useEffect(() => {
    if (settings && settings?.data?.content?.length > 0) {
      const result = settings?.data.content.filter(item => item.id === 13);
      setCopyright_en(result);
    }
    if (settings && settings?.data?.content?.length > 0) {
      const result = settings?.data.content.filter(item => item.id === 14);
      setCopyright_ar(result);
    }
  }, [settings]);

  return (
    <>
      <footer className={`${styles.mainFooter} bg-primary`}>
        <div className="container">
          <Row gutter={30}>
            <Col span={24} md={12} lg={5}>
              <h2 className={`${styles.titleMenuFooter} text-white lg:mb-2 font-bold`}>{t('company')}</h2>
              <ul className={`${styles.menuFooter} mb-7 lg:mb-0`}>
                <li>
                  <Link to="/about-us">{t('about_company')}</Link>
                </li>
                <li>
                  <Link to="/contact-us">{t('contact_us')}</Link>
                </li>
                <li>
                  <Link to="">{t('our_partners')}</Link>
                </li>
              </ul>
            </Col>
            <Col span={24} md={12} lg={5}>
              <h2 className={`${styles.titleMenuFooter} text-white lg:mb-2 font-bold`}>{t('information_center')}</h2>
              <ul className={`${styles.menuFooter} mb-7 lg:mb-0`}>
                <li>
                  <Link to="/faq">{t('faq')}</Link>
                </li>
                <li>
                  <Link to="/blogs">{t('blog')}</Link>
                </li>
                <li>
                  <Link to="">{t('technical_support')}</Link>
                </li>
              </ul>
            </Col>
            <Col span={24} md={12} lg={5}>
              <h2 className={`${styles.titleMenuFooter} text-white lg:mb-2 font-bold`}>{t('service')}</h2>
              <ul className={`${styles.menuFooter} mb-7 lg:mb-0`}>
                <li>
                  <Link to="">{t('book_flights')}</Link>
                </li>
              </ul>
            </Col>
            <Col span={24} md={12} lg={9}>
              <div className={`${styles.formSubscribe} px-7 pt-4 pb-10`}>
                <h5 className="font-bold text-white mb-4"> {t('subscribe_newsletters')} </h5>
                <h6 className="text-white mb-1">{subscribe?.error}</h6>
                <Form size="large"
                  className={` ${styles.form}`}
                  form={form}
                  name="form"
                  labelCol={{
                    span: 24,
                  }}
                  onFinish={onFinish}
                  autoComplete="off" >
                  <Form.Item
                    className={styles.formItem}
                    name="email"
                    rules={[{ required: true, message: t('field_required') },
                    {
                      type: 'email',
                      message: t('enter_email_valid'),
                    },
                    ]}
                  >
                    <Input placeholder={t('enter_email')} />
                  </Form.Item>
                  <Button loading={subscribe?.loading} className={styles.submitButton} type='primary' htmlType='submit'><FontAwesomeIcon className="ms-1" icon={faArrowLeft} /> </Button>
                </Form>
                <h6 className="font-size-14 text-white mt-4"> {t('subscribe_newsletters_text')} </h6>
              </div>
            </Col>
          </Row>
          <div className={`${styles.dividerFooter} bg-primary`}></div>
          <Row gutter={20} align="top">
            <Col span={24} lg={6} className="mt-2">
              <Image preview={false} height={66} src={logo} alt="" />
            </Col>
            <Col span={24} lg={14} className="mt-2">
              <ul className={`${styles.socialMedia} flex flex-wrap items-center text-white`}>
                {dataPages?.data?.content?.length > 0 ? (
                  dataPages?.data?.content.map((item) => (
                    <li key={item.id} className="lg:w-1/3 w-1/2">
                      <Link to={`/pages/${item.id}`} className="text-white py-3 inline-block">{item.title}</Link>
                    </li>
                  ))
                ) : null}
              </ul>
            </Col>
            <Col span={24} lg={4} className="mt-6">
              <SocialMedia />
            </Col>
          </Row>
        </div>
      </footer>
      <div className="bg-white py-3 text-center">
        <div className="text-primary font-bold">
          {currentLanguage === 'ar' && copyright_ar.length > 0 ? (
            copyright_ar.map((item) => (
              <div key={item.id}> {item.value}</div>
            ))
          ) : (
            copyright_en.map((item) => (
              <div key={item.id}>{item.value}</div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Footer;
