import { Breadcrumb, Col, Row } from 'antd';
import SliderThumb from 'components/Shared/SliderThumb';
import TestimonialSingle from 'components/Shared/TestimonialSingle';
import TitlePage from 'components/Shared/TitlePage';
import { fetchDestinationsSingle } from 'features/home/homeSlice';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import DetailsDestinations from 'components/Shared/Destinations/details';
const DestinationsSingle = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const { loading: destinationsSingleLoading, data: destinationsSingle } = useSelector((state) => state.data.destinationsSingle);

  useEffect(() => {
    dispatch(fetchDestinationsSingle(id));
  }, [i18n.language])


  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('destinations')}</title>
      </Helmet>
      <TitlePage title={t('destinations')} bg={true} />
      <section className='py-14'>
        <div className='container'>
          <Breadcrumb className='font-size-20 mb-6'>
            <Breadcrumb.Item>
              <Link to="/">
                <i className='icon-home me-2'></i>
                {t('home')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/destinations">
                {t('destinations')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{destinationsSingle?.name}</Breadcrumb.Item>
          </Breadcrumb>
          {destinationsSingleLoading ? <> <Skeleton height={30} className='mb-4' style={{ width: "50%" }} />
            <Skeleton height={30} style={{ width: "100%" }} /></> : <>
            <Row align="middle" className="mb-8">
              <Col span={24} lg={16} className="mb-5 lg:mb-0" dir="rtl">
                <SliderThumb images={destinationsSingle?.images?.content} />
              </Col>
              <Col span={24} lg={8} className="lg:ps-6">
              <DetailsDestinations name={destinationsSingle?.name} weather_description={destinationsSingle?.weather_description}
                amenities={destinationsSingle?.amenities} annual_visitors_min={destinationsSingle?.annual_visitors_min} 
                annual_visitors_max={destinationsSingle?.annual_visitors_max} recommended_visit_season={destinationsSingle?.recommended_visit_season} />
              </Col>
            </Row>
            <Row align="middle" className="mb-5">
              <Col span={24}>
                <div className="mb-4" style={{ minHeight: "100px" }}>
                  <div dangerouslySetInnerHTML={{ __html: destinationsSingle?.description }} />
                </div>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span={24}>
                <h6 className="text-primary mb-4 font-bold">{t('reviews')}</h6>
                <TestimonialSingle testimonial={destinationsSingle?.testimonials?.content} limit={true} />
              </Col>
            </Row>
          </>}

        </div>
      </section>
    </>

  )
}

export default DestinationsSingle