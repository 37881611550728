import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/error/errorSlice';

// Async Thunks
export const fetchHero = createAsyncThunk(
  'data/fetchHero',
  async (_, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/home_page_hero_section`);
      return { type: 'hero', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchAboutUs = createAsyncThunk(
  'data/fetchAboutUs',
  async (_, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/home_page_aboutUs_section`);
      return { type: 'aboutUs', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchTourevents = createAsyncThunk(
  'data/fetchTourevents',
  async (params, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/tourevents`, { params: params.params, headers: { perpage: params.params.perpage } });
      return { type: 'tourevents', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchToureventsSingle = createAsyncThunk(
  'data/fetchToureventsSingle',
  async (id, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/tourevents/${id}`);
      return { type: 'toureventsSingle', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchDestinations = createAsyncThunk(
  'data/fetchDestinations',
  async ({ params ,callback }, { rejectWithValue ,dispatch }) => {
    try {
      const cleanObject = (obj) => {
        return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== ''));
      };

      const filters = cleanObject({
        name: params?.name || '',
        category_id: params?.category_id || '',
        rating_number: params?.rating_number || '',
      });

      const requestParams = {
        page: params?.page || 1,
        filters: Object.keys(filters).length > 0 ? filters : undefined, 
        filter_operator: Object.keys(filters).length > 0 ? "like" : undefined, 
      };

      const response = await axiosApp.get('/destinations', {
        params: requestParams, 
        headers: {
          perpage: params.perpage, 
        },
      });

      callback?.();

      return { type: 'destinations', data: response.data };
    } catch (error) {
      // notification.error({
      //   message: error?.response?.data?.data || error.message
      // })
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);



export const fetchDestinationsSingle = createAsyncThunk(
  'data/fetchDestinationsSingle',
  async (id, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/destinations/${id}`);
      return { type: 'destinationsSingle', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);



export const fetchTestimonials = createAsyncThunk(
  'data/fetchTestimonials',
  async (params, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/testimonials`, { params: { page: params.params.page }, headers: { perpage: params.params.perpage } });
      return { type: 'testimonials', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchSteps = createAsyncThunk(
  'data/fetchSteps',
  async (_, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/steps`);
      return { type: 'steps', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchPartners = createAsyncThunk(
  'data/fetchPartners',
  async (params, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/partners`, { params: { page: params.params.page }, headers: { perpage: 4 } });
      return { type: 'partners', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const postSubscribe = createAsyncThunk(
  'data/postSubscribe',
  async ({ values, callback }, { rejectWithValue ,dispatch}) => {
    try {
      const response = await axiosApp.post(`/subscribe`, values);
      callback?.(response.data.message)
      return { type: 'subscribe', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const switchfavourite = createAsyncThunk(
  'data/switchfavourite',
  async ({ values, callback }, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.post('/favorites', values);
      callback?.(response.data);
      return { type: 'subscribe', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);



export const fetchAccommodations = createAsyncThunk(
  'data/fetchAccommodations',
  async ({ params }, { rejectWithValue ,dispatch }) => {
    try {
      const filterType = params?.type === "All" ? '' : params?.type || '';
      const response = await axiosApp.get(`/accommodations`, {
        params: {
          filters: {
            type: filterType,  
          },
          page: params?.page ? params?.page : 1, 
          title: params?.title || "",  
        }, headers: {
          perpage: params.perpage,
        },
      });
      return { type: 'accommodations', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchAccommodationSingle = createAsyncThunk(
  'data/fetchAccommodationSingle',
  async (id, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/accommodations/${id}`);
      return { type: 'accommodationSingle', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchLandmarks = createAsyncThunk(
  'data/fetchLandmarks',
  async (id, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/landmarks`);
      return { type: 'landmarks', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchLandmarksSingle = createAsyncThunk(
  'data/fetchLandmarksSingle',
  async (id, { rejectWithValue ,dispatch}) => {
    try {
      const response = await axiosApp.get(`/landmarks/${id}`);
      return { type: 'landmarksSingle', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);


// Initial State
const initialState = {
  hero: {
    loading: false,
    data: [],
    error: null,
  },
  aboutUs: {
    loading: false,
    data: [],

    error: null,
  },
  tourevents: {
    loading: false,
    data: [],
    error: null,
    pagination: null,
  },
  toureventsSingle: {
    loading: false,
    data: [],

    error: null,
  },
  destinations: {
    loading: false,
    data: [],
    error: null,
    pagination: null,
  },
  destinationsSingle: {
    loading: false,
    data: [],

    error: null,
  },
  testimonials: {
    loading: false,
    data: [],

    error: null,
  },
  steps: {
    loading: false,
    data: [],

    error: null,
  },
  partners: {
    loading: false,
    data: [],

    error: null,
  },
  subscribe: {
    loading: false,
    data: [],
    error: null,
  },
  favorites: {
    loading: false,
    error: null,
  },
  accommodations: {
    loading: false,
    data: [],
    error: null,
    pagination: null,
  },
  accommodationSingle: {
    loading: false,
    data: [],
    error: null,
  },
  landmarks: {
    loading: false,
    data: [],
    error: null,
  },
  landmarksSingle: {
    loading: false,
    data: [],
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    toggleFavorite: (state, action) => {
      const { type, cardId } = action.payload;
      let cardIndex;


      if (type === 'destination') {
        cardIndex = state.destinations.data.findIndex(card => card.id === cardId);
        if (cardIndex !== -1) {
          state.destinations.data[cardIndex] = {
            ...state.destinations.data[cardIndex],
            isFavorited: !state.destinations.data[cardIndex].isFavorited,
          };
        }
      } else if (type === 'tourevent') {
        cardIndex = state.tourevents.data.findIndex(card => card.id === cardId);
        if (cardIndex !== -1) {
          state.tourevents.data[cardIndex] = {
            ...state.tourevents.data[cardIndex],
            isFavorited: !state.tourevents.data[cardIndex].isFavorited,
          };
        }
      }else if (type === 'accommodation') { // Add logic for accommodations
        cardIndex = state.accommodations.data.findIndex(card => card.id === cardId);
        if (cardIndex !== -1) {
          state.accommodations.data[cardIndex] = {
            ...state.accommodations.data[cardIndex],
            isFavorited: !state.accommodations.data[cardIndex].isFavorited,
          };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchHero
      .addCase(fetchHero.pending, (state) => {
        state.hero.loading = true;
      })
      .addCase(fetchHero.fulfilled, (state, action) => {
        state.hero.loading = false;
        state.hero.data = action.payload.data.data;
      })
      .addCase(fetchHero.rejected, (state, action) => {
        state.hero.loading = false;
        state.hero.error = action.payload;
      })
      // 
      .addCase(fetchAboutUs.pending, (state) => {
        state.aboutUs.loading = true;
      })
      .addCase(fetchAboutUs.fulfilled, (state, action) => {
        state.aboutUs.loading = false;
        state.aboutUs.data = action.payload.data.data;
      })
      .addCase(fetchAboutUs.rejected, (state, action) => {
        state.aboutUs.loading = false;
        state.aboutUs.error = action.payload;
      })
      // Tourevents
      .addCase(fetchTourevents.pending, (state) => {
        state.tourevents.loading = true;
      })
      .addCase(fetchTourevents.fulfilled, (state, action) => {
        state.tourevents.loading = false;
        // state.tourevents.data = action.payload.data.data;
        state.tourevents.data = action.payload.data.data.content.map(card => ({ ...card, isFavorited: card.isFavorited || false }));
        state.tourevents.pagination = action.payload.data.data.pagination;

      })
      .addCase(fetchTourevents.rejected, (state, action) => {
        state.tourevents.loading = false;
        state.tourevents.error = action.payload;
      })
      // Tourevents single
      .addCase(fetchToureventsSingle.pending, (state) => {
        state.toureventsSingle.loading = true;
      })
      .addCase(fetchToureventsSingle.fulfilled, (state, action) => {
        state.toureventsSingle.loading = false;
        state.toureventsSingle.data = action.payload.data.data;
      })
      .addCase(fetchToureventsSingle.rejected, (state, action) => {
        state.toureventsSingle.loading = false;
        state.toureventsSingle.error = action.payload;
      })
      // Destinations
      .addCase(fetchDestinations.pending, (state) => {
        state.destinations.loading = true;
      })
      .addCase(fetchDestinations.fulfilled, (state, action) => {
        state.destinations.loading = false;
        state.destinations.data = action.payload.data.data.content.map(card => ({ ...card, isFavorited: card.isFavorited || false }));
        state.destinations.pagination = action.payload.data.data.pagination;
      })
      .addCase(fetchDestinations.rejected, (state, action) => {
        state.destinations.loading = false;
        state.destinations.error = action.payload;
      })
      // Destinations single
      .addCase(fetchDestinationsSingle.pending, (state) => {
        state.destinationsSingle.loading = true;
      })
      .addCase(fetchDestinationsSingle.fulfilled, (state, action) => {
        state.destinationsSingle.loading = false;
        state.destinationsSingle.data = action.payload.data.data;
      })
      .addCase(fetchDestinationsSingle.rejected, (state, action) => {
        state.destinationsSingle.loading = false;
        state.destinationsSingle.error = action.payload;
      })
      // Testimonials
      .addCase(fetchTestimonials.pending, (state) => {
        state.testimonials.loading = true;
      })
      .addCase(fetchTestimonials.fulfilled, (state, action) => {
        state.testimonials.loading = false;
        state.testimonials.data = action.payload?.data?.data;
      })
      .addCase(fetchTestimonials.rejected, (state, action) => {
        state.testimonials.loading = false;
        state.testimonials.error = action.payload;
      })

      .addCase(fetchSteps.pending, (state) => {
        state.steps.loading = true;
      })
      .addCase(fetchSteps.fulfilled, (state, action) => {
        state.steps.loading = false;
        state.steps.data = action.payload?.data?.data;
      })
      .addCase(fetchSteps.rejected, (state, action) => {
        state.steps.loading = false;
        state.steps.error = action.payload;
      })

      .addCase(fetchPartners.pending, (state) => {
        state.partners.loading = true;
      })
      .addCase(fetchPartners.fulfilled, (state, action) => {
        state.partners.loading = false;
        state.partners.data = action.payload?.data?.data;
      })
      .addCase(fetchPartners.rejected, (state, action) => {
        state.partners.loading = false;
        state.partners.error = action.payload;
      })

      .addCase(postSubscribe.pending, (state) => {
        state.subscribe.loading = true;
      })
      .addCase(postSubscribe.fulfilled, (state, action) => {
        state.subscribe.loading = false;
        state.subscribe.error = null
        // state.subscribe.data = action.payload?.data?.data;
      })
      .addCase(postSubscribe.rejected, (state, action) => {
        state.subscribe.loading = false;
        state.subscribe.error = action.payload;
      })

      // fetchAccommodations
      .addCase(fetchAccommodations.pending, (state) => {
        state.accommodations.loading = true;
      })
      .addCase(fetchAccommodations.fulfilled, (state, action) => {
        state.accommodations.loading = false;
        state.accommodations.data = action.payload.data.data.content.map(card => ({ ...card, isFavorited: card.isFavorited || false }));
        state.accommodations.pagination = action.payload.data.data.pagination;
      })
      .addCase(fetchAccommodations.rejected, (state, action) => {
        state.accommodations.loading = false;
        state.accommodations.error = action.payload;
      })
      // fetchAccommodationSingle
      .addCase(fetchAccommodationSingle.pending, (state) => {
        state.accommodationSingle.loading = true;
      })
      .addCase(fetchAccommodationSingle.fulfilled, (state, action) => {
        state.accommodationSingle.loading = false;
        state.accommodationSingle.data = action.payload.data.data;
      })
      .addCase(fetchAccommodationSingle.rejected, (state, action) => {
        state.accommodationSingle.loading = false;
        state.accommodationSingle.error = action.payload;
      })
      
      
      // landmarks
      .addCase(fetchLandmarks.pending, (state) => {
        state.landmarks.loading = true;
      })
      .addCase(fetchLandmarks.fulfilled, (state, action) => {
        state.landmarks.loading = false;
        state.landmarks.data = action.payload.data.data;
      })
      .addCase(fetchLandmarks.rejected, (state, action) => {
        state.landmarks.loading = false;
        state.landmarks.error = action.payload;
      })
   
      // landmarks
      .addCase(fetchLandmarksSingle.pending, (state) => {
        state.landmarksSingle.loading = true;
      })
      .addCase(fetchLandmarksSingle.fulfilled, (state, action) => {
        state.landmarksSingle.loading = false;
        state.landmarksSingle.data = action.payload.data.data;
      })
      .addCase(fetchLandmarksSingle.rejected, (state, action) => {
        state.landmarksSingle.loading = false;
        state.landmarksSingle.error = action.payload;
      })

    // switchfavourite
    .addCase(switchfavourite.pending, (state, action) => {
      const cardId = action.meta.arg.values.favorable_id;
      const favorable_type = action.meta.arg.values.favorable_type;
  
      let dataArray;
      if (['App\\Models\\Tourevent', 'App\\Models\\Destination', 'App\\Models\\Accommodation'].includes(favorable_type)) {
        if (favorable_type === 'App\\Models\\Tourevent') {
          dataArray = state.tourevents.data;
        } else if (favorable_type === 'App\\Models\\Destination') {
          dataArray = state.destinations.data;
        } else if (favorable_type === 'App\\Models\\Accommodation') {
          dataArray = state.accommodations.data;
        }
  
        const itemIndex = dataArray.findIndex(card => card.id === cardId);
        if (itemIndex !== -1) {
          dataArray[itemIndex].loading = true;
        }
      } else if (favorable_type === 'App\\Models\\Booking') {
        state.favorites.loading = true;
      }
    })
    .addCase(switchfavourite.fulfilled, (state, action) => {
      const cardId = action.meta.arg.values.favorable_id;
      const favorable_type = action.meta.arg.values.favorable_type;
  
      let dataArray;
      if (['App\\Models\\Tourevent', 'App\\Models\\Destination', 'App\\Models\\Accommodation'].includes(favorable_type)) {
        if (favorable_type === 'App\\Models\\Tourevent') {
          dataArray = state.tourevents.data;
        } else if (favorable_type === 'App\\Models\\Destination') {
          dataArray = state.destinations.data;
        } else if (favorable_type === 'App\\Models\\Accommodation') {
          dataArray = state.accommodations.data;
        }
  
        const itemIndex = dataArray.findIndex(card => card.id === cardId);
        if (itemIndex !== -1) {
          dataArray[itemIndex].isFavorited = !dataArray[itemIndex].isFavorited;
          dataArray[itemIndex].loading = false;
        }
      } else if (favorable_type === 'App\\Models\\Booking') {
        state.favorites.loading = false;
      }
    })
    .addCase(switchfavourite.rejected, (state, action) => {
      const cardId = action.meta.arg.values.favorable_id;
      const favorable_type = action.meta.arg.values.favorable_type;
  
      let dataArray;
      if (['App\\Models\\Tourevent', 'App\\Models\\Destination', 'App\\Models\\Accommodation'].includes(favorable_type)) {
        if (favorable_type === 'App\\Models\\Tourevent') {
          dataArray = state.tourevents.data;
        } else if (favorable_type === 'App\\Models\\Destination') {
          dataArray = state.destinations.data;
        } else if (favorable_type === 'App\\Models\\Accommodation') {
          dataArray = state.accommodations.data;
        }
  
        const itemIndex = dataArray.findIndex(card => card.id === cardId);
        if (itemIndex !== -1) {
          dataArray[itemIndex].loading = false;
        }
      } else if (favorable_type === 'App\\Models\\Booking') {
        state.favorites.loading = false;
      }
    });

  },
});
export const { toggleFavorite } = dataSlice.actions;

export default dataSlice.reducer;
