import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Image, notification } from "antd";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import StepsIndicator from "./StepsIndicator.jsx";
import Destinations from "components/Shared/Destinations/index.jsx";
import { useTranslation } from "react-i18next";
import arrowLeft from 'public/images/arrow-left.svg'
import arrowRight2 from 'public/images/arrow-right2.svg'
import Filter from "components/Shared/Destinations/filter.jsx";
import { Helmet } from "react-helmet";
const Step2 = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { t  } = useTranslation();
  const initialData = location.state || {};
  const [destination_id, setSelectedCardId] = useState(null); 
 

  const handleCardSelection = (id) => {
    setSelectedCardId(id);
  };


  useEffect(() => {
    const savedData = localStorage.getItem("bookingFormData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setSelectedCardId(parsedData.destination_id); 
    }
  }, [location , navigate])
  

  const onFinish = () => {
    if(!destination_id){
      notification.warning({
        description: t('select_your_destination'),
        duration: 3, 
      });
    }else{

      const storedData = localStorage.getItem("bookingFormData");
      const parsedData = storedData ? JSON.parse(storedData) : {};
      
      const updatedData = {
        ...parsedData, 
        ...initialData, 
        destination_id: destination_id || parsedData.destination_id,
      };
      
      localStorage.setItem("bookingFormData", JSON.stringify(updatedData));
      navigate("/booking/step3", { state: updatedData });
    }
  };

  if(!localStorage.getItem("bookingFormData") || !JSON.parse(localStorage.getItem("bookingFormData")).edit){
    return <Navigate to="/booking/step1" />
  }

  return (
    <>
    <Helmet>
      <title>{t('nbt')} | {t('customize_your_travel_package')}</title>
    </Helmet>
    <section className="py-8">
      <div className="container">
        <StepsIndicator currentStep={1} />
        <h2 className="text-primary-2 mb-4 flex items-center font-bold"> <i className="icon-location text-primary me-3"></i>  {t('choose_tourist_destination')}:</h2>
        <Filter filterButton={true} />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Destinations numberCard={9} pagination={true} booking={true} onCardSelect={handleCardSelection} selectedCardId={destination_id}  />
          <Row justify="center" className="mt-9">
            <Col span={24} lg={12}>
              <div className="flex items-center justify-around w-full">
                <Form.Item>
                  <Button className="text-primary border-primary font-bold px-8 py-3 h-auto font-size-18"
                    onClick={() => navigate("/booking/step1")}
                  >
                    <Image src={arrowRight2} alt="arrowLeft" preview={false} className="me-1 translate-scale-1-ltr" />
                    {t('prev')}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button className="bg-primary-2 text-white font-bold px-8 py-3 h-auto font-size-18" htmlType="submit">
                  {t('next')}
                    <Image src={arrowLeft} alt="arrowLeft" preview={false} className="ms-1 translate-scale-1-ltr" />
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </section>
    </>

  );
};

export default Step2;
