import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const TestimonialSingle = ({ testimonial, limit }) => {
  const displayedTestimonials = limit ? testimonial?.slice(0, 5) : testimonial;
  const { t } = useTranslation();
  return (
    <>
      {displayedTestimonials?.length > 0 ?
        <>

          {displayedTestimonials?.map((tourevent, index) => (

            <div className="justify-between inline-flex mb-5" key={index}>
              <div className="justify-start items-start gap-[15px] flex flex-none mb-5">
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src={tourevent?.image}
                  alt={tourevent?.name}
                />
                <div className="w-[55px] flex-col gap-[2px] inline-flex">
                  <div className="text-[10px] font-bold">{tourevent?.name}</div>
                  <div className="text-[10px]">{tourevent?.position}</div>
                  <div className="justify-start items-center gap-[2.96px] inline-flex">
                    <div className="text-primary-2 text-[12px] font-bold">
                      {tourevent?.rating} <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-justify text-black text-[10.61px] ms-3">{tourevent?.body}</div>
            </div>
          ))}
        </>

        : <>{t('no_reviews')}</>}

      {!limit ? <div className="text-end">
        <Link to="" className='font-bold font-size-20 text-primary-2'>مشاهدة جميع المراجعات</Link>
      </div> : null}

    </>
  );
};

export default TestimonialSingle;
