import { Button, Col, Image, Row } from "antd";
import Title from "antd/es/typography/Title";
import Imagestart from "public/images/start.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faChevronLeft, faPlay } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { fetchHero, fetchAboutUs, fetchSteps, fetchPartners } from "features/home/homeSlice";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import FsLightbox from "fslightbox-react";
import Tourevent from "components/Shared/Tourevent";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import Testimonial from "components/Shared/Testimonial";
import styles from "./index.module.scss";
import CustomLazyImage from "hooks/customLazyImage";
import Destinations from "components/Shared/Destinations";
import placeholder from "public/images/placeholder.png";
export default function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { loading: heroLoading, data: hero } = useSelector((state) => state.data.hero);
  const { loading: aboutUsLoading, data: aboutUs } = useSelector((state) => state.data.aboutUs);

  const { loading: stepsLoading, data: steps } = useSelector((state) => state.data.steps);
  const { loading: PartnersLoading, data: partners } = useSelector((state) => state.data.partners);

  const [toggler, setToggler] = useState(false);

  const [status3, setStatus3] = useState(1);



  useEffect(() => {
    dispatch(fetchHero());
    dispatch(fetchAboutUs());
    dispatch(fetchSteps());
  }, [i18n.language])


  useEffect(() => {
    dispatch(fetchPartners({ params: { page: status3 } }));
  }, [status3, i18n.language])





  const handleIncrement3 = () => setStatus3(status3 + 1);

  const handleDecrement3 = () => setStatus3(status3 > 1 ? status3 - 1 : 1);


  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1); 
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        offset: -50,
        smooth: "easeInOutQuart",
      });
    }
  }, [location]);

  const goPageBooking = () => {
    localStorage.removeItem("bookingFormData");
    navigate("/booking/step1")
  }


  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('home')}</title>
      </Helmet>
      <section className={`${styles.hero} flex items-end relative`}>
        {heroLoading ? <Skeleton  /> : 
        <div className={`${styles.bgHero}`}>
        <Image
          src={hero?.image} 
          alt=""
          preview={false}
          />
        </div>
        }
        <div className="container">
          <div className={`${styles.heroContent} p-4 lg:py-7 lg:px-16`}>
            <Title className={`${styles.heroContent}text-black font-bold font-size-24`}>{hero?.promotional_message}</Title>
            <div className="text-end">
              <Button onClick={goPageBooking} size="large" className={`bg-primary-2 text-white font-bold border-0 py-4 px-10 h-auto hover-btn-primary-2`}> <Image preview={false} src={Imagestart} /> {t('start_fun_now')}</Button>
            </div>
            <div onClick={() => setToggler(!toggler)} className={`${styles.ripple} absolute top-28 lg:top-1/2 left-1/2 z-2 -translate-x-1/2 -translate-y-1/2 cursor-pointer w-24 h-24 rounded-full bg-primary flex items-center justify-center`}><FontAwesomeIcon icon={faPlay} className="text-white font-size-30" /></div>
          </div>
        </div>
        <FsLightbox
          toggler={toggler}
          sources={[hero.video]}
        />
      </section>

      <section className="py-10 lg:py-20" id="aboutUs">
        <div className="container">
          <Row gutter={20}>
            <Col span={24} lg={12}>
              {aboutUsLoading ? <>
                <Skeleton className="mb-4" styles={{ borderRadius: "6px" }} height={30} />
                <Skeleton className="mb-4" styles={{ borderRadius: "6px" }} height={30} /></> :
                <>
                  <div className="text-center mb-12">
                    <h4 className={`${styles.entryTitle} bg-primary text-white rounded-full inline-block font-size-20 font-bold px-5 py-1`}>{aboutUs?.title}</h4>
                  </div>
                  <div className="mt-10 mb-10" dangerouslySetInnerHTML={{ __html: aboutUs?.body }}></div>

                  <div className="text-end">
                    <Link to="/about-us" className="font-bold text-primary font-size-18 border border-primary inline-block px-12 py-3 rounded-lg"> {t('learn_more')}  </Link>
                  </div>

                </>

              }                    

            </Col>
            <Col span={24} lg={12}>
            {aboutUsLoading ? <>
              <img
                src={placeholder} 
                alt=""
                />
              
            </> : <img
                src={aboutUs?.image} 
                alt=""
                />}
              
              {/* {aboutUsLoading ? <Skeleton styles={{ borderRadius: "16px" }} height={400} /> : <Image preview={false} src={aboutUs?.image} />} */}
            </Col>
          </Row>
        </div>
      </section>
      <section className="py-10" id="destinations">
        <div className="container">
          <div className="text-center mb-12">
          </div>
          <Row className="mb-20">
            <Col span={8} className="hidden lg:flex"></Col>
            <Col span={12} lg={8}>
              <div className="lg:text-center">
                <Title className={`${styles.entryTitle} bg-primary text-white rounded-full inline-block font-size-20 font-bold px-5 py-2`}>{t('destinations')}</Title>
              </div>
            </Col>
            <Col span={12} lg={8} className="text-end">
              <Link to="/destinations" className="font-bold text-primary font-size-24"> {t('all_destinations')}  </Link>
            </Col>
          </Row>
          <Destinations arrow={true} numberCard={3} booking={false} />
        </div>
      </section>
      <section className="py-10">
        <div className="container">
          <div className="text-center mb-12">
            <Title className={`${styles.entryTitle} bg-primary text-white rounded-full inline-block font-size-20 font-bold px-5 py-2`}>{t('book_now')}</Title>
          </div>
          <div className={`${styles.contentStep} lg:p-7 p-3 `}>
            <Row gutter={20}>
              <Col span={24} lg={12} className="mb-5 lg:mb-0">
                <Title className="font-size-20 font-bold">{steps?.texts?.title}</Title>
                <Title className="font-size-20 font-bold text-primary">{steps?.texts?.sub_title}</Title>

                {stepsLoading ? (
                  <div>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <Skeleton
                        style={{ borderRadius: "5px" }}
                        key={index}
                        height={40}
                      />
                    ))}
                  </div>
                ) : steps?.content?.length > 0 ? (
                  <ul className={`${styles.contentStepList} mt-10`}>
                    {steps.content.map((step) => (
                      <li key={step.id}>
                        <FontAwesomeIcon
                          className="lg:me-5 me-3 text-primary"
                          icon={faChevronLeft}
                        />
                        <span className="text-gray-500">{step.name}</span>{" "}
                        <span>{step.title}</span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  null
                )}

                <div className="mt-14 mb-6">
                  <Title className="font-size-20 font-bold text-primary"> {steps?.texts?.partner_title}</Title>
                </div>

                {PartnersLoading ? (
                  <Row gutter={25}>
                    {window.innerWidth > 992 ? <>{Array.from({ length: 4 }).map((_, index) => (
                      <Col span={8} lg={6} md={12} key={index}>
                        <Skeleton
                          style={{ borderRadius: '50%' }}
                          height={101}
                          width={100}
                        />
                      </Col>
                    ))}</> : <>{Array.from({ length: 1 }).map((_, index) => (
                      <Col span={24} lg={6} md={24} key={index}>
                        <Skeleton
                          style={{ borderRadius: '50%' }}
                          height={101}
                          width={100}
                        />
                      </Col>
                    ))}</> }
                    
                  </Row>
                ) : partners?.content?.length > 0 ? (
                  <Row gutter={25}>
                    {partners.content.map((partner) => (
                      <Col span={8} lg={6} md={12} key={partner.id}>
                        <div className={`${styles.widget_itemBrand}`}>
                          <div className={`${styles.widget_itemImage}`}>
                            <CustomLazyImage
                                src={partner.image} 
                                alt={partner.id}
                                width={100}
                                height={100}
                                className={`${styles.partnerImage}`}
                              />
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                ) : null}

                <Row>
                  <Col span={24}>
                    <div className="flex justify-center items-center mt-10">
                      <button onClick={handleDecrement3} disabled={status3 === 1 || PartnersLoading} className="prev-pageination bg-primary  text-white w-12 h-12 rounded-tl-xl rounded-br-xl flex items-center justify-center mx-10 cursor-pointer">
                        <FontAwesomeIcon className="text-lg" icon={faArrowRight} />
                      </button>
                      <button onClick={handleIncrement3} disabled={partners?.pagination?.has_next === false || PartnersLoading} className="next-pageination bg-primary  text-white w-12 h-12 rounded-tr-xl rounded-bl-xl flex items-center justify-center mx-10 cursor-pointer">
                        <FontAwesomeIcon className="text-lg" icon={faArrowLeft} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} lg={12}>
                <div className={`${styles.contentStepImage} mb-4 p-4 border border-primary rounded-lg text-center`}>
                  <Image preview={false} src={steps?.texts?.image} />
                </div>
                <Button onClick={goPageBooking} className="btn btn-primary-2 w-full text-center font-size-18 flex items-center justify-center py-2 h-auto rounded-md hover-btn-primary-2"><Image preview={false} src={Imagestart} width={20} />  <span className="ms-2">{t('start_nabat')} </span> </Button>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <section className={`${styles.sectionTestimonial} py-10`} id="testimonials">
        <div className="container">
          <Row className="mb-20">
            <Col span={8} className="hidden lg:flex"></Col>
            <Col span={12} lg={8}>
              <div className="lg:text-center">
                  <Title className={`${styles.entryTitle} bg-primary text-white rounded-full inline-block font-size-20 font-bold px-5 py-2`}>{t('customer_reviews')}</Title>
              </div>
            </Col>
            <Col span={12} lg={8} className="text-end">
              <Link to="/testimonials" className="font-bold text-primary font-size-24"> {t('all_reviews')} </Link>
            </Col>
          </Row>
          <Testimonial numberCard={3} pagination={false} />
        </div>
      </section>
      <section className="py-10" id="tourevents">
        <div className="container">
          <Row className="mb-7">
            <Col span={8} className="hidden lg:flex"></Col>
            <Col span={12} lg={8}>
              <div className="lg:text-center">
                <Title className={`${styles.entryTitle} bg-primary text-white rounded-full inline-block font-size-20 font-bold px-5 py-2`}>{t('events_our_trips')}</Title>
              </div>
            </Col>
            <Col span={12} lg={8} className="text-end">
              <Link to="/tourevents" className="font-bold text-primary font-size-24"> {t('all_events')} </Link>
            </Col>
          </Row>
          <Tourevent numberCard={3} pagination={false} />
        </div>
      </section>
    </>
  );
}
