import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/error/errorSlice';

export const postCreatePayment = createAsyncThunk(
  'data/fetchStatistics',
  async ({id, callback} , { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.post(`payments/create-payment` , { booking_id: id } );
      callback?.(response.data)
      // console.log("🚀 ~ response:", response)
      return { type: 'payment', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(error.response?.data?.data || error.response?.data?.message || error?.message);;
    }
  }
);

export const postPaymentTabby = createAsyncThunk(
  'data/postPaymentTabby',
  async ({id, callback} , { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.post(`checkout/tabby` , { booking_id: id } );
      callback?.(response.data)
      // console.log("🚀 ~ response:", response)
      return { type: 'payment', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(error.response?.data?.data || error.response?.data?.message || error?.message);;
    }
  }
);


// Initial State
const initialState = {
  payments:{
    loading: false,
    error: null,
  },
  tabby:{
    loading: false,
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // payment
      .addCase(postCreatePayment.pending, (state) => {
        state.payments.loading = true;
      })
      .addCase(postCreatePayment.fulfilled, (state, action) => {
        state.payments.loading = false;
        // state.payment.data = action.payload.data.data;
      })
      .addCase(postCreatePayment.rejected, (state, action) => {
        state.payments.loading = false;
        state.payments.error = action.payload;
      })
      // Tabby
      .addCase(postPaymentTabby.pending, (state) => {
        state.tabby.loading = true;
      })
      .addCase(postPaymentTabby.fulfilled, (state, action) => {
        state.tabby.loading = false;
        // state.payment.data = action.payload.data.data;
      })
      .addCase(postPaymentTabby.rejected, (state, action) => {
        state.tabby.loading = false;
        state.tabby.error = action.payload;
      })
      ;
  },
});

export default dataSlice.reducer;
