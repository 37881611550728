import style from "./index.module.scss";
import bgg from "public/images/bg-layer2.png";

const TitlePage = ({ title, bg ,  bgContact}) => {
  return (
    <div className={`${style.pageTitle} ${bgContact ? style.headerContact : null} relative z-2`} >
      <div className="container">
        <div
          className={`text-center ${bg ? style.bgStyle : ''}`}
          style={bg ? {
            marginTop:"30px",
            padding: "16px 0",
            backgroundImage: `url(${bgg})`,
            backgroundSize: '45%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          } :null}
        >
          <h2 className="text-white title mb-2 font-bold bg-primary inline-block min-w-56 py-2 px-3 border-white border-4 font-size-32">
            {title}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default TitlePage;
