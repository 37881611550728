import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/error/errorSlice';

export const fetchStatistics = createAsyncThunk(
  'data/fetchStatistics',
  async (_ , { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`user/statistics`);
      return { type: 'statistics', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(error.response?.data?.data || error.response?.data?.message || error?.message);;
      // return rejectWithValue(error.message);
    }
  }
);
export const fetchProfile = createAsyncThunk(
  'data/fetchProfile',
  async (_ , { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/profile`);
      return { type: 'profile', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(error.response?.data?.data || error.response?.data?.message || error?.message);;
      // return rejectWithValue(error.message);
    }
  }
);

export const fetchCountries = createAsyncThunk(
  'data/fetchCountries',
  async (_ , { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/countries`);
      return { type: 'countries', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(error.response?.data?.data || error.response?.data?.message || error?.message);
      // return rejectWithValue(error.message);
    }
  }
);

export const fetchCountriesSingle = createAsyncThunk(
  'data/fetchCountriesSingle',
  async ({countryId}, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/countries/${countryId}`);
      return { type: 'countriesSingle', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(error.response?.data?.data || error.response?.data?.message || error?.message);;
      // return rejectWithValue(error.message);
    }
  }
);

export const sendUpdate_user_address = createAsyncThunk(
  'data/sendUpdate_user_address',
  async ({values  , callback}, { rejectWithValue  , dispatch }) => {
    try {
      const response = await axiosApp.post(`/update_user_address` , values);
      callback?.(response.data)
      return { type: 'update_user_address', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);;
      // return rejectWithValue(error.message);
    }
  }
);


export const updateProfilePicture = createAsyncThunk(
  'data/profilePicture',
  async (formData, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.post(`/profile/image` , formData , {headers: {
        "Content-Type": "multipart/form-data", // هذا مهم جدًا
      }});
      return { type: 'profilePicture', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);;
      // return rejectWithValue(error.message);
    }
  }
);



export const sendUpdateProfile = createAsyncThunk(
  'data/updateProfile',
  async ({values , callback}, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.post(`/profile` , values );
      callback?.(response.data)
      return { type: 'updateProfile', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);;
    }
  }
);




// Initial State
const initialState = {
  profile:{
    loading: false,
    data: [], 
    error: null,
  },
  countries:{
    loading: false,
    data: [], 
    error: null,
  },
  countriesSingle:{
    loading: false,
    data: [], 
    error: null,
  },
  update_user_address:{
    loading: false,
    data: [], 
    error: null,
  },
  profilePicture:{
    loading: false,
    error: null,
  },
  updateProfile:{
    loading: false,
    error: null,
  },
  statistics:{
    loading: false,
    data: [], 
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // profile
      .addCase(fetchProfile.pending, (state) => {
        state.profile.loading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profile.loading = false;
        state.profile.data = action.payload.data.data;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.profile.loading = false;
        state.profile.error = action.payload;
      })
      // fetchCountries
      .addCase(fetchCountries.pending, (state) => {
        state.countries.loading = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries.loading = false;
        state.countries.data = action.payload.data.data;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.countries.loading = false;
        state.countries.error = action.payload;
      })
      // fetchCountriesSingle
      .addCase(fetchCountriesSingle.pending, (state) => {
        state.countriesSingle.loading = true;
      })
      .addCase(fetchCountriesSingle.fulfilled, (state, action) => {
        state.countriesSingle.loading = false;
        state.countriesSingle.data = action.payload.data.data;
      })
      .addCase(fetchCountriesSingle.rejected, (state, action) => {
        state.countriesSingle.loading = false;
        state.countriesSingle.error = action.payload;
      })
      // update_user_address
      .addCase(sendUpdate_user_address.pending, (state) => {
        state.update_user_address.loading = true;
      })
      .addCase(sendUpdate_user_address.fulfilled, (state, action) => {
        state.update_user_address.loading = false;
        state.update_user_address.data = action.payload.data.data;
      })
      .addCase(sendUpdate_user_address.rejected, (state, action) => {
        state.update_user_address.loading = false;
        state.update_user_address.error = action.payload;
      })
      // profilePicture
      .addCase(updateProfilePicture.pending, (state) => {
        state.profilePicture.loading = true;
      })
      .addCase(updateProfilePicture.fulfilled, (state, action) => {
        state.profilePicture.loading = false;
        state.profilePicture.data = action.payload.data.data;
      })
      .addCase(updateProfilePicture.rejected, (state, action) => {
        state.profilePicture.loading = false;
        state.profilePicture.error = action.payload;
      })
      // sendUpdateProfile
      .addCase(sendUpdateProfile.pending, (state) => {
        state.updateProfile.loading = true;
      })
      .addCase(sendUpdateProfile.fulfilled, (state, action) => {
        state.updateProfile.loading = false;
      })
      .addCase(sendUpdateProfile.rejected, (state, action) => {
        state.updateProfile.loading = false;
        state.updateProfile.error = action.payload;
      })
      // statistics
      .addCase(fetchStatistics.pending, (state) => {
        state.statistics.loading = true;
      })
      .addCase(fetchStatistics.fulfilled, (state, action) => {
        state.statistics.loading = false;
        state.statistics.data = action.payload.data.data;
      })
      .addCase(fetchStatistics.rejected, (state, action) => {
        state.statistics.loading = false;
        state.statistics.error = action.payload;
      })
      ;
  },
});

export default dataSlice.reducer;
