import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

const GoogleMapComponent = ({ lat, lng , containerStyle }) => {

  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (lat && lng) {
      setCenter({ lat: parseFloat(lat), lng: parseFloat(lng) });
    }
  }, [lat, lng]);

  return (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
      {center && (
        <Marker position={center}  />
      )}
    </GoogleMap>
  );
};

export default GoogleMapComponent;
