import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker, TimePicker, Row, Col, Image } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import StepsIndicator from "./StepsIndicator.jsx";
import { useTranslation } from "react-i18next";
import arrowLeft from 'public/images/arrow-left.svg';
import dayjs from "dayjs";
import { Helmet } from "react-helmet";

const Step1 = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const initialData = location.state || {};

  useEffect(() => {
    const savedData = localStorage.getItem("bookingFormData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      form.setFieldsValue({
        booking_date: parsedData.booking_date ? dayjs(parsedData.booking_date) : null,
        arrival_time: parsedData.arrival_time ? dayjs(parsedData.arrival_time, "HH:mm") : null,
        num_adults: parsedData.num_adults || "",
        num_children: parsedData.num_children || "",
      });
    }
  }, [initialData, form, navigate]);


  const onFinish = (values) => {
    const storedData = localStorage.getItem("bookingFormData");
    const parsedData = storedData ? JSON.parse(storedData) : {};

    const updatedData = {
      ...parsedData,
      ...initialData,
      ...values,
      booking_date: values.booking_date ? values.booking_date.format("YYYY-MM-DD") : null,
      arrival_time: values.arrival_time ? values.arrival_time.format("HH:mm") : null,
      eye: false,
      edit: true
    };
    localStorage.setItem("bookingFormData", JSON.stringify(updatedData));
    navigate("/booking/step2", { state: updatedData });
  };
  const disabledDate = (current) => {
    return (
      current &&
      (current < dayjs().startOf("day") ||
        (current >= dayjs().startOf("day") && current < dayjs().add(6, "day").endOf("day"))) 
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('customize_your_travel_package')}</title>
      </Helmet>
      <section className="py-8">
        <div className="container">
          <StepsIndicator currentStep={0} />
          <h2 className="text-primary-2 font-size-26 font-bold mb-3">{t('select_date_number_people')}: </h2>
          <h2 className="mb-4">{t('you_select_dates_people')}</h2>
          <ul className="flex flex-wrap mb-5 font-size-18">
            <li className="me-7"> <i className="icon-calendar-date text-primary me-3"></i> <span>{t('booking_date')}</span></li>
            <li className="me-7"> <i className="icon-time text-primary me-3"></i>  <span>{t('arrival_time')}</span></li>
            <li className="me-7"> <i className="icon-users text-primary me-3"></i> <span>{t('number_people_trip')}</span></li>
            <li className="me-7"> <i className="icon-kids text-primary me-3"></i>  <span>{t('number_children_trip')}</span></li>
          </ul>
          <Form form={form} layout="vertical" size="large" onFinish={onFinish}>
            <Form.Item
              label={`${t('select_booking_date')} :`}
              name="booking_date"
              rules={[{ required: true, message: t('field_required') }]}
            >
              <DatePicker disabledDate={disabledDate} placeholder={t('select_booking_date')} style={{ width: '100%' }} />
            </Form.Item>
            <h6 className="text-end font-bold mt-1 font-size-16"> <span className="text-red-700">*</span> {t('reservations_must_made_least_one_week_advance')} .</h6>
            <Form.Item
              label={`${t('estimated_arrival_time')} :`}
              name="arrival_time"
              rules={[{ required: true, message: t('field_required') }]}
            >
              <TimePicker placeholder={t('estimated_arrival_time')} style={{ width: '100%' }} use12Hours />
            </Form.Item>
            <Form.Item
              label={t('number_people_trip')}
              name="num_adults"
              rules={[{ required: true, message: t('field_required') }]}
            >
              <Input placeholder={t('select_number')} />
            </Form.Item>
            <Form.Item
              label={t('number_children_under_12_years')}
              name="num_children"
            >
              <Input placeholder={t('select_number')} />
            </Form.Item>
            <Row justify="center" className="mt-9">
              <Col span={24} lg={12}>
                <div className="flex items-center justify-around w-full">
                  <Form.Item>
                    <Button className="bg-primary-2 text-white font-bold px-8 py-3 h-auto font-size-18" htmlType="submit">
                      {t('next')}
                      <Image src={arrowLeft} alt="arrowLeft" preview={false} className="ms-1 translate-scale-1-ltr" />
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </div>

      </section>
    </>

  );
};

export default Step1;
