import React from 'react'
import triangle from 'public/images/triangle.png'
import { useTranslation } from 'react-i18next'
const EmptyData = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <img src={triangle} alt="triangle" className="mx-auto mb-6" />
      <h2 className="font-bold mb-4">{t('no_results')}.</h2>
      {/* <h4>{t('sorry_couldnt_find_any_results_match_search')}.</h4> */}
      {/* <h4>{t('try_adjusting_your_search_options_exploring_other_destinations')}!</h4> */}
    </div>
  )
}
export default EmptyData