import { setError } from 'features/error/errorSlice';
import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const fetchEvents = createAsyncThunk(
  'data/fetchEvents',
  async (_ , { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/events`);
      return { type: 'events', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);


// Initial State
const initialState = {
  events:{
    loading: false,
    data: [],
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // blogs
      .addCase(fetchEvents.pending, (state) => {
        state.events.loading = true;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events.loading = false;
        state.events.data = action.payload.data.data;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.events.loading = false;
        state.events.error = action.payload;
      });
  },
});

export default dataSlice.reducer;
