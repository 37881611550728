"use client";
import TitlePage from 'components/Shared/TitlePage';
import { fetchBlogsSingle, fetchComments, fetchResentBlogs, postComments } from 'features/blog/blogSlice';
import { Avatar, Button, Col, Form, Image, Input, Pagination, Row } from 'antd';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css'
import style from '../index.module.scss';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Login from 'components/Shared/Login';
import { useForm } from 'antd/es/form/Form';
import EmptyData from 'components/Shared/EmptyData';
import { Helmet } from 'react-helmet';

const BlogSingle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { resentBlogs } = useSelector((state) => state.blogs);
  const { blogsSingle } = useSelector((state) => state.blogs);
  const { i18n } = useTranslation();
  const [statusUser, setStatusUser] = useState(false);
  const [status, setStatus] = useState(1);
  const { user } = useSelector((state) => state.user);
  const { comment, comments } = useSelector((state) => state.blogs);
  const [form] = useForm();
  const { t } = useTranslation();


  useEffect(() => {
    dispatch(fetchResentBlogs(
      {
        params: {
          order_by: "created_at",
          order_by_direction: "desc",
          perpage: 6
        }
      }
    ));
    dispatch(fetchBlogsSingle(id));
  }, [i18n.language, id])

  useEffect(() => {
    dispatch(fetchComments({ id, status }));
  }, [i18n.language, id, status])


  useEffect(() => {
    if (user?.data) {
      setStatusUser(true)
    }
  }, [user])

  const onFinish = (values) => {
    values = {
      ...values,
      post_id: id
    }
    dispatch(postComments({
      values, callback: () => {
        form.resetFields();
        setStatus(1)
        dispatch(fetchComments({ id, status }))
      }
    }));
  }

  return (
    <div>
      <Helmet>
        <title>{t('nbt')} | {t('blog')}</title>
      </Helmet>
      <TitlePage title={t('blog')} />
      <section className={`${style.sectionBlogSingle} py-14`}>
        {blogsSingle?.loading ? (
          <div className="container">
            <Skeleton height={450} />
            <Skeleton className="mt-5 mb-5" height={50} />
            <Skeleton height={50} />
          </div>

        ) : (
          <div>
            <div className={`${style.blogSingleContent}`}>
              <div className='container'>

                <div className='relative mb-6 lg:mb-10'>
                  <Image
                    src={blogsSingle?.data?.cover_image || blogsSingle?.data?.image}
                    alt={blogsSingle?.data?.image?.title || 'Default Alt Text'}
                    height={450}
                    preview={false}
                    style={{ objectFit: "cover" }}
                    className={`${style.blogSingleImage} w-full border-4 border-primary rounded-lg`}
                  />
                  <h2 className="font-size-30 font-bold z-2 absolute bottom-8 start-8 text-white">{blogsSingle?.data?.title}</h2>
                </div>

                <h4 className='mb-6'>{t('article_written_by')}</h4>
                <div className='flex items-center mb-8'>
                  <div className='w-100'>
                    <Avatar shape='circle' size={100} />
                  </div>
                  <div className='flex flex-1 ms-4' style={{ flexDirection: "column" }}>
                    <h2 className='font-bold mb-1'>{blogsSingle?.data?.systemAdmin?.name}</h2>
                  </div>
                  <div>{blogsSingle?.data?.systemAdmin?.created_at}</div>
                </div>

                <div
                  dangerouslySetInnerHTML={{ __html: blogsSingle?.data?.body }}
                ></div>
                <div className='py-10'>
                  {comments?.loading ? <>
                    <Skeleton className='mb-2' />
                    <Skeleton />
                  </> : comments?.data?.content?.map((item) => (
                    <>
                      <div className='flex mb-7' key={item.id}>
                        <div>
                          <Avatar shape='circle' src={item?.user?.image} alt={item?.user?.name} size={80} />
                        </div>
                        <div className='flex ms-4 lg:ms-10' style={{ flexDirection: "column" }}>
                          <h3 className='font-bold mb-1'>{item?.user?.name}</h3>
                          <h5>{item?.body}</h5>
                        </div>
                      </div>

                    </>

                  ))}

                  <Pagination
                    align="end"
                    className="my-3"
                    onChange={(page) => setStatus(page)}
                    defaultCurrent={1}
                    current={status}
                    total={comments?.data?.pagination?.total}
                    pageSize={5}
                  />

                </div>
              </div>


              <div className='bg-primary py-12'>
                <div className='container'>

                  {statusUser || localStorage.getItem("user") ? <>
                    <h2 className="text-white font-size-30 mb-3">{t('invite_comment')}</h2>
                    <div className='flex items-center mb-8'>
                      <div className='w-100'>
                        <Avatar shape='circle' size={50} />
                      </div>
                      <div className='flex flex-1 ms-4' style={{ flexDirection: "column" }}>
                        <h2 className='font-bold mb-1 text-white'>{JSON.parse(localStorage.getItem("user")).name}</h2>
                      </div>
                    </div>
                    <Form form={form} layout="vertical" size="large" onFinish={onFinish}>
                      <Form.Item
                        name="body"
                        rules={[{ required: true, message: t('field_required') }]}
                      >
                        <Input.TextArea rows={4} placeholder={t('enter_message')} />
                      </Form.Item>
                      <Form.Item>
                        <Button block loading={comment?.loading} className="bg-primary-2 border-0 text-white font-bold px-8 py-4 h-auto font-size-18" htmlType="submit">
                          {t('add_your_comment')}
                        </Button>
                      </Form.Item>
                    </Form>
                  </> :
                    <Login blog={true} />
                  }
                </div>
              </div>
            </div>
          </div>
        )}

      </section>
      <section>
        <div className='container'>
          <div className='flex justify-between items-center mb-8'>
            <h2 className='font-bold'>{t('related_article')}</h2>
            <Link to="/blogs" className='font-bold text-primary-2 font-size-20'>{t('all_article')}</Link>
          </div>
          {resentBlogs?.loading ? (
            <div className="px-2">
              <Row gutter={20}>
                {Array.from({ length: 3 }).map((_, index) => (
                  <Col span={24} lg={8} md={12} key={index}>
                    <div className={`${style.widget_itemBlog}`}>
                      <Skeleton height={260} />
                      <div className="px-6 py-9 border-t-0 border-4">
                        <Skeleton className="mb-3" style={{ borderRadius: "5px" }} height={33} />
                        <Skeleton style={{ borderRadius: "5px" }} height={84} />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>

          ) : resentBlogs?.data?.content?.length > 0 ? (
            <Row gutter={27}>
              {resentBlogs.data.content.map((blog) => (
                <Col span={24} lg={8} md={12}>
                  <div key={blog.id} className={`${style.widget_itemBlog} rounded-md relative`}>
                    <div className="absolute top-4 flex items-center flex-wrap" style={{ insetInlineEnd: "15px", zIndex: "2" }}>
                      {blog?.categories?.map((item) => (
                        <div className={`${style.widget_itemCategory} text-white ms-2 border border-white rounded-lg px-3 py-2 font-size-13`}>{item?.name}</div>
                      ))}
                      {/* <div className={`${style.widget_itemCategory} text-white ms-2 border border-white rounded-lg px-3 py-2 font-size-13`}>تعليمي</div> */}
                    </div>
                    <div className={`${style.widget_itemImage}`}>
                      <Link to={`/blogs/${blog.id}`}>
                        <Image
                          preview={false}
                          src={blog.image}
                          height={260}
                          alt={blog.title}
                        />
                      </Link>
                    </div>
                    <div className={`${style.widget_itemContent} rounded-b-sm px-6 py-4`}>
                      <h4 className={`${style.widget_itemTitle} mb-3 font-bold`}>
                        <Link className="text-black" to={`/blogs/${blog.id}`}>{blog.title}</Link>
                      </h4>
                      <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center">
                          <Avatar size={26} shape="circle" src={blog?.systemAdmin?.avatar} />
                          <h5 className="font-bold text-black ms-3">{blog?.systemAdmin?.name}</h5>
                        </div>
                        <h6 className="text-gray-500 font-size-12">{blog?.systemAdmin?.created_at}</h6>
                      </div>
                      <div
                        className={`${style.widget_itemText} mb-3 font-bold`}
                        dangerouslySetInnerHTML={{ __html: blog.body }}
                      ></div>
                      <h5 className="text-center">
                        <Link className="text-primary font-bold" to={`/blogs/${blog.id}`}>{t('view_article')}</Link>
                      </h5>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <EmptyData />
          )}
        </div>
      </section>
    </div>
  );
};

export default BlogSingle;
