import React, { useEffect, useState } from "react";
import StepsIndicator from "./StepsIndicator.jsx";
import { Avatar, Button, Checkbox, Col, Form, Image, Input, notification, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchEvents } from "features/events/eventsSlice.js";
import style from "./index.module.scss";
import Skeleton from "react-loading-skeleton";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import arrowLeft from 'public/images/arrow-left.svg'
import arrowRight2 from 'public/images/arrow-right2.svg'
import Tourevent from "components/Shared/Tourevent/index.jsx";
import { Helmet } from "react-helmet";
import ModalComponent from "components/Ui/ModalComponent/index.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import logo from 'public/images/logo.svg';
import { useForm } from "antd/es/form/Form.js";
import { postForms } from "features/booking/bookingSlice.js";
import { fetchProfile } from "features/profile/profileSlice.js";

const Step5 = () => {
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.events);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const initialData = location.state || {};
  const [events_id, setEvents_id] = useState()
  const [tourevent_id, setSelectedCardId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState();
  const { postForm } = useSelector((state) => state.booking);
  const { profile } = useSelector((state) => state.profile);
  const [form] = useForm();

  useEffect(() => {
    dispatch(fetchEvents());
    if(localStorage.getItem("user")){
      dispatch(fetchProfile());
    }
  }, [i18n.language])


  useEffect(() => {
    const savedData = localStorage.getItem("bookingFormData");
    const parsedData = JSON.parse(savedData);
    if (savedData) {
      setSelectedCardId(parsedData.tourevent_id);
      setEvents_id(parsedData.events);
    }
  }, [location, navigate])



  const onChange = (list) => {
    setEvents_id(list)
  };


  const handleCardSelection = (id) => {
    setSelectedCardId(id);
  };

  const subimtBokking = () => {

    if (!tourevent_id) {
      notification.warning({
        description: t('choose_one_available_activities'),
        duration: 3,
      });
    } else {

      const storedData = localStorage.getItem("bookingFormData");
      const parsedData = storedData ? JSON.parse(storedData) : {};

      const updatedData = {
        ...parsedData,
        ...initialData,
        events: events_id || parsedData.events_id,
        tourevent_id: tourevent_id || parsedData.tourevent_id,
      };
      localStorage.setItem("bookingFormData", JSON.stringify(updatedData));
      navigate("/booking/step6", { state: updatedData });
    }
    // 
  };

  const showModal = (id) => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    dispatch(postForms({values , callback:(response)=>{
      notification.success({
        duration:3,
        message:response.message
      })
    }}))
  };


  if (!localStorage.getItem("bookingFormData")) {
    return <Navigate to="/booking/step1" />
  }
  else if (!JSON.parse(localStorage.getItem("bookingFormData")).room_type || !JSON.parse(localStorage.getItem("bookingFormData")).num_rooms || !JSON.parse(localStorage.getItem("bookingFormData")).edit) {
    return <Navigate to="/booking/step4" />
  }

  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('customize_your_travel_package')}</title>
      </Helmet>
      <section className="py-8">
        <div className="container">
          <StepsIndicator currentStep={4} />
          <div className="flex items-center mb-3">
            <i className="text-primary me-2 icon-map"></i>
            <h2 className="text-primary-2 font-size-24 font-bold"> {t('destination_chosen_includes_variety_activities_trip_choose_suits_participate_enjoy')} ! </h2>
          </div>
          <div className="py-5">
            <Row className="mb-8">
              <Col span={24}>
                <div className={`bg-light-primary p-5`}>
                  {events?.loading ?
                    <Row gutter={25}>
                      {window.innerWidth > 992 ? <>{Array.from({ length: 8 }).map((_, index) => (
                        <Col span={24} md={12} key={index}>
                          <Skeleton className="mb-4" style={{ borderRadius: "6px" }} height={20} />
                        </Col>
                      ))}</> : <>{Array.from({ length: 4 }).map((_, index) => (
                        <Col span={24} md={12} key={index}>
                          <Skeleton className="mb-4" style={{ borderRadius: "6px" }} height={20} />
                        </Col>
                      ))}</>}

                    </Row>
                    :
                    <Checkbox.Group className={style.groupCheckbox} onChange={onChange} value={events_id}>
                      <Row>
                        {events?.data?.content?.map((option) => (
                          <Col span={24} lg={12} className="mb-5">
                            <Checkbox key={option?.id} value={option?.id}>
                              {option?.title}
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="text-primary font-size-24 font-bold mb-3"> {t('join_one_currently_available_activities')}: </h2>
              </Col>
            </Row>
            <Tourevent booking={true} numberCard={3} onCardSelect={handleCardSelection} selectedCardId={tourevent_id} />

            <Row gutter={20} align="middle" className="mt-10">
              <Col span={24} lg={16}>
                <h3>{t('would_like_specific_activity_about_clicking_following_button')} </h3>
              </Col>
              <Col span={24} lg={8}>
                <Button type="primary" onClick={showModal} className="h-auto py-3 font-size-16 font-bold" block>{t('custom_activity')}</Button>
              </Col>
            </Row>

            <Row justify="center" className="mt-14">
              <Col span={24} lg={12}>
                <div className="flex items-center justify-around w-full">
                  <Form.Item>
                    <Button className="text-primary border-primary font-bold px-8 py-3 h-auto font-size-18"
                      onClick={() => navigate("/booking/step4")}
                    >
                      <Image src={arrowRight2} alt="arrowLeft" preview={false} className="me-1 translate-scale-1-ltr" />
                      {t('prev')}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button onClick={subimtBokking} className="bg-primary-2 text-white font-bold px-8 py-3 h-auto font-size-18">
                      {t('next')}
                      <Image src={arrowLeft} alt="arrowLeft" preview={false} className="ms-1 translate-scale-1-ltr" />
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>



        </div>

      </section>
      <ModalComponent width={1000}
        isVisible={isModalVisible}
        onClose={handleClose}
        title={false}
        footer={false}
      >
        <div className="cursor-pointer absolute font-bold top-9 border border-primary rounded-lg px-7 py-3  text-primary inline-flex items-center font-size-16" onClick={() => handleClose()} style={{ insetInlineStart: "25px", zIndex: "2" }}> <FontAwesomeIcon icon={faArrowRight} className="me-2" /> {t('back')}</div>
        <div className="text-center mb-7 mt-4">
          <Image src={logo} alt="logo" height={80} preview={false} className="mx-auto" />
        </div>
        <h2 className="text-primary font-bold text-center mb-6">أطلب التسجيل في فعالية معينة</h2>
        {localStorage.getItem("user") ?
          <div className="bg-primary p-4 rounded-md mb-6">
            <Row align="middle">
              <Col span={10}>
                <div className="flex justify-between items-center">
                  <h5 className="text-white">أهلا وسهلا بك سيد</h5>
                  <div className="flex items-center">
                    <Avatar size={50} src={profile?.data?.image} alt={profile?.data?.name} />
                    <h5 className="text-white ms-4">{profile?.data?.name}</h5>
                  </div>
                </div>
              </Col>
              <Col span={14}>
                <h5 className="text-white text-end">{profile?.data?.phone}</h5>
              </Col>
            </Row>
          </div>
          : null}
        <h4 className="text-primary font-bold mb-5">سنتواصل معك فيما يخص طلب الفعالية القريبة من وصفك وهل هي متاحة أم لا، قم بتعبة وصف الفعالية:</h4>
        <Form form={form} layout="vertical" size="large" onFinish={onFinish}>
          <Form.Item
            label={t('description_event_would_like_attend')}
            name="body"
            rules={[{ required: true, message: t('field_required') },
            ]}
          >
            <Input.TextArea rows={5} placeholder={t('enter_description_event_would_like_join')} />
          </Form.Item>
          <Form.Item>
            <Button loading={postForm.loading} block className="bg-primary-2 text-white font-bold px-8 py-3 h-auto font-size-18" htmlType="submit">
              {t('send')}
            </Button>
          </Form.Item>
        </Form>
      </ModalComponent>
    </>

  );
};

export default Step5;
