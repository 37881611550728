import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosApp from 'api';
import { setError } from 'features/error/errorSlice';


export const fetchAccommodations = createAsyncThunk(
  'data/fetchAccommodations',
  async ({params} , { rejectWithValue , dispatch }) => {
    try {
      const filterType = params?.type === "All" ? '' : params?.type || '';
      const response = await axiosApp.get(`/accommodations` , {params: {
        filters: {
          type: filterType, 
        },
        page: params?.page ? params?.page : 1,  
        title: params?.title || "",  
      }, headers: {
        perpage: 8, 
      },});
      return { type: 'accommodations', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchAccommodationSingle = createAsyncThunk(
  'data/fetchAccommodationSingle',
  async (id , { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/accommodations/${id}`);
      return { type: 'accommodationSingle', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial State
const initialState = {
  accommodations:{
    loading: false,
    data: [],
    error: null,
  },
  accommodationSingle:{
    loading: false,
    data: [],
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'accommodations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchAccommodations
      .addCase(fetchAccommodations.pending, (state) => {
        state.accommodations.loading = true;
      })
      .addCase(fetchAccommodations.fulfilled, (state, action) => {
        state.accommodations.loading = false;
        state.accommodations.data = action.payload.data.data;
      })
      .addCase(fetchAccommodations.rejected, (state, action) => {
        state.accommodations.loading = false;
        state.accommodations.error = action.payload;
      })
      // fetchAccommodationSingle
      .addCase(fetchAccommodationSingle.pending, (state) => {
        state.accommodationSingle.loading = true;
      })
      .addCase(fetchAccommodationSingle.fulfilled, (state, action) => {
        state.accommodationSingle.loading = false;
        state.accommodationSingle.data = action.payload.data.data;
      })
      .addCase(fetchAccommodationSingle.rejected, (state, action) => {
        state.accommodationSingle.loading = false;
        state.accommodationSingle.error = action.payload;
      })
      ;
  },
});

export default dataSlice.reducer;
