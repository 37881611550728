import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Image, Input, Radio, Row, Select } from 'antd';
import ModalComponent from 'components/Ui/ModalComponent';
import { fetchCategories } from 'features/categories/categoriesSlice';
import useAppParams from 'hooks/useAppParams';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import logo from 'public/images/logo.svg'
import star from 'public/images/star-empty.svg'
import { fetchCategoriesBlog, fetchSystemAdmins } from 'features/blog/blogSlice';
const { Search } = Input;
const Filter = ({ filterButton, filterBlog , filterTourevent }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { categories } = useSelector((state) => state.categories);
  const { categoriesBlog , systemAdmins } = useSelector((state) => state.blogs);
  const { UrlParams, handleSearch } = useAppParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form2] = Form.useForm();


  useEffect(() => {
    dispatch(fetchCategories());
    if(filterBlog){
      dispatch(fetchCategoriesBlog());
      dispatch(fetchSystemAdmins());
    }else{
      dispatch(fetchCategories());
    }
  }, [])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const submitSearch = (values) => {
    handleSearch({
      fields: { name: values },
      deletedFields: ["page"],
    });
    setIsModalVisible(false);
  };

  const onFinishFilter = (values) => {
    handleSearch({
      fields: {
        ...UrlParams,
        ...JSON.parse(JSON.stringify(values))
      },
      deletedFields: ["page"],
    });
    setIsModalVisible(false);
  };

  const handleRemoveForm2 = () => {
    handleSearch({
      deletedFields: ["page", "name", "rating_number", "category_id"],
    });
    setIsModalVisible(false);
    form2.resetFields();
  };
  return (
    <>
      <div className="filter mb-10">
        <div className="flex mb-2">
          <Search allowClear onSearch={submitSearch} prefix={<FontAwesomeIcon icon={faSearch} className="me-2" />} placeholder={filterBlog ? t('search_specific_article') : filterTourevent ? t('choose_activity_want') : t('search_for_your_tourist_destination')} enterButton={t('search')} size="large" />
          {filterButton || filterBlog ?
            <Button onClick={showModal} className="bg-primary-2 text-white ms-2 h-auto"><i className="icon-filter text-primary font-size-20"></i></Button>

            : null}
        </div>
        <h6 className="text-primary font-size-14"> {filterBlog ? t('search_blog_results') : filterTourevent ? t('search_tourevent_results') : t('search_destination_results')} </h6>
      </div>
      <ModalComponent width={700}
        isVisible={isModalVisible}
        onClose={handleClose}
        title={false}
        footer={false}
      >
        <div className="cursor-pointer absolute top-9 border border-primary rounded-lg px-7 py-3 font-bold text-primary inline-flex items-center font-size-16" onClick={() => handleClose()} style={{ insetInlineStart: "25px", zIndex: "2" }}> <FontAwesomeIcon icon={faArrowRight} className="me-2" /> {t('back')}</div>
        <div className="text-center mb-5">
          <Image src={logo} alt="logo" height={80} preview={false} className="mx-auto" />
        </div>
        <div className="text-center mb-5">
          <h2 className="font-bold text-primary text-center">{t('filter_search')}</h2>
        </div>
        <h5 className="font-bold text-primary mb-4">{t('customize_search_request')}</h5>

        {filterBlog ? <Form form={form2} size="large" layout="vertical" onFinish={onFinishFilter}>
          <Form.Item
            label={t('category')}
            name="category_id">
            <Select loading={categoriesBlog?.loading} placeholder={t('choose_category')}>
              {categoriesBlog?.data?.content?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="system_admin_id"
            label={t('publisher')}
          >
           <Select loading={systemAdmins?.loading} placeholder={t('choose_publisher')}>
              {systemAdmins?.data?.content?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Row justify="space-between" className="mt-4">
              <Col span={11}>
                <Button htmlType="submit" className="h-auto py-3 bg-primary-2 text-white" block>
                 {t('apply_filter')}
                </Button>
              </Col>
              <Col span={11}>
                <Button block onClick={handleRemoveForm2} className="h-auto py-3 border border-primary text-primary border-solid">
                  {t('clear_filter')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form> :
          <Form form={form2} size="large" layout="vertical" onFinish={onFinishFilter}>
            <Form.Item
              label={t('category')}
              name="category_id">
              <Select placeholder={t('choose_category')}>
                {categories?.data?.content?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="rating_number"
              label={t('select_room_type')}
            >
              <Radio.Group className="text-center-group flex">
                <Radio.Button value="single" className="w-1/5 text-center">
                  <div className="radio-content flex items-center justify-center">
                    <span>{t('any_rating')}</span>
                  </div>
                </Radio.Button>
                <Radio.Button value="3.0" className="w-1/5 text-center">
                  <div className="radio-content flex items-center justify-center">
                    <span className="font-bold me-2 font-size-20">3.0</span>
                    <Image src={star} height={20} preview={false} />
                  </div>
                </Radio.Button>
                <Radio.Button value="3.5" className="w-1/5 text-center">
                  <div className="radio-content flex items-center justify-center">
                    <span className="font-bold me-2 font-size-20">3.5</span>
                    <Image src={star} height={20} preview={false} />
                  </div>
                </Radio.Button>
                <Radio.Button value="4.0" className="w-1/5 text-center">
                  <div className="radio-content flex items-center justify-center">
                    <span className="font-bold me-2 font-size-20">4.0</span>
                    <Image src={star} height={20} preview={false} />
                  </div>
                </Radio.Button>
                <Radio.Button value="4.5" className="w-1/5 text-center">
                  <div className="radio-content flex items-center justify-center">
                    <span className="font-bold me-1 font-size-20">4.5</span>
                    <Image src={star} height={20} preview={false} />
                  </div>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Row justify="space-between" className="mt-4">
                <Col span={11}>
                  <Button htmlType="submit" className="h-auto py-3 bg-primary-2 text-white" block>
                  {t('apply_filter')}
                  </Button>
                </Col>
                <Col span={11}>
                  <Button block onClick={handleRemoveForm2} className="h-auto py-3 border border-primary text-primary border-solid">
                  {t('clear_filter')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>}


      </ModalComponent>
    </>
  )
}

export default Filter