import TitlePage from "../../components/Shared/TitlePage";
import { fetchDataPagesSingle } from "../../features/pages/dataPagesSlice";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function PageAbout() {

  const { id } = useParams();
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.dataPages?.dataPageSingle || {});



  useEffect(() => {
    dispatch(fetchDataPagesSingle(id));
  }, [i18n.language, id])


  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {data?.title || ''}</title>
      </Helmet>
      <div>
        <TitlePage title={loading? <Skeleton height={20}  /> : data?.title} />
        <section className="pb-12 pt-16">
          <div className="container">
            {loading ? (
              <div>
                <Skeleton height={30} className="mb-1" style={{ width: "80%" }} />
                <Skeleton height={30} className="mb-1" style={{ width: "60%" }} />
                <Skeleton height={30} className="mb-1" style={{ width: "40%" }} />
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: data?.body }} />
            )}
          </div>
        </section>
      </div>
    </>

  );
}
