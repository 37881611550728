import { Col, Row } from 'antd'
import Accommodation from 'components/Shared/Accommodation'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const FavoritesAccommodations = () => {
  const {t} = useTranslation();
  return (
    <>
    <Helmet>
      <title>{t('nbt')} | {t('preferred_stays_preferred')}</title>
    </Helmet>
    <Row className='mb-6'>
      <Col span={24}>
      <h2 className='font-bold flex items-center'> <i class="icon-favourite font-size-20 me-2"></i> {t('preferred_stays_preferred')} </h2>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Accommodation pagination={false} dashboard={true} numberCard={8} favorable_type="Accommodation" />
      </Col>
    </Row>
    </>
  )
}

export default FavoritesAccommodations