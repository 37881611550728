import { Col, Row } from 'antd';
import React, { useState } from 'react';
import TableBooking from '../commponents/TableBooking';
import { useTranslation } from 'react-i18next';
import ModalComponent from 'components/Ui/ModalComponent';
import { Helmet } from 'react-helmet';

const CurrentBooking = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

 const showModal = (record) => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <>
    <Helmet>
      <title>{t('nbt')} | {t('my_current_trips')}</title>
    </Helmet>
    <Row className="mb-12">
      <Col span={24} className="mb-5">
        <div className="flex items-center justify-between">
          <h2 className='flex items-center text-black'> <i className="icon-calendar me-2 text-black"></i> {t('my_current_trips')}</h2>
        </div>
      </Col>
      <Col span={24}>
        <TableBooking trip="current" />
      </Col>
    </Row>
    <ModalComponent
        width={600}
        isVisible={isModalVisible}
        onClose={handleClose}
        title={t('trip_cancellation_request')}
        footer={false}
      >
      </ModalComponent>
    </>
  );
};

export default CurrentBooking;
