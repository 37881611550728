import TitlePage from "../../components/Shared/TitlePage";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";
import { fetchCategoriesFaqFaq, fetchFaq } from "features/faq/faqSlice";
import { Collapse, Pagination } from "antd";
import style from "./index.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import useAppParams from "hooks/useAppParams";
import EmptyData from "components/Shared/EmptyData";
import { Helmet } from "react-helmet";

export default function Faq({dashboard}) {
  const { handleSearch, UrlParams } = useAppParams({});
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { faq, categoriesFaq } = useSelector((state) => state.faq);
  const swiperRef = useRef(null); 
  const [activeIndex, setActiveIndex] = useState(null); 
  const [isScrolling, setIsScrolling] = useState(false); 

  useEffect(() => {
    dispatch(fetchCategoriesFaqFaq());
  }, [i18n.language]);

  useEffect(() => {
    if (categoriesFaq?.data?.content?.length > 0) {
      const firstCategoryId = categoriesFaq.data.content[0]?.id;

      dispatch(fetchFaq({
        params: { ...UrlParams, id: Number(UrlParams?.id ? UrlParams?.id : firstCategoryId), page: Number(UrlParams?.page) },
      }));
    }
  }, [categoriesFaq?.data?.content, i18n.language, UrlParams]);

  const items = faq?.data?.content && faq.data.content.length > 0
  ? faq.data.content.map((item) => ({
    key: item?.id,
    label: item?.title,
    children: <p>{item?.body}</p>,
  }))
  : [];

  const getFaq = (id) => {
    handleSearch({
      fields: { id: id },
      deletedFields: ["page"],
    });
  };

  const pageinate = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };

  useEffect(() => {
    if (UrlParams?.id) {
      const initialIndex = categoriesFaq?.data?.content?.findIndex(
        (categorie) => categorie.id === Number(UrlParams.id)
      );
      setActiveIndex(initialIndex !== -1 ? initialIndex : 0); 
    }
  }, [UrlParams?.id, categoriesFaq?.data?.content]);
  

  const handleSlideChange = (swiper) => {
    if (!isScrolling) {
      setActiveIndex(swiper.activeIndex);
    }
  };

  useEffect(() => {
    const swiper = swiperRef.current.swiper;
    if (swiper) {
      const slides = swiper.slides;
  
      slides.forEach(slide => slide.classList.remove('active'));
  
      if (activeIndex !== null && slides[activeIndex]) {
        slides[activeIndex].classList.add('active');
      }
    }
  }, [activeIndex]); 
  

  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('faq_2')}</title>
      </Helmet>
      <section className="py-10">
        <div className="container">
          {!dashboard ?
            <>
              <div className="mb-20">
                <TitlePage title={t('faq_2')} />
              </div>
              <div className="flex items-center mb-10">
                <i className="icon-question font-size-30 me-3"></i>
                <h2 className="font-bold font-size-30 text-primary"> {t('faq_system')} FAQ {t('nabat_provides_answers_frequently_asked_questions_come_mind')} </h2>
              </div></>
            : null}

          <div className="border rounded-lg p-5 lg:p-8">

            <div className="mb-6" style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}>

              <Swiper
                className={`${style.swiperCategoty}`}
                spaceBetween={15}
                ref={swiperRef}
                touchRatio={1}
                direction="horizontal"
                slidesPerView="auto"
                initialSlide={activeIndex || 0}
                onSlideChange={handleSlideChange}
                onTouchStart={() => setIsScrolling(true)}
                onTouchEnd={() => setIsScrolling(false)}
              >
                {categoriesFaq.loading ? <Skeleton height={20} /> :
                  categoriesFaq?.data?.content?.map((categorie, index) => (
                    <SwiperSlide
                      key={categorie.id}
                      className={`w-auto ${activeIndex === index ? 'active' : ''}`}
                      onClick={() => {
                        getFaq(categorie.id);
                        setActiveIndex(index);
                      }}
                    >
                      {categorie?.name}
                    </SwiperSlide>
                  ))
                }
              </Swiper>

            </div>
            {faq?.loading ?
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton key={index} className="mb-4" style={{ borderRadius: "4px" }} height={30} />
                ))}
              </>
              : faq?.data?.content?.length > 0 ? <Collapse className={`${style.nabtFaq} lg:ps-10`} items={items} accordion defaultActiveKey={['1']} />  : <EmptyData />
            }
            <Pagination
              align="end"
              className="my-3"
              onChange={pageinate}
              defaultCurrent={1}
              current={UrlParams?.page ? Number(UrlParams?.page) : 1}
              total={faq?.data?.pagination?.total}
              pageSize={7}
            />
          </div>

        </div>
      </section>
    </>

  );
}
