import React, { createContext, useContext } from "react";
import { LoadScript } from "@react-google-maps/api";

const GoogleMapsContext = createContext();

export const GoogleMapsProvider = ({ children }) => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyBAkKGhvSQrt8IiweX6Lx8Il_gr8yedTZc">
      <GoogleMapsContext.Provider value={true}>{children}</GoogleMapsContext.Provider>
    </LoadScript>
  );
};

export const useGoogleMaps = () => {
  return useContext(GoogleMapsContext);
};
