import React from "react";
import Routes from "../../../routes/Routers";
import Footer from "components/Layouts/Footer/index.jsx";
import Header from "components/Layouts/Header/index.jsx";
const Layout = () => {
  return (
    <div className="app"> 
      <Header />
      <main>
        <Routes />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
