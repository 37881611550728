import { Avatar, Badge, Button, Col, Image, notification, Pagination, Row, Tabs } from 'antd'
import EmptyData from 'components/Shared/EmptyData'
import { fetchNotifications, fetchNotificationsSeen, fetchNotificationsNotSeen, postMarkAsRead, postMarkAllAsRead, toggleReadStatus, toggleReadStatusNotSeen, toggleReadStatusSeen } from 'features/notifications/notificationsSlice'
import useAppParams from 'hooks/useAppParams'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
const { TabPane } = Tabs;
const Notifications = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { notifications, notificationsSeen, notificationsNotSeen, markAllAsRead } = useSelector((state) => state.notifications);
  const [loadingIds, setLoadingIds] = useState({});
  const { handleSearch, UrlParams } = useAppParams({});
  const [state, setState] = useState("1");
  const [activeKey, setActiveKey] = useState("1");
  useEffect(() => {
    if (UrlParams) {
      if (state === "1") {
        dispatch(fetchNotifications({
          params: {
            ...UrlParams,
            page: Number(UrlParams?.page || 1),
          },
        }));
      }
    } else if (state === "2") {
      dispatch(fetchNotificationsSeen({
        params: {
          ...UrlParams,
          page: Number(UrlParams?.page || 1),
        },
      }))
    } else if (state === "3") {
      dispatch(fetchNotificationsNotSeen({
        params: {
          ...UrlParams,
          page: Number(UrlParams?.page || 1),
        },
      }));
    }

  }, [UrlParams, i18n.language]);

  const handleTabChange = (key) => {
    setState(key)
    handleSearch({
      fields: {
        page: 1,
      },
    });
    if (key === "1") {
      setActiveKey("1");
      dispatch(fetchNotifications({
        params: {
          ...UrlParams,
          page: 1,
        },
      }));
    } else if (key === "2") {
      setActiveKey("2");
      dispatch(fetchNotificationsSeen({
        params: {
          ...UrlParams,
          page: 1,
        },
      }))
    } else if (key === "3") {
      setActiveKey("3");
      dispatch(fetchNotificationsNotSeen({
        params: {
          ...UrlParams,
          page: 1,
        },
      }));
    }
  };

  const handelMarkAsRead = async ({ id, types }) => {
    setLoadingIds((prev) => ({ ...prev, [id]: true }));
    try {
      const resultAction = await dispatch(postMarkAsRead(id));

      if (postMarkAsRead.fulfilled.match(resultAction)) {
        const { type } = resultAction.payload;
        if (type === 'markAsRead') {
          if (types === 'all') {
            dispatch(toggleReadStatus({ type: 'notification', notificationId: id }));
          } else if (types === 'seen') {
            dispatch(toggleReadStatusSeen({ notificationId: id }));
            dispatch(fetchNotificationsSeen({
              params: { ...UrlParams, page: Number(UrlParams?.page || 1), perpage: 1 },
            }));
          } else if (types === 'notSeen') {
            dispatch(toggleReadStatusNotSeen({ notificationId: id }));
            dispatch(fetchNotificationsNotSeen({
              params: { ...UrlParams, page: Number(UrlParams?.page || 1), perpage: 1 },
            }));
          }
        }
      } else if (postMarkAsRead.rejected.match(resultAction)) {
        notification.error({
          description: resultAction.payload || t('an_error_occurred_while_updating_notification_status'),
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        description: error?.message || t('an_unexpected_error_occurred'),
        duration: 3,
      });
    } finally {
      setLoadingIds((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handelMarkAllAsRead = async () => {
    try {
      const resultAction = await dispatch(postMarkAllAsRead());

      if (postMarkAllAsRead.fulfilled.match(resultAction)) {
        if (notifications?.data) {
          const updatedNotifications = notifications.data.map((notif) => ({
            ...notif,
            read_at: new Date().toISOString(),
          }));
          dispatch(toggleReadStatus({ type: 'allNotifications', updatedNotifications }));
          setActiveKey('1');
          dispatch(fetchNotifications({
            params: { ...UrlParams, page: Number(UrlParams?.page || 1) },
          }));
        }
      } else {
        notification.error({
          description: resultAction.payload || t('failed_mark_notifications_read'),
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        description: error?.message || t('an_unexpected_error_occurred_marking_all_notification'),
        duration: 3,
      });
    }
  };
  const pageinate = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };
  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('notifications')} </title>
      </Helmet>
      <Row className='mb-6'>
        <Col span={24}>
          <h2 className='font-bold font-size-30 text-center'>{t('notification_center')}</h2>
        </Col>
      </Row>
      <Row className='mb-6'>
        <Col span={24} lg={8}>
        </Col>
        <Col span={24} lg={8}>
          <h2 className='font-size-30 text-center'>{t('notifications')}</h2>
        </Col>
        {notifications?.data?.length > 0 ?
          <Col span={24} lg={8} className='text-center lg:text-end'>
            <Button loading={markAllAsRead?.loading} onClick={handelMarkAllAsRead}>{t('mark_all_read')}  <Badge color="#B57134" count={notifications.data.unread_notifications_count} showZero />
            </Button>
          </Col> : null}

      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="1" activeKey={activeKey} centered onChange={handleTabChange}>
            <TabPane tab={t('all_notifications')} key="1">
              {notifications.loading ? (
                <>
                  <Skeleton height={20} className="mb-3" style={{ width: "30%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "60%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "90%" }} />
                </>
              ) : notifications?.data?.length > 0 ? (
                <>
                  {notifications?.data?.map((notification) => (
                    <div
                      key={notification.id}
                      className={`px-[5px] py-5 border-b border-[#959494] justify-between items-center flex`}
                      style={{
                        backgroundColor: notification.read_at === null ? '#FFF2E7' : '#FFF', 
                      }}
                    >
                      <div className="grow shrink basis-0 items-center gap-[19px] flex">
                        <div className="px-0.5 py-1 flex-col justify-start items-start inline-flex">
                          <Avatar shape='circle' size={62} src={notification?.image} />
                        </div>
                        <div className="flex-col justify-start gap-[19px] inline-flex">
                          <div className="self-stretch flex-col justify-center gap-[5px] flex">
                            <div className="text-black text-sm font-bold font-['Greta Arabic']">
                              {notification?.title}
                            </div>
                            <div className="text-[#757a81] text-sm font-normal font-['Greta Arabic']">
                              {notification?.message}
                            </div>
                          </div>
                          <h6 className="font-medium font-size-12 text-[#A5ACB8]">{notification?.created_at}</h6>
                        </div>
                      </div>
                      <div className="p-2.5 justify-start items-center gap-[18.29px] flex">
                        <Button className='p-0 h-auto border-0 bg-transparent font-size-20'
                          loading={loadingIds[notification?.id]}
                          onClick={() => handelMarkAsRead({ id: notification.id, types: "all" })}
                        >
                          {notification.read_at === null ? <i className='icon-email-unread'></i> : <span class="icon-message-open"><span class="path1"></span><span class="path2"></span></span>}
                        </Button>
                      </div>
                    </div>
                  ))}
                  <Pagination
                    align="end"
                    className="my-3"
                    onChange={pageinate}
                    defaultCurrent={1}
                    current={UrlParams?.page ? Number(UrlParams?.page) : 1}
                    total={notifications?.pagination?.total}
                    pageSize={5}
                  />
                </>
              ) : (
                <EmptyData />
              )}

            </TabPane>
            <TabPane tab={t('read')} key="2">
              {notificationsSeen.loading ? (
                <>
                  <Skeleton height={20} className="mb-3" style={{ width: "30%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "60%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "90%" }} />
                </>
              ) : notificationsSeen?.data?.length > 0 ? (
                <>
                  {notificationsSeen?.data?.map((notification) => (
                    <div
                      key={notification.id}
                      className={`px-[5px] py-5 border-b border-[#959494] justify-between items-center flex`}
                      style={{
                        backgroundColor: notification.read_at === null ? '#FFF2E7' : '#FFF', // لون الخلفية
                      }}
                    >
                      <div className="grow shrink basis-0 items-center gap-[19px] flex">
                        <div className="px-0.5 py-1 flex-col justify-start items-start inline-flex">
                          <Avatar shape='circle' size={62} src={notification?.image} />
                        </div>
                        <div className="flex-col justify-start gap-[19px] inline-flex">
                          <div className="self-stretch flex-col justify-center gap-[5px] flex">
                            <div className="text-black text-sm font-bold font-['Greta Arabic']">
                              {notification?.title}
                            </div>
                            <div className="text-[#757a81] text-sm font-normal font-['Greta Arabic']">
                              {notification?.message}
                            </div>
                          </div>
                          <h6 className="font-medium font-size-12 text-[#A5ACB8]">{notification?.created_at}</h6>
                        </div>
                      </div>
                      <div className="p-2.5 justify-start items-center gap-[18.29px] flex">
                        <Button className='p-0 h-auto border-0 bg-transparent font-size-20'
                          loading={loadingIds[notification?.id]}
                          onClick={() => handelMarkAsRead({ id: notification.id, types: "seen" })}
                        >
                          {notification.read_at === null ? <i className='icon-email-unread'></i> : <span class="icon-message-open"><span class="path1"></span><span class="path2"></span></span>}
                        </Button>
                      </div>
                    </div>
                  ))}
                  <Pagination
                    align="end"
                    className="my-3"
                    onChange={pageinate}
                    defaultCurrent={1}
                    current={UrlParams?.page ? Number(UrlParams?.page) : 1}
                    total={notifications?.pagination?.total}
                    pageSize={5}
                  />
                </>
              ) : (
                <EmptyData />
              )}

            </TabPane>
            <TabPane tab={t('unread')} key="3">
              {notificationsNotSeen.loading ? (
                <>
                  <Skeleton height={20} className="mb-3" style={{ width: "30%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "60%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "90%" }} />
                </>
              ) : notificationsNotSeen?.data?.length > 0 ? (
                <>
                  {notificationsNotSeen?.data?.map((notification) => (
                    <div
                      key={notification.id}
                      className={`px-[5px] py-5 border-b border-[#959494] justify-between items-center flex`}
                      style={{
                        backgroundColor: notification.read_at === null ? '#FFF2E7' : '#FFF', // لون الخلفية
                      }}
                    >
                      <div className="grow shrink basis-0 items-center gap-[19px] flex">
                        <div className="px-0.5 py-1 flex-col justify-start items-start inline-flex">
                          <Avatar shape='circle' size={62} src={notification?.image} />
                        </div>
                        <div className="flex-col justify-start gap-[19px] inline-flex">
                          <div className="self-stretch flex-col justify-center gap-[5px] flex">
                            <div className="text-black text-sm font-bold font-['Greta Arabic']">
                              {notification?.title}
                            </div>
                            <div className="text-[#757a81] text-sm font-normal font-['Greta Arabic']">
                              {notification?.message}
                            </div>
                          </div>
                          <h6 className="font-medium font-size-12 text-[#A5ACB8]">{notification?.created_at}</h6>
                        </div>
                      </div>
                      <div className="p-2.5 justify-start items-center gap-[18.29px] flex">
                        <Button className='p-0 h-auto border-0 bg-transparent font-size-20'
                          loading={loadingIds[notification?.id]}
                          onClick={() => handelMarkAsRead({ id: notification.id, types: "notSeen" })}
                        >
                          {notification.read_at === null ? <i className='icon-email-unread'></i> : <span class="icon-message-open"><span class="path1"></span><span class="path2"></span></span>}
                        </Button>
                      </div>
                    </div>
                  ))}
                  <Pagination
                    align="end"
                    className="my-3"
                    onChange={pageinate}
                    defaultCurrent={1}
                    current={UrlParams?.page ? Number(UrlParams?.page) : 1}
                    total={notifications?.pagination?.total}
                    pageSize={5}
                  />
                </>
              ) : (
                <EmptyData />
              )}

            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  )
}

export default Notifications