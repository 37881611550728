import axios from "axios";
import { useTranslation } from "react-i18next";

const axiosApp = axios.create({
  baseURL: 'https://3sem.tech/beta/public/api/',
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Accept": "application/json",
    'Content-Type': 'application/json',
  },


});


axiosApp.interceptors.request.use(
  (config) => {
    const language = localStorage.getItem('language') || 'ar'; 
    config.headers['X-Localization'] = language; 
    
    
    const token = localStorage.getItem('token'); 
    if (token) {
      const sanitizedToken = token.replace(/^"|"$/g, '');
      config.headers['Authorization'] = `${sanitizedToken}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Request interceptor
export const setupAxiosInterceptors = (language, token) => {
  axiosApp.interceptors.request.use(
    (config) => {
      // Set Accept-Language header

      const { i18n } = useTranslation();
      const language = i18n.language || 'en';

      config.headers['X-Localization'] = language;

      // Set Authorization header if token exists
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

axiosApp.interceptors.response.use(
  (response) => {
    // Pass the response if no error
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle Unauthenticated error
      console.error("Unauthenticated error: Logging out");
      localStorage.removeItem("token"); // Remove token
      localStorage.removeItem("user"); // Remove language if needed
      window.location.href = "/"; // Redirect to homepage or login page
    }

    // Reject the promise with the error
    return Promise.reject(error);
  }
);


export default axiosApp;
