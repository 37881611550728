import TitlePage from "components/Shared/TitlePage";
import { fetchContact, postInboxes } from "features/contact/contactSlice";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import style from "./index.module.scss";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import GoogleMapComponent from "components/Shared/Map";
import { useForm } from "antd/es/form/Form";
import bg from 'public/images/bg-contact.png'

const containerStyle = {
  width: "100%",
  height: "540px",
  marginTop: "30px",
  border: "5px solid #B57134",
  borderRadius: "8px"
};

export default function Contact() {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { contact, inboxes } = useSelector((state) => state.contact);
  const { i18n } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchContact())
  }, [i18n.language])


  const onFinish = (values) => {
    dispatch(postInboxes({
      values, callback: () => {
        form.resetFields();
      }
    }))
  }

  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('contact_us')}</title>
      </Helmet>
      <TitlePage title={contact?.data?.title} bgContact={true} />
      <section className={`${style.bgContact}`}>
        {/* <section className="pb-10 pt-20 my-10" style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}> */}
        <div className={`${style.headerContant}`}>
          <div className="container">
            <Row gutter={25}>
              <Col span={24} lg={8} className="mb-10 lg:mb-0">
                <div className="mb-5 lg:mb-lg-0 relative">
                  <div className="w-16 text-xl h-16 rounded-xl mx-auto bg-primary text-white inline-flex items-center justify-center absolute right-1/2 translate-x-1/2 -top-8">
                    <i className="icon-user2 text-white font-size-30"></i>
                  </div>
                  <div className="bg-content px-6 pt-12 pb-8 rounded-xl">
                    <h3 className="text-primary font-bold text-center mb-8">{t('contact_us')}</h3>

                    <h5 className="text-end mb-4">
                      {contact?.loading ? (
                        <Skeleton height={30} />
                      ) : (
                        <a
                          href={`mailto:${contact?.data?.email}`}
                          className="text-black flex items-center font-bold justify-end"
                        >
                          {contact?.data?.email}
                          <i className="icon-email ms-2 font-size-22"></i>
                        </a>
                      )}
                    </h5>
                    <h5 className="text-end">
                      {contact?.loading ? (
                        <Skeleton height={30} />
                      ) : (
                        <a
                          href={`tel:${contact?.data?.phone}`}
                          className="text-black flex items-center font-bold justify-end"
                        >
                          {contact?.data?.phone}
                          <i className="icon-phone ms-2 font-size-22"></i>
                        </a>
                      )}
                    </h5>


                  </div>
                </div>
              </Col>

              {/* Social Media Links */}
              <Col span={24} lg={8} className="mb-10 lg:mb-0">
                <div className="bg-content px-6 pt-12 pb-8 rounded-xl">
                  <div className="w-16 text-xl h-16 rounded-xl mx-auto bg-primary text-white inline-flex items-center justify-center absolute right-1/2 translate-x-1/2 -top-8">
                    <i className="icon-share font-size-28"></i>
                  </div>
                  <h3 className="text-primary font-bold text-center mb-6">{t('social_media_platforms')}</h3>
                  {contact?.loading ? (
                    <Skeleton height={100} />
                  ) : (
                    <>
                      {contact?.data?.instagram && (
                        <SocialMediaLink
                          icon='instagram'
                          url={contact?.data?.instagram}
                        />
                      )}
                      {contact?.data?.whatsApp && (
                        <SocialMediaLink
                          icon={faWhatsapp}
                          url={contact?.data?.whatsApp}
                        />
                      )}
                      {contact?.data?.linkedIn && (
                        <SocialMediaLink
                          icon='linkedin'
                          url={contact?.data?.linkedIn}
                        />
                      )}
                      {contact?.data?.snapchat && (
                        <SocialMediaLink
                          icon='snapchat'
                          url={contact?.data?.snapchat}
                        />
                      )}
                      {contact?.data?.tikTok && (
                        <SocialMediaLink
                          icon='tikTok'
                          url={contact?.data?.tikTok}
                        />
                      )}
                      {/* Add other platforms similarly */}
                    </>
                  )}
                </div>
              </Col>

              {/* Phone */}
              <Col span={24} lg={8}>
                <div className="mb-5 lg:mb-lg-0 relative">
                  <div className="w-16 text-xl h-16 rounded-xl mx-auto bg-primary text-white inline-flex items-center justify-center absolute right-1/2 translate-x-1/2 -top-8">
                    <i className="icon-location text-white font-size-28"></i>
                  </div>
                  <div className="bg-content px-6 pt-12 pb-8 rounded-xl text-center">
                    <h3 className="text-primary font-bold text-center mb-8">{t('location')}</h3>
                    <h5 className="text-primary-2">
                      {contact?.loading ? (
                        <Skeleton height={30} />
                      ) : (
                        contact?.data?.address
                      )}
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <section className="py-10 mb-10 mt-20">
        <div className="container">
          <Row>
            <Col lg={12}>
              <h4 className="font-bold text-primary mb-3">{t('contacus_share_feedback')}!</h4>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col span={24} lg={12}>
              <Form form={form} layout="vertical" size="large" onFinish={onFinish}>
                <Form.Item
                  label={t('name')}
                  name="name"
                  rules={[{ required: true, message: t('field_required') }]}
                >
                  <Input placeholder="قم بكتابة اسمك ثلاثي" />
                </Form.Item>
                <Form.Item
                  label={t('phone')}
                  name="phone"
                  rules={[{ required: true, message: t('field_required') },
                  {
                    type: "number",
                    message: t('enter_valid_number'),
                    transform: (value) => (value ? Number(value) : undefined), // Transform input to a number
                  },
                  ]}
                >
                  <Input placeholder={t('phone')} />
                </Form.Item>
                <Form.Item
                  label={t('subject')}
                  name="subject"
                  rules={[{ required: true, message: t('field_required') }]}
                >
                  <Input placeholder={t('subject')} />
                </Form.Item>
                <Form.Item
                  label={t('message')}
                  name="message"
                  rules={[{ required: true, message: t('field_required') }]}
                >
                  <Input.TextArea rows={4} placeholder={t('message')} />
                </Form.Item>
                <Form.Item>
                  <Button block loading={inboxes?.loading} className="bg-primary-2 text-white font-bold px-8 py-3 h-auto font-size-18" htmlType="submit">
                    أرسل
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={24} lg={12}>
              <GoogleMapComponent lat={contact?.data?.lat} lng={contact?.data?.lng} containerStyle={containerStyle} />
            </Col>
          </Row>
        </div>
      </section>
      {/* <section className={`${style.sectionBookNow} section bg-primary`}>
        <div className="container">
          <Row justify="center">
            <Col span={24} lg={20}>
              <div className="text-center">
                <h3 className="text-primary mb-2">{t('phone')}</h3>
                <h3 className="text-white mb-4">{contact?.data?.footer}</h3>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Row justify="center" className="-mt-16 mb-10 px-2">
        <Col span={24} lg={5}> <a className="btn btn-primary-2 w-full block text-center py-4 rounded-lg font-size-20" href="">{t('book_now')}<span className="icon"><i className="icon-ticket"></i></span></a></Col>
      </Row> */}
    </>
  );
}

function SocialMediaLink({ icon, url }) {
  return (
    <div className="flex items-center mb-2">
      <div className="ml-5 w-12 text-xl h-12 rounded-full bg-primary text-white inline-flex items-center justify-center">
        <FontAwesomeIcon icon={icon} />
        <span className={'icon-' + icon}></span>
      </div>
      <h5>
        <a href={url} target="_blank" rel="noopener noreferrer" className="text-primary">
          {url}
        </a>
      </h5>
    </div>
  );
}
