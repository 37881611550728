import { setError } from 'features/error/errorSlice';
import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const fetchFaq = createAsyncThunk(
  'data/fetchFaq',
  async (params, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/faqs` , {params: {
        filters: {
          category_id: params?.params?.id,  
        },
        "filter_operator": "like",
        page: params?.params?.page ? params?.params?.page : 1,
      }, headers: {
        perpage: 7, 
      },});
      return { type: 'faq', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchCategoriesFaqFaq = createAsyncThunk(
  'data/fetchCategoriesFaq',
  async (_, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/categories/faq`);
      return { type: 'categoriesFaq', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchCategoriesCancellation = createAsyncThunk(
  'data/fetchCategoriesCancellation',
  async (_, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/categories/cancellation`);
      return { type: 'categoriesCancellation', data: response.data };
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return rejectWithValue('Invalid request data. Please check the filters or API requirements.');
      }
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);


// Initial State
const initialState = {
  faq: {
    loading: false,
    data: [],
    error: null,
  },
  categoriesFaq: {
    loading: false,
    data: [],
    error: null,
  },
  categoriesCancellation: {
    loading: false,
    data: [],
    error: null,
  },
};

// Slice
const dataSlice2 = createSlice({
  name: 'pages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchFaq
      .addCase(fetchFaq.pending, (state) => {
        state.faq.loading = true;
      })
      .addCase(fetchFaq.fulfilled, (state, action) => {
        state.faq.loading = false;
        state.faq.data = action.payload.data.data;
      })
      .addCase(fetchFaq.rejected, (state, action) => {
        state.faq.loading = false;
        state.faq.error = action.payload;
      })
      // fetchCategoriesFaqFaq
      .addCase(fetchCategoriesFaqFaq.pending, (state) => {
        state.categoriesFaq.loading = true;
      })
      .addCase(fetchCategoriesFaqFaq.fulfilled, (state, action) => {
        state.categoriesFaq.loading = false;
        state.categoriesFaq.data = action.payload.data.data;
      })
      .addCase(fetchCategoriesFaqFaq.rejected, (state, action) => {
        state.categoriesFaq.loading = false;
        state.categoriesFaq.error = action.payload;
      })
      // fetchCategoriesCancellation
      .addCase(fetchCategoriesCancellation.pending, (state) => {
        state.categoriesCancellation.loading = true;
      })
      .addCase(fetchCategoriesCancellation.fulfilled, (state, action) => {
        state.categoriesCancellation.loading = false;
        state.categoriesCancellation.data = action.payload.data.data;
      })
      .addCase(fetchCategoriesCancellation.rejected, (state, action) => {
        state.categoriesCancellation.loading = false;
        state.categoriesCancellation.error = action.payload;
      });
  },
});

export default dataSlice2.reducer;
