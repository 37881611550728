import Faq from 'pages/faq'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const DashboardFaq = () => {
  const {t} = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('faq')}</title>
      </Helmet>
      <div>
        <Faq dashboard={true} />
      </div>
    </>
  )
}

export default DashboardFaq