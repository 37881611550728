import React, { useEffect, useState } from "react";
import { Button, Card, Image, Col, Row, Pagination, Tabs, Spin } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheck, faStar } from "@fortawesome/free-solid-svg-icons";
import style from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import useAppParams from "hooks/useAppParams.js";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import ModalComponent from "components/Ui/ModalComponent/index.jsx";
import TabPane from "antd/es/tabs/TabPane.js";
import logo from 'public/images/logo.svg'
import { fetchFavorites } from "features/favorites/favoritesSlice";
import FavoriteToggle from "components/Shared/FavoriteToggle";
import { fetchAccommodations, fetchAccommodationSingle, fetchLandmarks } from "features/home/homeSlice";
import EmptyData from "components/Shared/EmptyData";
import SliderThumb from "components/Shared/SliderThumb";
import TestimonialSingle from "components/Shared/TestimonialSingle";
import DetailsAccommodation from "./details";

const Accommodation = ({ selectedCardId, onCardSelect, favorable_type, numberCard, dashboard }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading: accommodationsLoading, data: accommodations, pagination: paginations } = useSelector((state) => state.data.accommodations);
  const { loading: accommodationsSingleLoading, data: accommodationSingle } = useSelector((state) => state.data.accommodationSingle);
  const { loading: landmarksLoading, data: landmarks } = useSelector((state) => state.data.landmarks);

  const { handleSearch, UrlParams } = useAppParams({});
  const { i18n } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const { favorites } = useSelector((state) => state.favorites);


  useEffect(() => {
    if (favorable_type === "Accommodation" && UrlParams) {
      dispatch(
        fetchFavorites({
          params: {
            ...UrlParams,
            page: Number(UrlParams?.page || 1),
            favorable_type: favorable_type,
            perpage: numberCard,
          },
        })
      )
    } else if (UrlParams) {
      dispatch(fetchAccommodations({
        params: {
          ...UrlParams,
          page: Number(UrlParams?.page || 1),
          perpage: numberCard,
        },
      }));
    }
  }, [i18n.language, UrlParams]);


  useEffect(() => {
    console.log(accommodationSingle)
  }, [accommodationSingle])
  

  const pageinate = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };


  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleCardClick = (id) => {
    setActiveCard(id);
    onCardSelect(id);
  };



  useEffect(() => {
    if (selectedCardId) {
      setActiveCard(selectedCardId);
    }
  }, [selectedCardId]);


  const showModal = (id) => {
    setIsModalVisible(true);
    dispatch(fetchAccommodationSingle(id));
    dispatch(fetchLandmarks())
  };

  const selectedCard = (e , id) => {
    e.stopPropagation();
    if (onCardSelect != undefined) {
      handleCardClick(id);
      setIsModalVisible(false);
    }
  };



  return (
    <>
      {(accommodationsLoading || favorites?.loading) ? (
        <Row gutter={25}>
          {window.innerWidth > 992 ? <>{Array.from({ length: 4 }).map((_, index) => (
            <Col span={24} md={12} key={index}>
              <Skeleton className="mb-4" style={{ borderRadius: "16px" }} height={220} />
            </Col>
          ))}</> : <>{Array.from({ length: 1 }).map((_, index) => (
            <Col span={24} md={12} key={index}>
              <Skeleton className="mb-4" style={{ borderRadius: "16px" }} height={220} />
            </Col>
          ))}</>}

        </Row>
      ) : (
        <>
          {((accommodations?.length > 0 || favorites?.data?.content?.length > 0) ? (
            <Row gutter={25}>
              {(accommodations?.length > 0 ? accommodations : favorites?.data?.content || []).map((item) => (
                <Col span={24} lg={12} className="mb-5" key={item.id}>
                  <Card
                    bordered
                    className={`${activeCard === item.id ? style.selected : ""} ${onCardSelect ? 'cursor-pointer' : null} ${style.cardAccommodation} overflow-hidden border-0 mb-3`}
                    onClick={(e) => selectedCard(e, item?.id)}
                  >



                    {activeCard === item.id && (
                      <div className="bg-white start-5 top-[20px] w-[40px] h-[40px] rounded-full absolute flex items-center justify-center font-size-26 z-2">
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                    )}
                    <div className="lg:flex">
                      <div className="col-auto relative">
                        {localStorage.getItem("user") && (
                          <FavoriteToggle
                            cardId={item?.favorable?.id ? item?.favorable?.id : item.id}
                            isFavorited={item?.isFavorited || item?.favorable?.isFavorited}
                            loading={item.loading}
                            favorable_type={"App\\Models\\Accommodation"}
                            dashboard={dashboard}
                          />
                        )}
                        <Image preview={false} width={onCardSelect ? 250 : 210} style={{ objectFit: "cover" }} height={onCardSelect ? 220 : 180} src={item?.favorable?.image || item?.image} />
                      </div>
                      <div className="lg:ms-3 lg:px-3 pt-3 flex-1">
                        <h4 className="font-bold text-primary text-center mb-1">{item?.favorable?.name || item?.name}</h4>
                        <div className="flex items-center justify-between mb-2 px-2 lg:px-0">
                          <div className="bg-primary px-3 font-bold py-1 font-size-12 text-white rounded-full">
                            {item?.favorable?.type === 'RESORT' ? t('resort') :
                              item?.favorable?.type === 'CHALET' ? t('chalet') :
                                item?.favorable?.type === 'HOTEL' ? t('hotel') :
                                  item?.favorable?.type === 'CARAVAN' ? t('caravan') :
                                    item?.favorable?.type === 'CAMP' ? t('camp') :
                                      item?.type === 'RESORT' ? t('resort') :
                                        item?.type === 'CHALET' ? t('chalet') :
                                          item?.type === 'HOTEL' ? t('hotel') :
                                            item?.type === 'CARAVAN' ? t('caravan') :
                                              item?.type === 'CAMP' ? t('camp') : null}
                          </div>
                          <h5 className="text-primary font-bold px-2 lg:px-0">
                            {item?.favorable?.rating !== undefined ? item?.favorable?.rating : item?.rating}
                            <FontAwesomeIcon icon={faStar} />
                          </h5>
                        </div>
                        <h5 className="text-center font-size-15 mb-2 overflow-hidden h-1/3">{item?.favorable?.body || item?.body}</h5>
                        <Button onClick={(e) => {
                          e.stopPropagation();
                          showModal(item?.favorable?.id || item?.id);
                        }} type="primary" className="w-full h-auto font-bold py-2" size="large">
                          {t('more_read')}
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
              <Col span={24}>
                <Pagination
                  align="end"
                  className="my-3"
                  onChange={pageinate}
                  defaultCurrent={1}
                  current={UrlParams?.page ? Number(UrlParams?.page) : 1}
                  total={paginations?.total || favorites?.data?.pagination?.total}
                  pageSize={numberCard}
                />
              </Col>
            </Row>
          ) : (
            <EmptyData />
          ))}
        </>
      )}


      <ModalComponent width={1000}
        isVisible={isModalVisible}
        onClose={handleClose}
        title={false}
        footer={false}
      >
        <div className="cursor-pointer absolute top-9 border border-primary rounded-lg px-7 py-3 font-bold text-primary inline-flex items-center font-size-16" onClick={() => handleClose()} style={{ insetInlineStart: "25px", zIndex: "2" }}> <FontAwesomeIcon icon={faArrowRight} className="me-2" /> {t('back')}</div>

        <div className="text-center mb-5">
          <Image src={logo} alt="logo" height={80} preview={false} className="mx-auto" />
        </div>
        {accommodationsSingleLoading ? <div className="text-center"><Spin /> </div> : (
          <div className="p-4">
            <div className="lg:flex items-center justify-between mb-3">
              <h2 className="mb-3 lg:mb-0 font-bold">{t('detailed_information_about')}  <span className="text-primary">{accommodationSingle?.data?.title} :</span></h2>
              <div className="justify-start items-center gap-[2.96px] inline-flex">
                <h2 className="text-primary font-bold">{accommodationSingle?.data?.rating} <FontAwesomeIcon icon={faStar} /> </h2>
              </div>
            </div>
            <Tabs defaultActiveKey="1" centered className="tab-napt mb-6">
              <TabPane tab={t('general_information')} key="1">
                <Row align="middle">
                  <Col span={24} lg={16} className="mb-5 lg:mb-0" dir="rtl">
                    <SliderThumb count={9} images={accommodationSingle?.images?.content} />
                  </Col>
                  <Col span={24} lg={8} className="lg:ps-12">
                    <h2 className="font-bold text-black mb-7"> {t('details_available_facilities')}</h2>
                    <DetailsAccommodation wifi={accommodationSingle?.data?.wifi} rooms={accommodationSingle?.data?.rooms}
                      restaurants={accommodationSingle?.data?.restaurants} cafes={accommodationSingle?.data?.cafes}
                      type={accommodationSingle?.data?.type} rating={accommodationSingle?.data?.rating}
                    />
                    {/* <div className="flex items-center mb-5 lg:mb-7">
                      <Image src={wifi} alt="wifi" />
                      <h5 className="ms-4">{Number(accommodationSingle?.data?.wifi) === 0 ? 'خدمة الوايفاي ليست مجانية ' : 'خدمة الوايفاي مجانية في كل المكان '}</h5>
                    </div>
                    <div className="flex items-center justify-between mb-5 lg:mb-7">
                      <Image src={room} alt="room" />
                      <h5 className="ms-3 w-[130px]">عدد الغرف   </h5>
                      <div className="flex items-center justify-between">
                        <h4 className="me-3">{accommodationSingle?.data?.rooms}</h4>
                        <h5>غرفة </h5>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-5 lg:mb-7">
                      <Image src={food} alt="fluent_food" />
                      <h5 className="ms-3 w-[130px]">عدد المطاعم   </h5>
                      <div className="flex items-center justify-between">
                        <h4 className="me-3">{accommodationSingle?.data?.restaurants}</h4>
                        <h5>مطاعم </h5>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-5 lg:mb-7">
                      <Image src={coffee} alt="coffee" />
                      <h5 className="ms-3 w-[130px]">عدد الكافيهات   </h5>
                      <div className="flex items-center justify-between">
                        <h4 className="me-3">{accommodationSingle?.data?.cafes}</h4>
                        <h5>كفيهات </h5>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-5 lg:mb-7">
                      <h5>{t('category')}   </h5>
                      <h4>
                        {accommodationSingle?.data?.type === 'HOTEL' ? 'المنتجعات' :
                          accommodationSingle?.data?.type === 'CARAVAN' ? 'الشاليهات' :
                            accommodationSingle?.data?.type === 'CHALET' ? 'الفنادق' :
                              accommodationSingle?.data?.type === 'CAMP' ? 'كرفانات' :
                                accommodationSingle?.data?.type === 'RESORT' ? 'خيام' : null}
                      </h4>
                    </div>
                    <div className="flex items-center justify-between mb-5 lg:mb-7">
                      <h5>التقييم   </h5>
                      <h4>{accommodationSingle?.data?.rating} <FontAwesomeIcon icon={faStar} className="text-primary" /> </h4>
                    </div> */}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={t('venue_details')} key="2">
                <div className="mb-4">
                  <div dangerouslySetInnerHTML={{ __html: accommodationSingle?.data?.body }} />
                </div>
              </TabPane>
              <TabPane tab={t('map_ratings')} key="3">
                <Row gutter={20} justify="space-between">
                  <Col span={24} lg={9} className="mb-5 lg:mb-0">
                    <h6 className="text-primary mb-4 font-bold">{t('location_nearby_landmarks')}</h6>
                    {landmarksLoading ? <Skeleton height={20} /> : landmarks?.content?.length > 0 ?

                      <>
                        {landmarks?.content?.map((item) => (
                          <div className="flex items-center justify-between mb-3" key={item?.id}>
                            <h4>{item?.title}</h4>
                            <h4><Link to={`/landmarks/${item?.id}`} className="font-size-20 text-primary flex items-center">{t('for_details')} <FontAwesomeIcon icon={faArrowLeft} className="ms-2" /> </Link></h4>
                          </div>
                        ))}
                      </>

                      : t('no_data')}

                  </Col>
                  <Col span={24} lg={13}>
                    <h6 className="text-primary mb-4 font-bold">{t('reviews')}</h6>
                    <TestimonialSingle testimonial={accommodationSingle?.testimonials?.content} limit={true} />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
            <Button onClick={(e) => selectedCard(e, accommodationSingle?.data?.id)} className="h-auto w-full bg-primary-2 block rounded-lg py-3 font-bold font-size-18 text-white text-center">{t('select_venue')}</Button>
          </div>
        )}
      </ModalComponent>
    </>
  );
};

export default Accommodation;
