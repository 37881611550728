import { Routes, Route } from "react-router-dom";
import Home from "pages/home";
import PageAbout from "pages/pages";
import Blogs from "pages/blogs";
import BlogSingle from "pages/blogs/id/page";
import About from "pages/about";
import Signin from "pages/signin";
import Step1 from "pages/bookings/step1";
import Step2 from "pages/bookings/step2";
import Step3 from "pages/bookings/step3";
import Step4 from "pages/bookings/step4";
import Step5 from "pages/bookings/step5";
import Step6 from "pages/bookings/step6";
import Checkout from "pages/bookings/checkout";
import NewTicket from "pages/dashboard/support/newTicket";
import Tickets from "pages/dashboard/support/tickets";
import Dashboard from "pages/dashboard";
import DashboardLayout from "pages/dashboard/DashboardLayout/page";
// import Packages from "pages/dashboard/packages/page";
import { GoogleMapsProvider } from "pages/contact/GoogleMapsProvider";
import Profile from "pages/profile";
import ScrollToTop from "hooks/ScrollToTop";
import Faq from "pages/faq";
import DashboardFaq from "pages/dashboard/support/faq";
import { PrivateRoutes } from "./ProtectedRoute";
import DestinationsPage from "pages/destinations";
import FavoritesTourevents from "pages/dashboard/favorites/tourevents";
import FavoritesAccommodations from "pages/dashboard/favorites/accommodations";
import FavoritesDestinations from "pages/dashboard/favorites/destinations";
import FavoritesBookings from "pages/dashboard/favorites/bookings";
import Notifications from "pages/dashboard/notification/notifications";
import Activities from "pages/dashboard/notification/activities";
import ToureventsPage from "pages/tourevents";
import TestimonialsPage from "pages/testimonials";
import Rewards from "pages/dashboard/offers/rewards";
import Points from "pages/dashboard/offers/points";
import Markets from "pages/dashboard/offers/market";
import ToureventSingle from "pages/tourevents/single";
import DestinationsSingle from "pages/destinations/single";
import AccommodationSingle from "pages/accommodation/single";
import LandmarksSingle from "pages/landmarks/id";
import Contact from "pages/contact";
import PastBooking from "pages/dashboard/booking/pastBooking";
import CurrentBooking from "pages/dashboard/booking/currentBooking";
import Invoices from "pages/dashboard/invoices";
import PaymentCallback from "pages/payment";
// import Destinations from "pages/dashboard/destinations/page";
// import Packages from "pages/dashboard/packages/page";
// import NewTicket from "pages/dashboard/support/NewTicket";
// import TicketHistory from "pages/dashboard/support/TicketHistory";

const Routers = () => {

  return (
    <>
      {/* {showSidebar && <Sidebar />} */}

      <ScrollToTop >

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pages/:id" element={<PageAbout />} />
          <Route path="/blogs/" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogSingle />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/booking/step1" element={<Step1 />} />
          <Route path="/booking/step2" element={<Step2 />} />
          <Route path="/booking/step3" element={<Step3 />} />
          <Route path="/booking/step4" element={<Step4 />} />
          <Route path="/booking/step5" element={<Step5 />} />
          <Route path="/booking/step6" element={<Step6 />} />
          <Route path="/booking/*" element={<Step1 />} /> {/* Default to Step1 */}
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="destinations" element={<DestinationsPage />}></Route>
          <Route path="destinations/:id" element={<DestinationsSingle />}></Route>
          <Route path="tourevents" element={<ToureventsPage />}></Route>
          <Route path="tourevents/:id" element={<ToureventSingle />}></Route>
          <Route path="testimonials" element={<TestimonialsPage />}></Route>
          <Route path="accommodation/:id" element={<AccommodationSingle />}></Route>
          <Route path="landmarks/:id" element={<LandmarksSingle />}></Route>


          {/* <Route path="/dashboard/support/new-ticket" element={<NewTicket />} /> 
      <Route path="/dashboard/support/ticket-history" element={<TicketHistory />} /> */}

{/* 
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="*" index element={<Dashboard />}></Route>
              <Route path="packages" index element={<Packages />}></Route>
              <Route path="new-ticket" index element={<NewTicket />}></Route>
              <Route path="ticket-history" element={<TicketHistory />}></Route>
              <Route path="faq" element={<DashboardFaq />}></Route>
              <Route path="favorites/packages" element={<FavoritesPackages />}></Route>
              <Route path="favorites/events" element={<FavoritesEvents />}></Route>
            </Route>
          </Route> */}

        </Routes>
      </ScrollToTop>
      

        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/payment/callback" element={<PaymentCallback />}></Route>
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="*" index element={<Dashboard />}></Route>
              {/* <Route path="packages" index element={<Packages />}></Route> */}
              <Route path="new-ticket" index element={<NewTicket />}></Route>
              <Route path="tickets" element={<Tickets />}></Route>
              <Route path="faq" element={<DashboardFaq />}></Route>
              <Route path="favorites/bookings" element={<FavoritesBookings />}></Route>
              <Route path="favorites/tourevents" element={<FavoritesTourevents />}></Route>
              <Route path="favorites/accommodations" element={<FavoritesAccommodations />}></Route>
              <Route path="favorites/destinations" element={<FavoritesDestinations />}></Route>
              <Route path="notifications" element={<Notifications />}></Route>
              <Route path="activities" element={<Activities />}></Route>
              <Route path="rewards" element={<Rewards />}></Route>
              <Route path="markets" element={<Markets />}></Route>
              <Route path="points" element={<Points />}></Route>
              <Route path="current-booking" element={<CurrentBooking />}></Route>
              <Route path="previous-booking" element={<PastBooking />}></Route>
              <Route path="invoices" element={<Invoices />}></Route>
            </Route>
          </Route>

        </Routes>

      <ScrollToTop >
        <GoogleMapsProvider>
          <Routes>
            <Route path="/contact-us" element={<Contact />} />
          </Routes>
        </GoogleMapsProvider>
      </ScrollToTop>
    </>
  );
};

export default Routers;
