import { Button, notification } from "antd";
import Profile from "components/Shared/Profile";
import { postCreatePayment , postPaymentTabby } from "features/payment/paymentSlice";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import urpayImage from 'public/images/urpay.svg'
import tabbyImage from 'public/images/tabby.svg'
import { useTranslation } from "react-i18next";
import urway from 'public/images/urway.png'
import urwayAr from 'public/images/urway-ar.png'
const Checkout = () => {
  const dispatch = useDispatch();
  const { payments , tabby } = useSelector((state) => state.payment);
  const { t  , i18n} = useTranslation();





  const createPayment = () => {
    const id = localStorage.getItem("bookingCheckout");
    console.log(activePayment)
    if(activePayment === 1){
      dispatch(postPaymentTabby({
        id, callback: (response) => {
          notification.success({
            // message: 'Success!',
            description: response.message,
            duration: 3,
          });
          setTimeout(() => {
            window.location.href = response.data.payment_url
          }, 2000);
  
        }
      }))
    }else if(activePayment === 2){
      dispatch(postCreatePayment({
        id, callback: (response) => {
          notification.success({
            // message: 'Success!',
            description: response.message,
            duration: 3,
          });
          setTimeout(() => {
            window.location.href = response.payment_url
          }, 2000);
  
        }
      }))
    }
  }

  const [activePayment, setActivePayment] = useState(null);

  const handlePaymentClick = (paymentId) => {
    setActivePayment(paymentId);
  };

  if (!localStorage.getItem("bookingCheckout")) {
    return <Navigate to="/booking/step1" />
  }
  return (
    <section className="py-10">
      <div className="container">
        <h2 className="font-bold mb-4">{t('nabat_website_pay_confirm_reservation_process')}</h2>
        <Profile />
        <div className="flex items-center justify-center py-10">
          <div
            className={`border border-primary rounded-xl p-4 h-[130px] flex items-center justify-center mx-2 w-[170px] cursor-pointer ${activePayment === 1 ? 'bg-primary' : ''
              }`}
            onClick={() => handlePaymentClick(1)}
          >
            <img src={tabbyImage} alt="Tabby" />
          </div>
          <div
            className={`border border-primary rounded-xl p-4 h-[130px] flex items-center justify-center mx-2 w-[170px] cursor-pointer ${activePayment === 2 ? 'bg-primary' : ''
              }`}
            onClick={() => handlePaymentClick(2)}
          >
            <img src={i18n.language === "ar" ? urwayAr : urway } alt="Urpay" />
          </div>
        </div>
        <Button loading={payments?.loading || tabby?.loading} onClick={createPayment} disabled={activePayment === null} className="h-auto bg-primary-2 py-3 font-size-20 text-white text-center w-full block">{t('pay_now')}</Button>

      </div>
    </section>

  );
};

export default Checkout;
