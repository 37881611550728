import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  const [fadeClass, setFadeClass] = useState("fade-in");

  useEffect(() => {
    // تطبيق الأنميشن عند التنقل بين الصفحات
    setFadeClass("fade-out");
    const timer = setTimeout(() => {
      window.scrollTo(0, 0); // إعادة السكرول لأعلى
      setFadeClass("fade-in");
    }, 300); // نفس مدة الأنميشن في CSS

    return () => clearTimeout(timer);
  }, [pathname]);

  return <div className={fadeClass}>{children}</div>;
};

export default ScrollToTop;
