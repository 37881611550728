import { Col, Row } from 'antd';
import React from 'react';
import TableBooking from '../commponents/TableBooking';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const PastBooking = () => {
const { t } = useTranslation()
  return (
    <>
    <Helmet>
      <title>{t('nbt')} | {t('my_previous_trips')}</title>
    </Helmet>
    <Row className="mb-12">
      <Col span={24} className="mb-5">
        <div className="flex items-center justify-between">
          <h2 className='flex items-center text-black'> <i className="icon-calendar me-2"></i> {t('my_previous_trips')}</h2>
        </div>
      </Col>
      <Col span={24}>
        <TableBooking trip="previous" />
      </Col>
    </Row>
    </>
  );
};

export default PastBooking;
