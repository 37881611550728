
import { Button, Space, Table } from 'antd';
import { fetchPoints } from 'features/offers/offersSlice';
import useAppParams from 'hooks/useAppParams';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const Points = () => {
  const dispatch = useDispatch();
  const {t , i18n} = useTranslation();
  const { handleSearch, UrlParams } = useAppParams({});
  const [data, setData] = useState();
  const { points } = useSelector((state) => state.offers);

  useEffect(() => {
    if (UrlParams) {
      dispatch(fetchPoints({
        params: {
          ...UrlParams,
          page: Number(UrlParams?.page || 1),
        },
      }))
    }
  }, [UrlParams , i18n.language]);

  useEffect(() => {
    if (points) {
      setData(points?.data?.content || []);
    }
  }, [points]);

  const columns = [
    {
      title: t('process'),
      dataIndex: ['notes'],
      key: 'notes',
    },
    {
      title: t('date'),
      dataIndex: ['created_at'],
      key: 'created_at',
    },
    {
      title: t('points_earned'),
      dataIndex: ['points_earned'],
      key: 'points_earned',
    },
    {
      title: t('total_number_points'),
      dataIndex: ['total_points'],
      key: 'total_points',
    },

    {
      title: t('flight_settings'),
      dataIndex: 'action',
      key: 'action',
      render: (value, record) => {
        return (
          <Space size="small" className='items-center'>
            <Button className="border-0 px-1 bg-transparent">
              <i className='icon-refresh font-size-22'></i>
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('my_points_record')}</title>
      </Helmet>
      <div>
        <h2 className='font-bold flex items-center mb-7'><i className='icon-card-pay me-3'></i> {t('my_points_record')}</h2>
        <Table
          columns={columns}
          loading={points.loading}
          locale={{ emptyText : t('no_data') }}
          dataSource={data}
          pagination={{
            pageSize: 6,
            current: Number(UrlParams?.page ? UrlParams?.page : 1),
            total: points?.data?.pagination?.total,
            onChange: (page) => {
              handleSearch({ fields: { page: page } });
            },
          }}
        />
      </div>
    </>
  )
}

export default Points