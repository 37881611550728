import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axiosApp from 'api';
import { setError } from 'features/error/errorSlice';


export const fetchBookings = createAsyncThunk(
  'data/fetchBookings',
  async ({ params, trip }, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = trip ? `/bookings/${trip}` : '/bookings';
      const response = await axiosApp.get(endpoint, {
        params,
        headers: { perpage: params?.perpage },
      });
      return { type: 'booking', data: response.data };
    } catch (error) {
      const errorMessage =
        error.response?.data?.data ||
        error.response?.data?.message ||
        error?.message;
      dispatch(setError({ message: errorMessage }));
      return rejectWithValue(errorMessage);
    }
  }
);

export const postBooking = createAsyncThunk(
  'data/postBooking',
  async (values , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.post(`/bookings` , values);
      return { type: 'postBooking', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const postWheelies = createAsyncThunk(
  'data/postWheelies',
  async ({values,callback} , { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.post(`/wheelies` , values);
      notification.success({
        message:response.data.message
      });
      callback?.()
      return { type: 'postWheelies', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const postRestaurants = createAsyncThunk(
  'data/postRestaurants',
  async ({values , callback} , { rejectWithValue , dispatch}) => {
    try {
      const response = await axiosApp.post(`/restaurants` , values);
      notification.success({
        message:response.data.message
      })
      callback?.()
      return { type: 'postRestaurants', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const showBooking = createAsyncThunk(
  'data/showBooking',
  async (id , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/bookings/${id}`);
      return { type: 'showBooking', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const postDeleteCancellations = createAsyncThunk(
  'data/postDeleteCancellations',
  async ({values ,  callback} , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.post(`/cancellations` , values);
      callback?.(response.data)
      return { type: 'postDeleteCancellations', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchStatusFlow = createAsyncThunk(
  'data/status-flow',
  async (_ , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/status-flow`);
      return { type: 'status', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const postForms = createAsyncThunk(
  'data/postForms',
  async ({values , callback}, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.post(`/forms` ,values);
      callback?.(response.data)
      return { type: 'forms', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);


export const postModifications = createAsyncThunk(
  'data/postModifications',
  async ({values , callback}, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.post(`/modifications` ,values);
      callback?.(response.data)
      return { type: 'modifications', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const postTestimonialsTating = createAsyncThunk(
  'data/postTestimonialsTating',
  async ({values , callback}, { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.post(`/testimonials` ,values);
      callback?.(response.data)
      return { type: 'testimonials', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);


// Initial State
const initialState = {
  booking:{
    loading: false,
    data: [],
    error: null,
  },
  booking:{
    loading: false,
    data: [],
    error: null,
  },
  status:{
    loading: false,
    data: [],
    error: null,
  },
  deleteCancellations:{
    loading: false,
    error: null,
  },
  postWheelie:{
    loading: false,
    error: null,
  },
  postRestaurant:{
    loading: false,
    error: null,
  },
  postForm:{
    loading: false,
    error: null,
  },
  modifications:{
    loading: false,
    error: null,
  },
  testimonials:{
    loading: false,
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // postBooking
      .addCase(postBooking.pending, (state) => {
        state.booking.loading = true;
      })
      .addCase(postBooking.fulfilled, (state, action) => {
        state.booking.loading = false;
        state.booking.data = action.payload.data.data;
      })
      .addCase(postBooking.rejected, (state, action) => {
        state.booking.loading = false;
        state.booking.error = action.payload;
      })
      // postWheelies
      .addCase(postWheelies.pending, (state) => {
        state.postWheelie.loading = true;
      })
      .addCase(postWheelies.fulfilled, (state, action) => {
        state.postWheelie.loading = false;
      })
      .addCase(postWheelies.rejected, (state, action) => {
        state.postWheelie.loading = false;
        state.postWheelie.error = action.payload;
      })
      // postWheelies
      .addCase(postRestaurants.pending, (state) => {
        state.postRestaurant.loading = true;
      })
      .addCase(postRestaurants.fulfilled, (state, action) => {
        state.postRestaurant.loading = false;
      })
      .addCase(postRestaurants.rejected, (state, action) => {
        state.postRestaurant.loading = false;
        state.postRestaurant.error = action.payload;
      })
      // showBooking
      .addCase(showBooking.pending, (state) => {
        state.booking.loading = true;
      })
      .addCase(showBooking.fulfilled, (state, action) => {
        state.booking.loading = false;
        state.booking.data = action.payload.data.data;
      })
      .addCase(showBooking.rejected, (state, action) => {
        state.booking.loading = false;
        state.booking.error = action.payload;
      })
      // fetchBookings
      .addCase(fetchBookings.pending, (state) => {
        state.booking.loading = true;
      })
      .addCase(fetchBookings.fulfilled, (state, action) => {
        state.booking.loading = false;
        state.booking.data = action.payload.data.data;
      })
      .addCase(fetchBookings.rejected, (state, action) => {
        state.booking.loading = false;
        state.booking.error = action.payload;
      })
      // fetchStatusFlow
      .addCase(fetchStatusFlow.pending, (state) => {
        state.status.loading = true;
      })
      .addCase(fetchStatusFlow.fulfilled, (state, action) => {
        state.status.loading = false;
        state.status.data = action.payload.data.data;
      })
      .addCase(fetchStatusFlow.rejected, (state, action) => {
        state.status.loading = false;
        state.status.error = action.payload;
      })
      // deleteCancellations
      .addCase(postDeleteCancellations.pending, (state) => {
        state.deleteCancellations.loading = true;
      })
      .addCase(postDeleteCancellations.fulfilled, (state, action) => {
        state.deleteCancellations.loading = false;
      })
      .addCase(postDeleteCancellations.rejected, (state, action) => {
        state.deleteCancellations.loading = false;
        state.deleteCancellations.error = action.payload;
      })
      // postForm
      .addCase(postForms.pending, (state) => {
        state.postForm.loading = true;
      })
      .addCase(postForms.fulfilled, (state, action) => {
        state.postForm.loading = false;
      })
      .addCase(postForms.rejected, (state, action) => {
        state.postForm.loading = false;
        state.postForm.error = action.payload;
      })
      // modifications
      .addCase(postModifications.pending, (state) => {
        state.modifications.loading = true;
      })
      .addCase(postModifications.fulfilled, (state, action) => {
        state.modifications.loading = false;
      })
      .addCase(postModifications.rejected, (state, action) => {
        state.modifications.loading = false;
        state.modifications.error = action.payload;
      })
      // modifications
      .addCase(postTestimonialsTating.pending, (state) => {
        state.testimonials.loading = true;
      })
      .addCase(postTestimonialsTating.fulfilled, (state, action) => {
        state.testimonials.loading = false;
      })
      .addCase(postTestimonialsTating.rejected, (state, action) => {
        state.testimonials.loading = false;
        state.testimonials.error = action.payload;
      })
      ;
  },
});

export default dataSlice.reducer;
