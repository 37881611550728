import { createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";

let lastErrorMessage = null;
let hasShownOfflineError = false;
let notificationKey = 0; // مفتاح فريد لكل إشعار

const errorSlice = createSlice({
  name: "error",
  initialState: {},
  reducers: {
    setError: (_, action) => {
      const { message } = action.payload;

      const isOfflineError =
        !navigator.onLine ||
        message.includes("Network Error") ||
        message.includes("Failed to fetch");

      if (isOfflineError) {
        if (hasShownOfflineError) return;
        hasShownOfflineError = true;
      } else {
        hasShownOfflineError = false;
      }

      // زيادة المفتاح الفريد في كل مرة
      notificationKey++;

      notification.error({
        key: notificationKey, // استخدام المفتاح الفريد
        message: "خطأ!",
        description: message,
        placement: "topRight",
        duration: 3,
      });

      lastErrorMessage = message;
    },
  },
});

export const { setError } = errorSlice.actions;
export default errorSlice.reducer;