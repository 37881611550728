import { Avatar, Badge, Button, Col, notification, Pagination, Row, Tabs } from 'antd'
import EmptyData from 'components/Shared/EmptyData'
import { fetchActivities, postMarkAllAsReadActivity, postMarkAsReadActivity, toggleActivities, toggleReadStatusActivity } from 'features/notifications/notificationsSlice'
import useAppParams from 'hooks/useAppParams'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
const { TabPane } = Tabs;
const Activities = () => {
  const dispatch = useDispatch();
  const {t , i18n} = useTranslation();
  const { activities , markAllAsReadActivity } = useSelector((state) => state.notifications);
  const { handleSearch, UrlParams } = useAppParams({});
  const [loadingIds, setLoadingIds] = useState({});
  const [activeTab, setActiveTab] = useState("1");

  const getTodayAndTomorrow = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1); 

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    return {
      today: formatDate(today),
      tomorrow: formatDate(tomorrow),
    };
  };
  const { today, tomorrow } = getTodayAndTomorrow();
  

  useEffect(() => {
    // تأكد من أن الريكوست يتم ضربه عند تحميل الصفحة لأول مرة
    dispatch(fetchActivities({
      params: {
        date_first: today,
        date_second: today,
        page: 1, // الصفحة الافتراضية
      }
    }));
  }, []); // يعمل مرة واحدة عند تحميل الصفحة
  
  useEffect(() => {
    if (UrlParams) {
      dispatch(fetchActivities({
        params: {
          date_first: activeTab === "1" ? today : activeTab === "2" ? tomorrow : '',
          date_second: activeTab === "1" ? today : activeTab === "2" ? tomorrow : '',
          ...UrlParams,
          page: Number(UrlParams?.page || 1),
        }
      }));
    }
  }, [activeTab, UrlParams?.page, i18n.language]); // يعمل عند تغيير التاب أو الصفحة
  
  const handleTabChange = (key) => {
    if (key !== activeTab) { // تجنب إعادة التحميل لنفس التاب
      setActiveTab(key);
      handleSearch({
        fields: { page: 1 }, // إعادة ضبط الصفحة عند تغيير التاب
      });
    }
  };

  
  const handelMarkAsRead = async ({ id }) => {
    setLoadingIds((prev) => ({ ...prev, [id]: true }));
    try {
      const resultAction = await dispatch(postMarkAsReadActivity(id));
  
      if (postMarkAsReadActivity.fulfilled.match(resultAction)) {
        const { type } = resultAction.payload;
        if (type === 'markAsRead') {
            dispatch(toggleActivities({ activitieId: id }));
        }
      } else if (postMarkAsReadActivity.rejected.match(resultAction)) {
        notification.error({
          description: resultAction.payload || t('an_error_occurred_while_updating_notification_status'),
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        description: error?.message || t('an_unexpected_error_occurred'),
        duration: 3,
      });
    } finally {
      setLoadingIds((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handelMarkAllAsRead = async () => {
    try {
      const resultAction = await dispatch(postMarkAllAsReadActivity());
  
      if (postMarkAllAsReadActivity.fulfilled.match(resultAction)) {
        if (activities?.data) {
          const updatedActivities = activities.data.map((notif) => ({
            ...notif,
            read_at: new Date().toISOString(),
          }));
          dispatch(toggleReadStatusActivity({ type: 'allActivities', updatedActivities }));
          dispatch(fetchActivities({
            params: { date_first: today, date_second: today }
          }));
        }
      } else {
        notification.error({
          description: resultAction.payload || 'فشل في تعيين كل الإشعارات كمقروءة.',
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        description: error?.message || 'حدث خطأ غير متوقع أثناء تعيين جميع الإشعارات كمقروءة.',
        duration: 3,
      });
    }
  };

  const pageinate = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('activities')}</title>
      </Helmet>
      <Row className='mb-6'>
        <Col span={24}>
          <h2 className='font-bold font-size-30 text-center'>{t('notification_center')}</h2>
        </Col>
      </Row>
      <Row className='mb-6'>
        <Col span={24} lg={8}>
        </Col>
        <Col span={24} lg={8}>
          <h2 className='font-size-30 text-center'>{t('activities')}</h2>
        </Col>
        {activities?.data?.length > 0 ?
        <Col span={24} lg={8} className='text-center lg:text-end'>
          <Button loading={markAllAsReadActivity?.loading} onClick={handelMarkAllAsRead}>{t('mark_all_read')}  <Badge color="#B57134" count={activities.data.unread_activitys_count} showZero />
          </Button>
        </Col> : null}
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="1" centered onChange={handleTabChange}>
            <TabPane tab={t('today_activities')} key="1" >
              {activities.loading ? (
                <>
                  <Skeleton height={20} className="mb-3" style={{ width: "30%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "60%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "90%" }} />
                </>
              ) : activities?.data?.length > 0 ? (
                <>
                  {activities?.data?.map((activitie) => (
                    <div
                      key={activitie.id}
                      className={`px-[5px] py-5 border-b border-[#959494] justify-between items-center flex`}
                      style={{
                        backgroundColor: activitie.read_at === null ? '#FFF2E7' : '#FFF', // لون الخلفية
                      }}
                    >
                      <div className="grow shrink basis-0 items-center gap-[19px] flex">
                        <div className="px-0.5 py-1 flex-col justify-start items-start inline-flex">
                          <Avatar shape='circle' size={62} src={activitie?.image} />
                        </div>
                        <div className="flex-col justify-start gap-[19px] inline-flex">
                          <div className="self-stretch flex-col justify-center gap-[5px] flex">
                            <div className="text-black text-sm font-bold font-['Greta Arabic']">
                              {activitie?.title}
                            </div>
                            <div className="text-[#757a81] text-sm font-normal font-['Greta Arabic']">
                              {activitie?.description}
                            </div>
                          </div>
                          <h6 className="font-medium font-size-12 text-[#A5ACB8]">{activitie?.start_time} | {activitie?.end_time} - {activitie?.date}</h6>
                        </div>
                      </div>
                      <div className="p-2.5 justify-start items-center gap-[18.29px] flex">
                        <Button className='p-0 h-auto border-0 bg-transparent font-size-20'
                          loading={loadingIds[activitie?.id]}
                          onClick={() => handelMarkAsRead({ id: activitie.id, types: "all" })}
                        >
                          {activitie.read_at === null ? <i className='icon-email-unread'></i> : <span class="icon-message-open"><span class="path1"></span><span class="path2"></span></span>}
                        </Button>
                      </div>
                    </div>
                  ))}
                  <Pagination
                    align="end"
                    className="my-3"
                    onChange={pageinate}
                    defaultCurrent={1}
                    current={UrlParams?.page ? Number(UrlParams?.page) : 1}
                    total={activities?.pagination?.total}
                    pageSize={5}
                  />
                </>
              ) : (
                <EmptyData />
              )}
            </TabPane>
            <TabPane tab={t('tomorrow_activities')} key="2">
            {activities.loading ? (
                <>
                  <Skeleton height={20} className="mb-3" style={{ width: "30%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "60%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "90%" }} />
                </>
              ) : activities?.data?.length > 0 ? (
                <>
                  {activities?.data?.map((activitie) => (
                    <div
                      key={activitie.id}
                      className={`px-[5px] py-5 border-b border-[#959494] justify-between items-center flex`}
                      style={{
                        backgroundColor: activitie.read_at === null ? '#FFF2E7' : '#FFF', // لون الخلفية
                      }}
                    >
                      <div className="grow shrink basis-0 items-center gap-[19px] flex">
                        <div className="px-0.5 py-1 flex-col justify-start items-start inline-flex">
                          <Avatar shape='circle' size={62} src={activitie?.image} />
                        </div>
                        <div className="flex-col justify-start gap-[19px] inline-flex">
                          <div className="self-stretch flex-col justify-center gap-[5px] flex">
                            <div className="text-black text-sm font-bold font-['Greta Arabic']">
                              {activitie?.title}
                            </div>
                            <div className="text-[#757a81] text-sm font-normal font-['Greta Arabic']">
                              {activitie?.description}
                            </div>
                          </div>
                          <h6 className="font-medium font-size-12 text-[#A5ACB8]">{activitie?.start_time} | {activitie?.end_time} - {activitie?.date}</h6>
                        </div>
                      </div>
                      <div className="p-2.5 justify-start items-center gap-[18.29px] flex">
                        <Button className='p-0 h-auto border-0 bg-transparent font-size-20'
                          loading={loadingIds[activitie?.id]}
                          onClick={() => handelMarkAsRead({id:activitie.id , types: "seen"})}
                        >
                          {activitie.read_at === null ? <i className='icon-email-unread'></i> : <span class="icon-message-open"><span class="path1"></span><span class="path2"></span></span>}
                        </Button>
                      </div>
                    </div>
                  ))}
                  <Pagination
                    align="end"
                    className="my-3"
                    onChange={pageinate}
                    defaultCurrent={1}
                    current={UrlParams?.page ? Number(UrlParams?.page) : 1}
                    total={activities?.pagination?.total}
                    pageSize={5}
                  />
                </>
              ) : (
                <EmptyData />
              )}
            </TabPane>
            <TabPane tab={t('all_activities')} key="3">
            {activities.loading ? (
                <>
                  <Skeleton height={20} className="mb-3" style={{ width: "30%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "60%" }} />
                  <Skeleton height={20} className="mb-3" style={{ width: "90%" }} />
                </>
              ) : activities?.data?.length > 0 ? (
                <>
                  {activities?.data?.map((activitie) => (
                    <div
                      key={activitie.id}
                      className={`px-[5px] py-5 border-b border-[#959494] justify-between items-center flex`}
                      style={{
                        backgroundColor: activitie.read_at === null ? '#FFF2E7' : '#FFF', // لون الخلفية
                      }}
                    >
                      <div className="grow shrink basis-0 items-center gap-[19px] flex">
                        <div className="px-0.5 py-1 flex-col justify-start items-start inline-flex">
                          <Avatar shape='circle' size={62} src={activitie?.image} />
                        </div>
                        <div className="flex-col justify-start gap-[19px] inline-flex">
                          <div className="self-stretch flex-col justify-center gap-[5px] flex">
                            <div className="text-black text-sm font-bold font-['Greta Arabic']">
                              {activitie?.title}
                            </div>
                            <div className="text-[#757a81] text-sm font-normal font-['Greta Arabic']">
                              {activitie?.description}
                            </div>
                          </div>
                          <h6 className="font-medium font-size-12 text-[#A5ACB8]">{activitie?.start_time} | {activitie?.end_time} - {activitie?.date}</h6>
                        </div>
                      </div>
                      <div className="p-2.5 justify-start items-center gap-[18.29px] flex">
                        <Button className='p-0 h-auto border-0 bg-transparent font-size-20'
                          loading={loadingIds[activitie?.id]}
                          onClick={() => handelMarkAsRead({id:activitie.id , types: "seen"})}
                        >
                          {activitie.read_at === null ? <i className='icon-email-unread'></i> : <span class="icon-message-open"><span class="path1"></span><span class="path2"></span></span>}
                        </Button>
                      </div>
                    </div>
                  ))}
                  <Pagination
                    align="end"
                    className="my-3"
                    onChange={pageinate}
                    defaultCurrent={1}
                    current={UrlParams?.page ? Number(UrlParams?.page) : 1}
                    total={activities?.pagination?.total}
                    pageSize={5}
                  />
                </>
              ) : (
                <EmptyData />
              )}
            </TabPane>
          </Tabs>
        </Col>
      </Row>

    </>
  )
}

export default Activities