import { Breadcrumb, Col, Row } from 'antd';
import SliderThumb from 'components/Shared/SliderThumb';
import TestimonialSingle from 'components/Shared/TestimonialSingle';
import TitlePage from 'components/Shared/TitlePage';
import { fetchToureventsSingle } from 'features/home/homeSlice';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

const ToureventSingle = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const { loading: toureventsSingleLoading, data: toureventsSingle } = useSelector((state) => state.data.toureventsSingle);

  useEffect(() => {
    dispatch(fetchToureventsSingle(id));
  }, [])


  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('events')}</title>
      </Helmet>
      <TitlePage title={t('events')} bg={true} />
      <section className='py-14'>
        <div className='container'>
          <Breadcrumb className='font-size-20 mb-6'>
            <Breadcrumb.Item>
              <Link to="/">
                <i className='icon-home me-2'></i>
                {t('home')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/tourevents">
                {t('events')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{toureventsSingle?.name}</Breadcrumb.Item>
          </Breadcrumb>
          {toureventsSingleLoading ? <>
            <Skeleton height={30} className='mb-4' style={{width:"50%"}} />
            <Skeleton height={30} style={{width:"100%"}} />
          </> : 
          <>
          <Row align="middle" className='mb-8'>
            <Col span={24} lg={12} className="mb-5 lg:mb-0" dir="rtl">
              <SliderThumb images={toureventsSingle?.images?.content} />
            </Col>
            <Col span={24} lg={12} className="lg:ps-6">
              <div className="p-5 text-center">
                <div className="flex items-center mb-4 lg:mb-9 justify-center">
                  <h2 className="w-[140px]">{t('event_name')}</h2>
                  <h2 className="font-bold text-primary">{toureventsSingle?.name}</h2>
                </div>
                <div className="flex items-center mb-4 lg:mb-9 justify-center">
                  <h4 className="w-[140px]">{t('event_venue')}</h4>
                  <h4 className="font-bold text-black">{toureventsSingle?.position}</h4>
                </div>
                <div className="flex items-center mb-4 justify-center">
                  <h4 className="w-[140px]">{t('event_time')}</h4>
                  <h4 className="font-bold text-black">{toureventsSingle?.at}</h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row align="middle" className="mb-5">
            <Col span={24}>
              <div style={{ minHeight: "100px" }}>
                <div dangerouslySetInnerHTML={{ __html: toureventsSingle?.description }} />
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <h2 className="text-primary mb-4 font-bold">{t('reviews')}</h2>
              <TestimonialSingle testimonial={toureventsSingle?.testimonials?.content} limit={true} />
            </Col>
          </Row>
          </>
          }
          
        </div>
      </section>
    </>

  )
}

export default ToureventSingle