import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './home/homeSlice';
import blogReducer from './blog/blogSlice';
import dataPages from './pages/dataPagesSlice';
import aboutReducer from './about/aboutSlice';
import contactReducer from './contact/contactSlice';
import signinReducer from './signin/signinSlice';
import profileReducer from './profile/profileSlice';
import settingsReducer from './settings/settingsSlice';
import categoriesReducer from './categories/categoriesSlice';
import accommodationsReducer from './accommodations/accommodationsSlice';
import eventsReducer from './events/eventsSlice';
import bookingReducer from './booking/bookingSlice';
import faqReducer from './faq/faqSlice';
import favoritesReducer from './favorites/favoritesSlice';
import notificationsReducer from './notifications/notificationsSlice';
import offersReducer from './offers/offersSlice';
import supportReducer from './support/supportSlice';
import invoicesReducer from './invoices/invoicesSlice';
import errorReducer from "./error/errorSlice";
import paymentReducer from "./payment/paymentSlice";



const store = configureStore({
  reducer: {
    data: dataReducer,
    blogs: blogReducer,
    dataPages: dataPages,
    about: aboutReducer,
    contact: contactReducer,
    user: signinReducer,
    profile: profileReducer,
    settings: settingsReducer,
    categories: categoriesReducer,
    accommodations: accommodationsReducer,
    events: eventsReducer,
    booking: bookingReducer,
    faq: faqReducer,
    favorites: favoritesReducer,
    notifications: notificationsReducer,
    offers: offersReducer,
    support: supportReducer,
    invoices: invoicesReducer,
    error: errorReducer,
    payment: paymentReducer,
  },
});

export default store;