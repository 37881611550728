import { notification } from 'antd';
import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/error/errorSlice';


export const fetchContact = createAsyncThunk(
  'data/fetchContact',
  async (_ , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/contact_us`);
      return { type: 'contact', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const postInboxes = createAsyncThunk(
  'data/postInboxes',
  async ({values , callback} , { rejectWithValue ,dispatch}) => {
    try {
      const response = await axiosApp.post(`/inboxes` , values);
      notification.success({
        message:response?.data?.message,
        duration:3
      });
      callback?.()
      return { type: 'postInboxes', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial State
const initialState = {
  contact:{
    loading: false,
    data: [],
    error: null,
  },
  inboxes:{
    loading: false,
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // blogs
      .addCase(fetchContact.pending, (state) => {
        state.contact.loading = true;
      })
      .addCase(fetchContact.fulfilled, (state, action) => {
        state.contact.loading = false;
        state.contact.data = action.payload.data.data;
      })
      .addCase(fetchContact.rejected, (state, action) => {
        state.contact.loading = false;
        state.contact.error = action.payload;
      })
    builder
      // postInboxes
      .addCase(postInboxes.pending, (state) => {
        state.inboxes.loading = true;
      })
      .addCase(postInboxes.fulfilled, (state, action) => {
        state.inboxes.loading = false;
      })
      .addCase(postInboxes.rejected, (state, action) => {
        state.inboxes.loading = false;
        state.inboxes.error = action.payload;
      })
      ;
  },
});

export default dataSlice.reducer;
