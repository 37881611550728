import { ConfigProvider } from 'antd';
import Layout from 'components/Layouts/Layout/index.jsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function App() {
  const {i18n} = useTranslation()
 
  const currentLanguage = i18n.language; 

  const direction = currentLanguage === 'ar' ? 'rtl' : 'ltr';
  const fontFamily = currentLanguage === 'ar' ? 'greta' : 'sans-serif'; 

  useEffect(() => {
    if (currentLanguage === 'ar') {
      document.documentElement.setAttribute('dir', 'rtl');
      document.body.classList.add('ar');
      document.body.classList.remove('en');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
      document.body.classList.add('en');
      document.body.classList.remove('ar');
    }
  }, [currentLanguage]);


  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/679623393a8427326074fb69/1iih7j70m';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // تنظيف السكربت عند إزالة المكون
    };
  }, []);
  
  return (
    <div className="App">
      <ConfigProvider
      direction={direction}
        theme={{
          token: {
            colorPrimary: "#B57134",
            fontFamily: {fontFamily},
          },
        }}
      >
      <Layout />
      </ConfigProvider>
    </div>
  );
}

export default App;
