import { Col, Row, Flex, Image, Table, Space, Button } from 'antd';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import iconTicketQuestion from "public/images/ticket-question.svg"
import iconCamal from "public/images/camal.svg"
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAppParams from 'hooks/useAppParams';
import { fetchTickets } from 'features/support/supportSlice';
function Tickets() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSearch, UrlParams } = useAppParams({});
  const [data, setData] = useState();
  const { tickets } = useSelector((state) => state.support);

  useEffect(() => {
    if(UrlParams){
      dispatch(fetchTickets({
        params: {
          ...UrlParams,
          page: Number(UrlParams?.page || 1),
        },
    }))
    }
  }, [UrlParams]);

    useEffect(() => {
      if (tickets) {
        setData(tickets?.data?.content || []);
      }
    }, [tickets]);

  const columns = [
    {
      title: t('ticket_number'),
      dataIndex: ['ticket_id'],
      key: 'ticket_id',
    },
    {
      title: t('problem_type'),
      dataIndex: ['category','name'],
      key: 'category.name',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className='px-2 inline-block py-1 font-size-12 font-bold rounded-full' style={{ backgroundColor: status?.status[0]?.color, color: "#FFF" }}>
          {status?.status[0]?.value}
        </span>
      ),
    },
    {
      title: t('ticket_attachments'),
      dataIndex: ['category', 'image'], 
      key: 'category.image',
      render: (image) => (
        <Image
          src={image} 
          alt={t('ticket_attachments')}
          width={50} 
          height={50} 
          preview={false} 
          style={{ objectFit: 'cover', borderRadius: '5px' }} 
        />
      ),
    },
    // {
    //   title: t('actions'),
    //   dataIndex: 'action',
    //   key: 'action',
    //   render: (value, record) => {
    //     return (
    //       <Space size="small" className='items-center'>
    //         <Button className="border-0 px-1 bg-transparent">
    //           <i className='icon-eye font-size-22'></i>
    //         </Button>
    //         <Button className="border-0 px-1 bg-transparent">
    //           <i className='icon-refresh font-size-22'></i>
    //         </Button>
    //       </Space>
    //     );
    //   },
    // },
  ];
  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('tickets_history')}</title>
      </Helmet>
      <div>
        <Row>
          <Col span={24}>
            <Flex className='mb-7' align='center'>
              <Image src={iconCamal} alt='icon' />
              <h4 className='ms-4'>{t('technical_support_description')}.</h4>
            </Flex>
            <Flex className='mb-5' align='center'>
              <Image src={iconTicketQuestion} alt='icon' />
              <h4 className='ms-4'>{t('fault_tracking_resolution_system')}</h4>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              loading={tickets.loading}
              locale={{ emptyText : t('no_data') }}
              dataSource={data}
              pagination={{
                pageSize: 6,
                current: Number(UrlParams?.page ? UrlParams?.page : 1),
                total: tickets?.data?.pagination?.total,
                onChange: (page) => {
                  handleSearch({ fields: { page: page } });
                },
              }}
            />
          </Col>
        </Row>
      </div>
    </>

  );
};
export default Tickets;