import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next'
import wifiIcon from 'public/images/wifi.svg'
import room from 'public/images/room.svg'
import coffee from 'public/images/coffee.svg'
import food from 'public/images/fluent_food.svg'
const DetailsAccommodation = ({wifi , rooms,  restaurants , cafes , type , rating}) => {
  const {t} = useTranslation();
  return (
    <div>
      <div className="flex items-center mb-5 lg:mb-7">
        <Image src={wifiIcon} alt="wifi" />
        <h5 className="ms-4">{Number(wifi) === 0 ? t('wifi_not_free_everywhere') : t('wifi_free_everywhere')}</h5>
      </div>
      <div className="flex items-center mb-5 lg:mb-7">
        <Image src={room} alt="room" />
        <h5 className="ms-3 w-[130px]">{t('number_rooms')}   </h5>
        <div className="flex items-center justify-between">
          <h4 className="me-3">{rooms}</h4>
          <h5>{t('rooms')} </h5>
        </div>
      </div>
      <div className="flex items-center mb-5 lg:mb-7">
        <Image src={food} alt="fluent_food" />
        <h5 className="ms-3 w-[130px]">{t('number_restaurants')}   </h5>
        <div className="flex items-center justify-between">
          <h4 className="me-3">{restaurants}</h4>
          <h5>{t('restaurants')} </h5>
        </div>
      </div>
      <div className="flex items-center mb-5 lg:mb-7">
        <Image src={coffee} alt="coffee" />
        <h5 className="ms-3 w-[130px]">{t('number_cafes')}   </h5>
        <div className="flex items-center justify-between">
          <h4 className="me-3">{cafes}</h4>
          <h5>{t('cafes')} </h5>
        </div>
      </div>
      <div className="flex items-center mb-5 lg:mb-7">
        <h5>{t('category')}   </h5>
        <h4>
          {type === 'HOTEL' ? t('hotel') :
            type === 'CARAVAN' ? t('caravan') :
              type === 'CHALET' ? t('chalet') :
                type === 'CAMP' ? t('camp') :
                  type === 'RESORT' ? t('resort') : null}
        </h4>
      </div>
      <div className="flex items-center mb-5 lg:mb-7">
        <h5>{t('rating')}   </h5>
        <h4>{rating} <FontAwesomeIcon icon={faStar} className="text-primary" /> </h4>
      </div>
    </div>
  )
}

export default DetailsAccommodation