import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEnvelope, faPhone, faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "./index.module.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Dropdown, Modal, notification, Space } from "antd";
import logo from 'public/images/logo.svg'
import list from 'public/images/list.svg'
import userSetting from 'public/images/user-setting.svg'
import userLogout from 'public/images/logout.svg'
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axiosApp from "api";
import SocialMedia from "components/Shared/SocialMedia";
import { fetchActivitiesHeader, fetchNotificationsHeader } from "features/notifications/notificationsSlice";
import { fetchSettings } from "features/settings/settingsSlice";
import { logout, logoutAll } from "features/signin/signinSlice";

const Header = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { settings } = useSelector((state) => state.settings);
  const [filteredDataEmail, setFilteredDataEmail] = useState([]);
  const [filteredDataPhone, setFilteredDataPhone] = useState([]);
  const { profilePicture } = useSelector((state) => state.profile);
  const { notificationsHeader, activitiesHeader } = useSelector((state) => state.notifications);
  const location = useLocation();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [imageAvatar, setImageAvatar] = useState(null);
  const { user  } = useSelector((state) => state.user); 

  const avatarUrl =  profilePicture?.data?.image || JSON.parse(localStorage.getItem("user"))?.image ;

  // useEffect(() => {
  //   setImageAvatar(profilePicture?.data?.image)
  // }, [profilePicture])


  // useEffect(() => {
  //   const user = localStorage.getItem("user")
  //   // const user = ;
  //   if (user?.image) {
  //     setImageAvatar(JSON.parse(user.image)); // تعيين الصورة من localStorage إذا كانت موجودة
  //   }
  // }, []);

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  useEffect(() => {
    const fetchData = async () => {
        try {
            dispatch(fetchSettings());
            if (user && user.data && user.data.data && user.data.data.user || localStorage.getItem("user")) {
                dispatch(fetchNotificationsHeader({ params: '' }));
                dispatch(fetchActivitiesHeader({ params: '' }));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, [user, dispatch]);
  
  

  useEffect(() => {

    if (settings && settings?.data?.content?.length > 0) {
      const result = settings?.data?.content?.filter(item => item.id === 85);
      setFilteredDataEmail(result);
    }

    if (settings && settings?.data?.content?.length > 0) {
      const result = settings?.data?.content?.filter(item => item.id === 18 && item.value);
      setFilteredDataPhone(result);
    }
  }, [settings]);


  useEffect(() => {
    localStorage.setItem('language', i18n.language);

    axiosApp.interceptors.request.use(
      (config) => {
        const language = i18n.language || 'ar';

        config.headers['X-Localization'] = language;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [i18n.language]);


  const items = [
    {
      label: (
        <Link onClick={() => switchLanguage('ar')} className="flex items-center"> <span className="icon-ar-flag me-1 font-size-18"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>  العربية</Link>
      ),
      key: '0',
    },
    {
      label: (
        <Link onClick={() => switchLanguage('en')} className="flex items-center"> <span className="icon-en-flag me-1 font-size-18"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span> English</Link>
      ),
      key: '1',
    }
  ];


  const headerRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);


  const confirmLogout = () => {
    Modal.confirm({
      title: t('do_want_logout'),
      okText: t('ok'),
      cancelText: t('cancel'),
      onOk: () =>
        new Promise((resolve) => {
          dispatch(
            logout({
              callback: (message) => {
                notification.success({
                  duration: 3,
                  description: message
                })
                navigate('/')
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                resolve();
              },
            })
          );
        })

    });
  };

  const confirmLogoutAll = () => {
    Modal.confirm({
      title: t('do_want_logoutAll'),
      okText: t('ok'),
      cancelText: t('cancel'),
      onOk: () =>
        new Promise((resolve) => {
          dispatch(
            logoutAll({
              callback: (message) => {
                notification.success({
                  duration: 3,
                  description: message
                })
                navigate('/')
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                resolve();
              },
            })
          );
        })

    });
  };

  const userMenu = (
    <div className="bg-white p-4 rounded-ee-xl border border-black" onClick={() => setIsOpen(!isOpen)}>
      <div className="flex items-center px-2 mb-3">
        <img src={list} alt="list" />
        <h6 className="ms-2">
          <Link to="/dashboard">{t('myDashboard')}</Link>
        </h6>
      </div>
      <div className="flex items-center px-2 mb-3">
        <img src={userSetting} alt="userSetting" />
        <h6 className="ms-2">
          <Link to="/profile">{t('profile')}</Link>
        </h6>
      </div>
      <div className="flex items-center px-2 mb-3">
        <img src={userLogout} alt="logout" />
        <h6 className="ms-2">
          <Link onClick={() => confirmLogout()}>{t('logout')}</Link>
        </h6>
      </div>
      <div className="flex items-center px-2">
        <img src={userLogout} alt="logout" />
        <h6 className="ms-2">
          <Link onClick={() => confirmLogoutAll()}>{t('logoutAll')}</Link>
        </h6>
      </div>
    </div>
  );

  const notificationsMenu = (
    <div onClick={() => setIsOpen(!isOpen)} className="px-[18px] min-w-72 py-2.5 bg-white rounded-bl-lg rounded-br-lg border border-[#462009]">
      <div className="self-stretch px-2.5 py-[5px] bg-white justify-center items-center gap-2.5 inline-flex">
        <div className="text-center text-[#462009] text-base font-bold">{t('notifications')}</div>
      </div>
      <div className="flex-col gap-[5px] flex">
        {notificationsHeader?.data?.content?.length > 0 ?
          notificationsHeader?.data?.content?.map((item) => (
            <>
              <div className="px-0.5 py-2.5 bg-[#fff2e7] rounded-lg flex-col justify-center flex">
                <div className="self-stretch items-center inline-flex">
                  <div className="px-[5px] justify-start items-center gap-2.5 flex">
                    <img className="w-[30px] h-[30px] rounded-full" src={item?.image} />
                  </div>
                  <div className="items-center gap-[5px] flex">
                    <div className="h-[30px] p-[5px] justify-end items-center gap-2.5 flex">
                      <div className="text-right text-black text-sm font-normal">{item?.title}</div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch pr-[50px] justify-center items-center gap-2.5 inline-flex">
                  <div className="grow shrink basis-0 text-right"><span class="text-[#757a81] text-[10px] font-normal">{t('since')} </span><span class="text-[#757a81] text-[10px] font-normal font-['Open Sans']">5</span><span class="text-[#757a81] text-[10px] font-normal"> دقائق</span></div>
                </div>
              </div>
            </>
          ))
          : t('no_data')}
        <div className="self-stretch px-2.5 py-[5px] bg-white justify-center items-center gap-2.5 inline-flex">
          <Link to="/dashboard/notifications" className="text-center text-[#462009] text-base font-normal">{t('view_all_notifications')}</Link>
        </div>
      </div>

    </div>
  );

  const tableMenu = (
    <div onClick={() => setIsOpen(!isOpen)} className="px-[18px] py-2.5 bg-white rounded-bl-lg rounded-br-lg border border-[#462009] flex-col gap-1.5 inline-flex w-72">
      <div className="self-stretch bg-white items-center inline-flex">
        <div className="text-center text-[#462009] text-base font-bold">{t('activities')}</div>
      </div>
      {activitiesHeader?.data?.content?.length > 0 ?
        (
          activitiesHeader?.data?.content?.slice(0, 3).map((item) => (
            <div className="px-[5px] flex-col justify-center items-end gap-[15px] flex">
              <div className="self-stretch px-4 py-[7px] bg-[#fff2e7] rounded-lg flex-col justify-center items-center gap-px flex">
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="w-[30.91px] self-stretch flex-col justify-start items-center gap-[5px] inline-flex">
                    <img src={item?.image} alt="" className="w-[30.91px] h-[30.91px]" />
                  </div>
                  <div className="grow shrink basis-0 ms-3 items-center gap-2.5 flex">
                    <div className="justify-center items-start gap-2.5 flex">
                      <div className="text-right text-black text-sm font-normal">{item?.title}</div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch pr-[34px] justify-center items-center gap-2.5 inline-flex">
                  <div className="grow shrink basis-0 text-right"><span class="text-[#757a81] text-xs font-normal">{t('since')} </span><span class="text-[#757a81] text-xs font-normal font-['Open Sans']">{item?.date}</span></div>
                </div>
              </div>
            </div>
          ))
        )
        : t('no_data')}

      <div className="self-stretch px-2.5 py-[5px] bg-white justify-center items-center gap-2.5 inline-flex">
        <Link to="/dashboard/activities" className="text-center text-[#462009] text-base font-normal">{t('view_all_tasks')}</Link>
      </div>
    </div>
  );

  const goPageBooking=()=>{
    localStorage.removeItem("bookingFormData")
    localStorage.removeItem("bookingCheckout")
    navigate("booking/step1")
  }

  return (
    <>
      <div className={`${styles.topInfo} bg-primary py-2 lg:py-3`}>
        <div className="container mx-auto">
          <div className="lg:flex justify-between items-center">
            <ul className={`${styles.infoContact} flex items-center text-white justify-center lg:justify-start mb-2 lg:mb-0`}>
              {filteredDataEmail.length > 0 ? (
                <>
                  {filteredDataEmail.map(item => (
                    <li key={item.id}>
                      <a
                        className="flex items-center"
                        href={`mailto:${item.value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.value}
                        <FontAwesomeIcon className="ms-2" icon={faEnvelope} />
                      </a>
                    </li>
                  ))}
                </>
              ) : (
                null
              )}

              {filteredDataPhone.length > 0 ? (
                <>
                  {filteredDataPhone.map(item => (
                    <li key={item.id}>
                      <a
                        className="flex items-center"
                        href={`tel:${item.value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.value}
                        <FontAwesomeIcon className="ms-2" icon={faPhone} />
                      </a>
                    </li>
                  ))}
                </>
              ) : (
                null
              )}
            </ul>
            <SocialMedia />
            {/* <ul className={`${styles.socialMedia} flex items-center text-white justify-center lg:justify-start`}>
            {filteredDataSocial.map(item => (
              item.key !== null ? ( // إذا كانت قيمة item.key ليست null، يتم عرض العنصر
                <li key={item.id}>
                  <Link href={item.value} target="_blank">
                    {item.key === "instagram" ? <FontAwesomeIcon icon={faInstagramSquare} /> :
                      item.key === "twitter" ? <FontAwesomeIcon icon={faTwitter} /> :
                        item.key === "tikTok" ? <FontAwesomeIcon icon={faTiktok} /> :
                          item.key === "linkedIn" ? <FontAwesomeIcon icon={faLinkedinIn} /> :
                            item.key === "snapchat" ? <FontAwesomeIcon icon={faSnapchatSquare} /> :
                              item.key === "youTube" ? <FontAwesomeIcon icon={faYoutube} /> : null}
                  </Link>
                </li>
              ) : null 
            ))}
          </ul> */}
          </div>
        </div>
      </div>
      <header className={` ${styles.mainHeader} ${location.pathname === "/" ? styles.internal && isScrolled ? styles.scrolled : "" : null} z-1 z-10`} ref={headerRef}>
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <div className={`${styles.logo}`}>
              <Link to="/">
              <img src={logo} preview={false} alt="logo" />
              </Link>
            </div>
            <div className={`${styles.menu} ${isOpen ? "translate-x-0" : " items-center translate-x-full lg:-translate-x-0"} ${window.innerWidth > 992 ? 'col md:flex' : ''}  transition-all fixed lg:relative bg-white lg:bg-transparent top-0 left-0 flex-1 w-full lg:h-auto h-screen z-20 p-4 lg:p-0`}>
              <div className="text-end lg:hidden">
                <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer inline-block p-2"><FontAwesomeIcon className="font-size-22" icon={faTimes} /></div>
              </div>
              <nav className="lg:flex lg:items-center flex-1 justify-center">
                <ul onClick={() => setIsOpen(!isOpen)} className="lg:flex items-center">
                  <li className="pb-3 lg:pb-0">
                    <NavLink
                      to="/"

                    >
                      {t('home')}
                    </NavLink>
                  </li>
                  <li className="pb-3 lg:pb-0">
                    <NavLink
                      to="/about-us"
                    >
                      {t('about_us')}
                    </NavLink>
                  </li>
                  <li className="pb-3 lg:pb-0">
                    <NavLink to="/destinations">{t('destinations')}</NavLink>
                  </li>
                  <li className="pb-3 lg:pb-0">
                    <NavLink to="/booking/step1" onClick={goPageBooking}>{t('book_now')}</NavLink>
                  </li>
                  <li className="pb-3 lg:pb-0">
                    <NavLink to="/testimonials">{t('customer_reviews')}</NavLink>
                  </li>
                  <li className="pb-3 lg:pb-0">
                    <NavLink to="/tourevents">{t('events_our_trips')}</NavLink>
                  </li>
                </ul>
              </nav>
              <div className="lg:flex items-center">

                {localStorage.getItem("user") ?

                  <Space size="small" className="lg:me-5 mb-3 lg:mb-0">
                    <Dropdown overlay={userMenu} >
                      <div>
                        <Avatar shape="circle" className="border border-primary me-1 cursor-pointer" src={avatarUrl} height={25} width={25} />
                      </div>
                    </Dropdown>

                    <Dropdown overlay={notificationsMenu}>
                      <div>
                        <div className="px-1 flex cursor-pointer">
                          <i className="icon-notifications text-primary font-size-22"></i>
                        </div>
                      </div>
                    </Dropdown>

                    <Dropdown overlay={tableMenu}>
                      <div>
                        <div className="px-1 flex cursor-pointer">
                          <i className="icon-calendar-date font-size-22"></i>
                        </div>
                      </div>
                    </Dropdown>
                  </Space>


                  : <Link to="/signin" className="me-4 mb-3 inline-block lg:mb-0 bg-primary-2 text-white font-bold border-0 py-2 px-3 rounded-lg">{t('login')}</Link>}
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={['click']}
                >
                  <a className="cursor-pointer font-size-20 flex items-center" onClick={(e) => e.preventDefault()}>
                    {currentLanguage !== 'en' && (
                      <div className="flex items-center"> <span className="icon-ar-flag me-1 "><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> العربية</div>
                    )}
                    {currentLanguage !== 'ar' && (
                      <div className="flex items-center"> <span className="icon-en-flag me-1 "><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span> English</div>

                    )}
                    <i className="icon-arrow-down ms-2 font-size-16 pb-1"></i>
                  </a>
                </Dropdown>

              </div>
            </div>
            <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer lg:hidden p-2 "><FontAwesomeIcon icon={faBars} /></div>
          </div>
        </div>
      </header>
    </>

  );
};

export default Header;
