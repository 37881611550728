import { Button, Col, Input, notification, Row } from 'antd'
import EmptyData from 'components/Shared/EmptyData';
import { fetchRewards } from 'features/offers/offersSlice';
import { fetchProfile } from 'features/profile/profileSlice';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

const Markets = () => {
  const {t , i18n} = useTranslation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  const { rewards } = useSelector((state) => state.offers);

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchRewards());
  }, [i18n.language]);


  const handleCopy = () => {
    const token = profile?.data?.marketing_token;

    if (token) {
      navigator.clipboard.writeText(token).then(() => {
        notification.success({
          message: t('link_copied')
        })
      }).catch(() => {
        notification.error({
          message: t('error_occurred_while_copying')
        })
      });
    } else {
      notification.warning({
        message: t('no_text_copy')
      })
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('marketing_link')}</title>
      </Helmet>
      <div>
        <h2 className='font-bold flex items-center mb-4'><span class="icon-coins me-2"><span class="path1"></span><span class="path2"></span></span> {t('your_marketing_link')} </h2>
        <Row className='mb-8' gutter={20} >
          <Col span={20}>
            <Input value={profile?.data?.marketing_token} size='large' className='text-left ' disabled />
          </Col>
          <Col span={4}>
            <Button onClick={handleCopy} size='large' block className='p-[14px] h-auto' type='primary'>{t('copy_link')}</Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className='border-primary rounded-lg p-4 border'>
              <h2 className='font-bold flex items-center mb-4'><span class="icon-coins me-2"><span class="path1"></span><span class="path2"></span></span> {t('available_offers')}</h2>
              <div className='border border-primary'>
                <Row className='bg-primary px-2 py-1 mb-2'>
                  <Col lg={6}><h2 className='text-white'>{t('number_points')}</h2></Col>
                  <Col lg={18}><h2 className='text-white'>{t('offer_description')}</h2></Col>
                </Row>
                {rewards?.loading ? <div className='p-3'><Skeleton height={20} className='mb-2' /><Skeleton height={20} style={{ width: "50%" }} /></div> : rewards?.data?.content?.length === 0 ? <EmptyData /> :
                  <>
                    {rewards?.data?.content?.map((reward) => (
                      <Row className='px-2 py-1 mb-2' key={reward?.id}>
                        <Col lg={6}><h2>{reward?.points_balance}</h2></Col>
                        <Col lg={18}><h2>{reward?.money_balance}</h2></Col>
                      </Row>
                    ))}
                  </>
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>

  )
}

export default Markets