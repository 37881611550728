import { Col, Row } from 'antd'
import Destinations from 'components/Shared/Destinations'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const FavoritesDestinations = () => {
  const {t} = useTranslation();
  return (
    <>
    <Helmet>
      <title>{t('nbt')} | {t('my_favorite_destinations_favorited')}</title>
    </Helmet>
    <Row className='mb-6'>
      <Col span={24}>
        <h2 className='font-bold flex items-center'> <i class="icon-favourite font-size-20 me-2"></i> {t('my_favorite_destinations_favorited')} </h2>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Destinations pagination={false} dashboard={true} numberCard={6} favorable_type="Destination" />
      </Col>
    </Row>
    </>
  )
}

export default FavoritesDestinations