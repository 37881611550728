// ModalComponent.js
import React from 'react';
import { Modal } from 'antd';

const ModalComponent = ({ isVisible, onClose, title, children , width , footer}) => {
  return (
    <Modal
      centered
      title={title}
      visible={isVisible}
      onCancel={onClose}
      width={width}
      footer={footer}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
