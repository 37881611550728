import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/error/errorSlice';


export const fetchDataPages = createAsyncThunk(
  'data/fetchDataPages',
  async (_ , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/pages`);
      return { type: 'dataPages', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchDataPagesSingle = createAsyncThunk(
  'data/fetchDataPagesSingle',
  async (id , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/pages/${id}`);
      return { type: 'dataPages', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial State
const initialState = {
  dataPages:{
    loading: false,
    data: [],
    error: null,
  },
  dataPageSingle:{
    loading: false,
    data: [],
    error: null,
  },
};

// Slice
const dataSlice2 = createSlice({
  name: 'pages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // blogs
      .addCase(fetchDataPages.pending, (state) => {
        state.dataPages.loading = true;
      })
      .addCase(fetchDataPages.fulfilled, (state, action) => {
        state.dataPages.loading = false;
        state.dataPages.data = action.payload.data.data;
      })
      .addCase(fetchDataPages.rejected, (state, action) => {
        state.dataPages.loading = false;
        state.dataPages.error = action.payload;
      })
      .addCase(fetchDataPagesSingle.pending, (state) => {
        state.dataPageSingle.loading = true;
      })
      .addCase(fetchDataPagesSingle.fulfilled, (state, action) => {
        state.dataPageSingle.loading = false;
        state.dataPageSingle.data = action.payload.data.data;
      })
      .addCase(fetchDataPagesSingle.rejected, (state, action) => {
        state.dataPageSingle.loading = false;
        state.dataPageSingle.error = action.payload;
      });
  },
});

export default dataSlice2.reducer;
