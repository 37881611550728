import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Controller } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';

const SliderThumb = ({ images, count }) => {
  const {t , i18n} = useTranslation();
  const mainSwiperRef = useRef(null);
  const thumbsSwiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null); 

  useEffect(() => {
    if (mainSwiperRef.current && thumbsSwiperRef.current) {
      mainSwiperRef.current.controller.control = thumbsSwiperRef.current;
      thumbsSwiperRef.current.controller.control = mainSwiperRef.current;
    }
  }, []);

  useEffect(() => {
    if (images && images.length > 0) {
      setActiveIndex(0); 
    }
  }, [images]); 
  

  if (!images || images.length === 0) {
    return <h3 className='text-primary font-bold'>{t('no_pictures')} </h3>;
  }

  return (
    <div dir={i18n.language === "ar" ? 'rtl' : 'ltr'}>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        loop={false}
        navigation={true}
        modules={[Navigation, Thumbs, Controller]}
        onSwiper={(swiper) => (mainSwiperRef.current = swiper)}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        className="main-swiper mb-3"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <Image
              style={{ height: "400px", objectFit: "cover", borderRadius: "4px" }}
              preview={false}
              src={image.image}
              alt={image.alt || `Slide ${index + 1}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        onSwiper={(swiper) => (thumbsSwiperRef.current = swiper)}
        spaceBetween={10}
        slidesPerView={window.innerWidth < 767 ? 5 : count ? 9 : 7}
        freeMode={true}
        loop={false}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs, Controller]}
        className="thumbs-swiper"
      >
        {images.map((image, index) => (
          <SwiperSlide
            key={index}
            onClick={() => {
              if (mainSwiperRef.current) {
                mainSwiperRef.current.slideTo(index);
                setActiveIndex(index); 
              }
            }}
            style={{ cursor: 'pointer' }} 
            className={activeIndex === index ? 'active' : ''} 
          >
            <Image
              preview={false}
              src={image.image || image.src}
              alt={image.alt || `Thumb ${index + 1}`}
              style={{ height: "54px", objectFit: "cover", borderRadius: "4px" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderThumb;
