import React, { useEffect, useState } from 'react';
import { Table, Select, Space, Button, Modal, Image, Form, Input, notification, Row, Col, Avatar, Rate } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBookings, postDeleteCancellations, fetchStatusFlow, postModifications , postTestimonialsTating } from 'features/booking/bookingSlice';
import {  useNavigate } from 'react-router-dom';
import cancel from "public/images/cancel-circle.svg";
import useAppParams from 'hooks/useAppParams';
import ModalComponent from 'components/Ui/ModalComponent';
import { useTranslation } from 'react-i18next';
import { fetchCategoriesCancellation } from 'features/faq/faqSlice';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from 'public/images/logo.svg'
import { fetchProfile } from 'features/profile/profileSlice';

const { Option } = Select;

const TableBooking = ({ trip }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const { UrlParams } = useAppParams({});
  const { booking, deleteCancellations , modifications , testimonials } = useSelector((state) => state.booking);
  const { categoriesCancellation } = useSelector((state) => state.faq);
  const [data, setData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tripModal, setTripModal] = useState(false);
  const [bookingId, setBookingId] = useState();
  const { profile } = useSelector((state) => state.profile);
  useEffect(() => {
    dispatch(fetchStatusFlow());
  }, []);
  useEffect(() => {
    if (localStorage.getItem("user")) {
      dispatch(fetchProfile());
    }
  }, [i18n.language])
  useEffect(() => {
    if (trip === "current") {
      dispatch(fetchBookings({
        params: { page: currentPage, perpage: 6 }, trip,
      }));
    } else if (trip === "previous") {
      dispatch(fetchBookings({
        params: { page: currentPage, perpage: 6 }, trip,
      }));
    }
    else {
      dispatch(fetchBookings({
        params: { page: currentPage, perpage: 6 },
      }));
    }
  }, [currentPage]);

  useEffect(() => {
    if (booking) {
      setData(booking?.data?.content || []);
    }
  }, [booking]);

  const columns = [
    {
      title: t('destinations_2'),
      dataIndex: ['destination', 'name'],
      key: 'destination.name',
    },
    {
      title: t('date'),
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: t('flight_status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className='px-2 inline-block py-1 font-size-12 font-bold rounded-full' style={{ backgroundColor: status?.status[0]?.color, color: "#FFF" }}>
          {status?.status[0]?.value}
        </span>
      ),
    },
    {
      title: t('flight_settings'),
      dataIndex: 'action',
      key: 'action',
      render: (value, record) => {
        return (
          <Space size="small" className='items-center'>
            <Button className="border-0 px-1 bg-transparent" onClick={() => editBooking({ record, type: "eye" })}>
              <i className='icon-eye font-size-22'></i>
            </Button>
            {trip && trip === "current" ?
              <Button className="border-0 px-1 bg-transparent" onClick={()=>ModalEditBooking(record.id)}>
                <i className='icon-edit2 font-size-22'></i>
              </Button> : trip && trip === "previous" ?
                <Button className="border-0 px-1 bg-transparent" onClick={()=>ModalEditBooking(record.destination.id)}>
                  <i className='icon-star2 text-primary font-size-22'></i>
                </Button>
                :
                <Button className="border-0 px-1 bg-transparent" onClick={() => editBooking({ record, type: "edit" })}>
                  <i className='icon-edit2 font-size-22'></i>
                </Button>
            }
            <Button className='border-0 px-1 bg-transparent' onClick={() => showModal(record)}>
              <Image src={cancel} height={22} preview={false} />
            </Button>
          </Space>
        );
      },
    },
  ];

  const editBooking = ({ record, type }) => {
    const storedData = {
      booking_date: record?.booking_date,
      arrival_time: record?.arrival_time,
      num_adults: record?.num_adults,
      num_children: record?.num_children,
      destination_id: record?.destination.id,
      accommodation_id: record?.accommodation.id,
      room_type: record?.room_type,
      num_rooms: record?.num_rooms,
      num_meals: record?.num_meals,
      vehicle_type: record?.vehicle_type,
      food_allergies: record?.food_allergies,
      events: record?.events_id,
      tourevent_id: record?.tourevent?.id,
      price: record?.price,
      id: record?.id,
      eye: true,
    };
    localStorage.setItem("bookingFormData", JSON.stringify(storedData));
    if (type === "edit") {
      const storedData2 = {
        ...storedData,
        eye: false,
        edit: true,
      };
      localStorage.setItem("bookingFormData", JSON.stringify(storedData2));
      navigate("/booking/step1");
    } else {
      const storedData2 = {
        ...storedData,
        eye: true,
        edit: false,
      };
      localStorage.setItem("bookingFormData", JSON.stringify(storedData2));
      navigate("/booking/step6");
    }
  };

  const showModal = (record) => {
    setTripModal(false)
    setSelectedRecord(record);
    setIsModalVisible(true);

    form.setFieldsValue({
      userName: record?.user?.name || "",
      reservation_number: record?.reservation_number || "",
    });

    dispatch(fetchCategoriesCancellation());


  };

  const handleClose = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };

  const onFinish = (values) => {
    values = {
      ...values,
      booking_id: selectedRecord.id,
    }

    dispatch(postDeleteCancellations({
      values, callback: (response) => {
        setIsModalVisible(false);
        notification.success({
          description: response.message,
          duration: 3,
        });
        dispatch(fetchBookings({
          params: { ...UrlParams, perpage: 6 },
        }));
      },
    }));
  };

  const ModalEditBooking = (id) => {
    setTripModal(true)
    setIsModalVisible(true);
    setBookingId(id)
  }


  const onFinishEditBooking = (values)=>{
    values={
      ...values,
      booking_id:bookingId
    }

    dispatch(postModifications({values , callback:(response)=>{
      notification.success({
        duration:3,
        message:response.message
      })
      form.resetFields();
      
    }}))
    
  }
  const onFinishRaingtBooking = (values)=>{
    values={
      ...values,
      testimonialsable_id:bookingId,
      testimonialsable_type:"App\\Models\\Destination"
    }
    dispatch(postTestimonialsTating({values , callback:(response)=>{
      notification.success({
        duration:3,
        message:response.message
      })
      form.resetFields();
      setIsModalVisible(false);
    }}))
  }

  return (
    <>
      <Table
        columns={columns}
        loading={booking.loading}
        dataSource={data}
        locale={{ emptyText: t('no_data') }}
        pagination={{
          pageSize: 6,
          current: currentPage,
          total: booking?.data?.pagination?.total,
          onChange: (page) => {
            setCurrentPage(page)
          },
        }}
      />
      <ModalComponent
        width={800}
        isVisible={isModalVisible}
        onClose={handleClose}
        title={false}
        footer={false}
      >
        <div className="cursor-pointer absolute font-bold top-9 border border-primary rounded-lg px-7 py-3  text-primary inline-flex items-center font-size-16" onClick={() => handleClose()} style={{ insetInlineStart: "25px", zIndex: "2" }}> <FontAwesomeIcon icon={faArrowRight} className="me-2" /> {t('back')}</div>

        <div className="text-center mb-5">
          <Image src={logo} alt="logo" height={80} preview={false} className="mx-auto" />
        </div>
        {tripModal && trip === "current" ? <>
          <h3 className='text-center font-bold mb-5'>تعديل على الرحلة المحجوزة</h3>
          <h5 className='font-bold mb-2'>نرجو منك توضيح التعديل الذي ترغب في إجرائه على حالة رحلتك النشطة، حتى تتمكن الإدارة من متابعته معك.</h5>
          <Form size="large"
            form={form}
            name="form"
            labelCol={{
              span: 24,
            }}
            onFinish={onFinishEditBooking}>
            <Form.Item
              label="تعديلك على الرحلة"
              name="body"
              rules={[{ required: true, message: t('field_required') },
              ]}
            >
              <Input.TextArea rows={5} placeholder='قم بكتابة التعديل الذي ترغب به على الرحلة' />
            </Form.Item>
            <Button loading={modifications?.loading} block type='primary' className='h-auto py-3' htmlType='submit'> {t('send')}</Button>
          </Form>

        </> : tripModal && trip === "previous" ? <>
        
        <h3 className='text-center font-bold mb-5'>الرجاء تقييم الرحلة</h3>
          <Form size="large"
            form={form}
            name="form"
            labelCol={{
              span: 24,
            }}
            onFinish={onFinishRaingtBooking}>
            <Form.Item
              label="نرجو منك مشاركة رأيك حول تجربتك في الرحلة وكتابة تعليق يعكس وجهة نظرك"
              name="rating"
              // rules={[{ required: true, message: t('field_required') },
              // ]}
            >
              <div className="rateing">
                <Rate onChange={(value) => form.setFieldsValue({ rating: value })} style={{fontSize:"50px" , color:"#B57134"}} />
              </div>
            </Form.Item>
            <Form.Item
              label="رأيك في الرحلة"
              name="body"
              rules={[{ required: true, message: t('field_required') },
              ]}
            >
              <Input.TextArea rows={5} placeholder='قم بكتابة تعليق يعكس وجهة نظرك.' />
            </Form.Item>
            <Button loading={testimonials?.loading} block type='primary' className='h-auto py-3' htmlType='submit'> {t('send')}</Button>
          </Form>
        
        </> :
          <>
            <h3 className='text-center font-bold mb-5'>قدم طلب الغاء رحلة</h3>
            {localStorage.getItem("user") ?
              <div className="bg-primary p-4 rounded-md mb-6">
                <Row align="middle">
                  <Col span={10}>
                    <div className="flex justify-between items-center">
                      <h5 className="text-white">أهلا وسهلا بك سيد</h5>
                      <div className="flex items-center">
                        <Avatar size={50} src={profile?.data?.image} alt={profile?.data?.name} />
                        <h5 className="text-white ms-4">{profile?.data?.name}</h5>
                      </div>
                    </div>
                  </Col>
                  <Col span={14}>
                    <h5 className="text-white text-end">{profile?.data?.phone}</h5>
                  </Col>
                </Row>
              </div>
              : null}
            <Form size="large"
              form={form}
              name="form"
              labelCol={{
                span: 24,
              }}
              onFinish={onFinish}
              autoComplete="off" >
              <Form.Item
                label={t('problem_title')}
                name="title"
                rules={[{ required: true, message: t('field_required') },
                ]}
              >
                <Input placeholder={t('problem_title')} />
              </Form.Item>
              {/* <Form.Item
            label={t('customer_name')}
            name="userName"
            rules={[{ required: true, message: t('field_required') },
            ]}
          >
            <Input disabled />
          </Form.Item> */}
              {/* <Form.Item
            label={t('reservation_number')}
            name="reservation_number"
            rules={[{ required: true, message: t('field_required') },
            ]}
          >
            <Input disabled />
          </Form.Item> */}

              <Form.Item
                rules={[{ required: true, message: t('field_required') },
                ]}
                label={t('reason_cancellation')}
                name="category_id">
                <Select loading={categoriesCancellation.loading} placeholder={t('please_specify_after_ction')}>
                  {categoriesCancellation?.data?.content?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={t('note')}
                name="body"
                rules={[{ required: true, message: t('field_required') },
                ]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              <Button loading={deleteCancellations?.loading} block type='primary' className='h-auto py-3' htmlType='submit'> {t('send')}</Button>
            </Form>
          </>
        }


      </ModalComponent>
    </>
  );
};

export default TableBooking;
