import { faArrowLeft, faArrowRight, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Col, Pagination, Rate, Row } from "antd";
import { fetchTestimonials } from "features/home/homeSlice";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
import useAppParams from "hooks/useAppParams";
import EmptyData from "../EmptyData";

const Testimonial = ({ numberCard, pagination, distance }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { loading: testimonialsLoading, data: testimonials } = useSelector((state) => state.data.testimonials);

  const [status, setStatus] = useState(1);
  const { UrlParams, handleSearch } = useAppParams({});

  const handleIncrement = () => setStatus(status + 1);
  const handleDecrement = () => setStatus(status > 1 ? status - 1 : 1);




  useEffect(() => {
    if (pagination && UrlParams) {
      dispatch(
        fetchTestimonials({
          params: {
            ...UrlParams,
            page: Number(UrlParams?.page ? UrlParams?.page : 1),
            perpage: numberCard,
          },
        })
      )
    } else if (UrlParams) {
      dispatch(fetchTestimonials({
        params: { page: status, perpage: numberCard },
      }));
    }
  }, [status, i18n.language, UrlParams])




  const pageinate = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };

  return (
    <>

      {testimonialsLoading ? (
        <Row gutter={25}>
          {window.innerWidth > 992 ? <>{Array.from({ length: 3 }).map((_, index) => (
            <Col span={24} lg={8} md={12} key={index}>
              <Skeleton className="mb-14 lg:mb-0" style={{ borderRadius: "16px" }} height={278} />
            </Col>
          ))}</> : <>{Array.from({ length: 1 }).map((_, index) => (
            <Col span={24} lg={8} md={12} key={index}>
              <Skeleton className="mb-14 lg:mb-0" style={{ borderRadius: "16px" }} height={278} />
            </Col>
          ))}</>}

        </Row>
      ) : testimonials?.content?.length > 0 ? (
        <Row gutter={25}>
          {testimonials.content.map((testimonial, index) => (
            <Col key={testimonial.id} span={24} lg={8} md={12}>
              <div className={`${style.widget_itemTestimonial} ${index === 1 ? style.center : ''} itemTestimonial  ${distance ? 'mb-14' : 'mb-14 lg:mb-0'}`}>
                <div className={`${style.widget_itemAvatar}`}>
                  <Avatar shape="circle" src={testimonial.image} size={72} />
                </div>
                <div className="text-end">
                  <Rate
                    disabled
                    defaultValue={testimonial.rating}
                    style={{ color: "#B57134" }}
                  />
                </div>
                <h6 className={`${style.widget_itemText} font-size-14 mt-6 mb-7`}>
                  "{testimonial.body}"
                </h6>
                <div className="flex items-center justify-between">
                  <div>
                    <h6>{testimonial.name}</h6>
                    <h5 className="text-primary font-size-12">
                      {testimonial.position}
                    </h5>
                  </div>
                  <h5>
                    <span
                      className={`${style.widget_itemIconLike} bg-primary me-3`}
                    >
                      <FontAwesomeIcon
                        className="text-white font-size-12"
                        icon={faThumbsUp}
                      />
                    </span>
                    {t('testimonials')}
                  </h5>

                </div>
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <EmptyData />
      )}

      {pagination ?
        <Pagination
          align="end"
          className="my-3"
          onChange={pageinate}
          defaultCurrent={1}
          current={UrlParams?.page ? Number(UrlParams?.page) : 1}
          total={testimonials.pagination?.total}
          pageSize={numberCard}
        />
        :




        <Row>
          <Col span={24}>
            <div className="flex justify-center items-center lg:mt-10">
              <button onClick={handleDecrement} disabled={status === 1 || testimonialsLoading} className="prev-pageination bg-primary  text-white w-12 h-12 rounded-tl-xl rounded-br-xl flex items-center justify-center mx-10 cursor-pointer">
                <FontAwesomeIcon className="text-lg" icon={faArrowRight} />
              </button>
              <button onClick={handleIncrement} disabled={testimonials?.pagination?.has_next === false || testimonialsLoading} className="next-pageination bg-primary  text-white w-12 h-12 rounded-tr-xl rounded-bl-xl flex items-center justify-center mx-10 cursor-pointer">
                <FontAwesomeIcon className="text-lg" icon={faArrowLeft} />
              </button>
            </div>
          </Col>
        </Row>
      }

    </>
  );
};

export default Testimonial;
