import { notification } from 'antd';
import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/error/errorSlice';


export const fetchBlogs = createAsyncThunk(
  'data/fetchBlogs',
  async (_ , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/posts`);
      return { type: 'blogs', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchSystemAdmins = createAsyncThunk(
  'data/fetchSystemAdmins',
  async (_ , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/system_admins`);
      return { type: 'systemAdmins', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchCategoriesBlog = createAsyncThunk(
  'data/fetchCategoriesBlog',
  async (_ , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/categories/post`);
      return { type: 'categoriesBlog', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchResentBlogs = createAsyncThunk(
  'data/fetchResentBlogs',
  async ({params} , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/posts` , {params , headers:{
          perpage:params.perpage
        }});
      return { type: 'resentBlogs', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchBlogsSingle = createAsyncThunk(
  'data/fetchBlogsSingle',
  async (id , { rejectWithValue ,dispatch}) => {
    try {
      const response = await axiosApp.get(`/posts/${id}`);
      return { type: 'blogsSingle', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const postComments = createAsyncThunk(
  'data/postComments',
  async ({values , callback} , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.post(`/comments` , values);
      notification.success({
        message:response.data.message,
        duration:3
      })
      callback?.()
      return { type: 'comments', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchComments = createAsyncThunk(
  'data/fetchComments',
  async ({id , status} , { rejectWithValue ,dispatch }) => {
    try {
      const response = await axiosApp.get(`/comments`, {
        params: {
          page:status,
          filters: {
            post_id: id,  
          },
        },
        headers:{
          perpage:5
        }
      });
      return { type: 'comment', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);


// Initial State
const initialState = {
  blogs:{
    loading: false,
    data: [],
    pagination: { current_page: 1, last_page: 1, has_next: false },
    error: null,
  },
  resentBlogs:{
    loading: false,
    data: [],
    pagination: { current_page: 1, last_page: 1, has_next: false },
    error: null,
  },
  blogsSingle:{
    loading: false,
    data: [],
    pagination: { current_page: 1, last_page: 1, has_next: false },
    error: null,
  },
  systemAdmins:{
    loading: false,
    data: [],
    error: null,
  },
  categoriesBlog:{
    loading: false,
    data: [],
    error: null,
  },
  comments:{
    loading: false,
    data: [],
    error: null,
  },
  comment:{
    loading: false,
    error: null,
  }
};

// Slice
const dataSlice2 = createSlice({
  name: 'blogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // blogs
      .addCase(fetchBlogs.pending, (state) => {
        state.blogs.loading = true;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.blogs.loading = false;
        state.blogs.data = action.payload.data.data;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.blogs.loading = false;
        state.blogs.error = action.payload;
      })
      // 
      .addCase(fetchResentBlogs.pending, (state) => {
        state.resentBlogs.loading = true;
      })
      .addCase(fetchResentBlogs.fulfilled, (state, action) => {
        state.resentBlogs.loading = false;
        state.resentBlogs.data = action.payload.data.data;
      })
      .addCase(fetchResentBlogs.rejected, (state, action) => {
        state.resentBlogs.loading = false;
        state.resentBlogs.error = action.payload;
      })
      // fetchSystemAdmins
      .addCase(fetchSystemAdmins.pending, (state) => {
        state.systemAdmins.loading = true;
      })
      .addCase(fetchSystemAdmins.fulfilled, (state, action) => {
        state.systemAdmins.loading = false;
        state.systemAdmins.data = action.payload.data.data;
      })
      .addCase(fetchSystemAdmins.rejected, (state, action) => {
        state.systemAdmins.loading = false;
        state.systemAdmins.error = action.payload;
      })
      // fetchCategoriesBlog
      .addCase(fetchCategoriesBlog.pending, (state) => {
        state.categoriesBlog.loading = true;
      })
      .addCase(fetchCategoriesBlog.fulfilled, (state, action) => {
        state.categoriesBlog.loading = false;
        state.categoriesBlog.data = action.payload.data.data;
      })
      .addCase(fetchCategoriesBlog.rejected, (state, action) => {
        state.categoriesBlog.loading = false;
        state.categoriesBlog.error = action.payload;
      })
      // 
      .addCase(fetchBlogsSingle.pending, (state) => {
        state.blogsSingle.loading = true;
      })
      .addCase(fetchBlogsSingle.fulfilled, (state, action) => {
        state.blogsSingle.loading = false;
        state.blogsSingle.data = action.payload.data.data;
      })
      .addCase(fetchBlogsSingle.rejected, (state, action) => {
        state.blogsSingle.loading = false;
        state.blogsSingle.error = action.payload;
      })
      //comment 
      .addCase(postComments.pending, (state) => {
        state.comment.loading = true;
      })
      .addCase(postComments.fulfilled, (state, action) => {
        state.comment.loading = false;
      })
      .addCase(postComments.rejected, (state, action) => {
        state.comment.loading = false;
        state.comment.error = action.payload;
      })
      //fetchComments 
      .addCase(fetchComments.pending, (state) => {
        state.comments.loading = true;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.comments.loading = false;
        state.comments.data = action.payload.data.data;
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.comments.loading = false;
        state.comments.error = action.payload;
      })
      ;
  },
});

export default dataSlice2.reducer;
