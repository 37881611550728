import { setError } from 'features/error/errorSlice';
import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchRewards = createAsyncThunk(
  'data/fetchRewards',
  async (_, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/rewards`);
      return { type: 'rewards', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchPoints = createAsyncThunk(
  'data/fetchPoints',
  async (_, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`/points` , {headers:{
        perpage:6
      }});
      return { type: 'points', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const autoRedeem = createAsyncThunk(
  'data/autoRedeem',
  async (_, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.post(`/redemptions/auto-redeem`)
      return { type: 'redeem', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial State
const initialState = {
  rewards:{
    loading: false,
    data: [], 
    error: null,
  },
  points:{
    loading: false,
    data: [], 
    error: null,
  },
  redeem:{
    loading: false,
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchRewards
      .addCase(fetchRewards.pending, (state) => {
        state.rewards.loading = true;
      })
      .addCase(fetchRewards.fulfilled, (state, action) => {
        state.rewards.loading = false;
        state.rewards.data = action.payload.data.data;
      })
      .addCase(fetchRewards.rejected, (state, action) => {
        state.rewards.loading = false;
        state.rewards.error = action.payload;
      })
      // fetchPoints
      .addCase(fetchPoints.pending, (state) => {
        state.points.loading = true;
      })
      .addCase(fetchPoints.fulfilled, (state, action) => {
        state.points.loading = false;
        state.points.data = action.payload.data.data;
      })
      .addCase(fetchPoints.rejected, (state, action) => {
        state.points.loading = false;
        state.points.error = action.payload;
      })
      // autoRedeem
      .addCase(autoRedeem.pending, (state) => {
        state.redeem.loading = true;
      })
      .addCase(autoRedeem.fulfilled, (state, action) => {
        state.redeem.loading = false;
        state.redeem.data = action.payload.data.data;
      })
      .addCase(autoRedeem.rejected, (state, action) => {
        state.redeem.loading = false;
        state.redeem.error = action.payload;
      })
      ;
  },
});

export default dataSlice.reducer;
