import { Col, Image, Row } from "antd";
import style from "./index.module.scss"
import Profile from "components/Shared/Profile";
import TableBooking from "./commponents/TableBooking";
import Destinations from "components/Shared/Destinations";
import { Link } from "react-router-dom";
import compass from "public/images/compass.svg"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchStatistics } from "features/profile/profileSlice";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { statistics } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(fetchStatistics())
  }, [])


  return (
    <>
      <Row>
        <Col span={24}>
          <Profile userDashboard={true} />
        </Col>
      </Row>
      {statistics.loading ? (
        <Row gutter={25}>
          {window.innerWidth > 992 ? <>{Array.from({ length: 4 }).map((_, index) => (
            <Col span={24} lg={6} md={12} key={index}>
              <Skeleton className="mb-4" style={{ borderRadius: "16px" }} height={220} />
            </Col>
          ))}</> : <>{Array.from({ length: 1 }).map((_, index) => (
            <Col span={24} lg={6} md={12} key={index}>
              <Skeleton className="mb-4" style={{ borderRadius: "16px" }} height={220} />
            </Col>
          ))}</>}

        </Row>
      ) : (
        <Row gutter={20} className="mb-3">
          {statistics?.data?.map((item) => (
            <Col key={item.key} span={24} lg={6} md={12}>
              <div className={`${style.wedget_itemInfo} px-2 py-9 text-center mb-5`}>
                <div
                  className={`${style.wedget_itemIcon} w-9 h-9 bg-white rounded-full flex items-center justify-center mx-auto mb-4`}
                >
                  <img src={item.icon} alt={item.label} />
                </div>
                <h4 className="font-bold text-white mb-3">{item.count}</h4>
                <h5 className="font-bold text-white">{item.label}</h5>
              </div>
            </Col>
          ))}
        </Row>
      )}

      <Row className="mb-12">
        <Col span={24} className="mb-5">
          <div className="flex items-center justify-between">
            <h2> <i className="icon-calendar"></i> {t('trips')}</h2>
          </div>
        </Col>
        <Col span={24}>
          <TableBooking />
        </Col>
      </Row>
      <Row className="mb-12">
        <Col span={24} className="mb-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Image src={compass} height={20} />
              <h2 className="ms-2">{t('new_recommended_destinations')}</h2>
            </div>
            <Link to="/destinations" className="text-primary font-bold">{t('view_all')}</Link>
          </div>
        </Col>
        <Col span={24}>
          <Destinations numberCard={3} pagination={true} dashboard={true} />
        </Col>
      </Row>
      <Row className="mb-12">
        <Col span={24} className="mb-5">
          <div className="flex items-center justify-between">
            <h2 className="flex items-center"> <i className="icon-favourite me-2"></i> {t('my_favorite_destinations')}</h2>
            <Link to="/dashboard/favorites/packages" className="text-primary font-bold">{t('view_all')}</Link>
          </div>
        </Col>
        <Col span={24}>
          <Destinations pagination={false} dashboard={true} numberCard={3} favorable_type="Destination" />
        </Col>
      </Row>
    </>
  );
};
export default Dashboard;