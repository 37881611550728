import React from "react";
import { Col, Row, Steps } from "antd";
import bg from "public/images/bg-layer.png";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
const StepsIndicator = ({ currentStep }) => {
  const {t} = useTranslation();
  return (
    <>
      <Row justify="center">
        <Col span={24} lg={20} className="text-center">
          <div
            style={{
              backgroundImage: `url(${bg})`,
              backgroundSize: '60%',
              backgroundPosition: '50% 100%',
              backgroundRepeat: "no-repeat",
            }}
          >
            <h2 className="font-size-30 font-bold text-primary-2 pt-10 pb-10">
              {t('customize_your_travel_package')}
            </h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Steps current={currentStep} className={`${style.stepBooking} mb-14`}>
            <Steps.Step title={t('date_people')} />
            <Steps.Step title={t('destinations_2')} />
            <Steps.Step title={t('accommodation')} />
            <Steps.Step title={t('trip_requirements')} />
            <Steps.Step title={t('trip_activities')} />
            <Steps.Step title={t('package_summary')} />
          </Steps>
        </Col>
      </Row>
    </>

  );
};

export default StepsIndicator;
