
import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const SocialMedia = () => {

  const { settings } = useSelector((state) => state.settings);
  const [filteredDataSocial, setFilteredDataSocial] = useState([]);


  useEffect(() => {
    if (settings && settings?.data?.content?.length > 0) {
      const result = settings?.data.content.filter(item => item.id >= 78 && item.id <= 83);
      setFilteredDataSocial(result);
    }
  }, [settings]);



  return (
    <ul className={`${styles.socialMedia} flex items-center text-white justify-center lg:justify-end`}>
      {filteredDataSocial.map(item => (
        item.key !== null ? ( 
          <li key={item.id}>
            <a href={item.value} target="_blank" className="flex">
              {item.key === "instagram" ? <span className="icon-instagram"></span> :
                item.key === "twitter" ? <span className="icon-twitter"></span>:
                  item.key === "tikTok" ? <span className="icon-tiktok"></span>:
                    item.key === "linkedIn" ? <span className="icon-linkedin"></span> :
                      item.key === "snapchat" ? <span className="icon-snapchat"></span> :
                        item.key === "youTube" ? <span className="icon-youtube"></span> : null}
            </a>
          </li>
        ) : null
      ))}
    </ul>
  );
};

export default SocialMedia;
