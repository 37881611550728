import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Image, notification, Spin } from 'antd';
import { switchfavourite, toggleFavorite } from 'features/home/homeSlice';
import useAppParams from 'hooks/useAppParams';
import { fetchFavorites } from 'features/favorites/favoritesSlice';
import heart from "public/images/heart.svg"
import { useTranslation } from 'react-i18next';
const FavoriteToggle = ({ cardId, isFavorited, favorable_type , dashboard }) => {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { UrlParams } = useAppParams({});

  const handleToggleFavorite = async () => {
    const originalState = isFavorited;
    const newState = !originalState;

    dispatch(toggleFavorite(cardId));

    setLoading(true);

    try {
      if (newState) {
        await dispatch(switchfavourite({
          values: {
            favorable_id: cardId,
            id: cardId,
            favorable_type: favorable_type,
          },
          callback: (response) => {
            notification.success({
              description: response.message,
              duration: 3,
            });
            dispatch(fetchFavorites({
              params: {
                ...UrlParams,
                page: Number(UrlParams?.page ? UrlParams?.page : 1),
                favorable_type: favorable_type === "App\\Models\\Destination" ? "Destination" :  favorable_type === "App\\Models\\Tourevent" ? "Tourevent" :  favorable_type === "App\\Models\\Accommodation" ? "Accommodation" : null,
                perpage: 6,
              },
            }));
          },
        })).unwrap();
      } else {
        await dispatch(switchfavourite({
          values: {
            favorable_id: cardId,
            id: cardId,
            favorable_type: favorable_type,
            _method: "delete",
          },
          callback: (response) => {
            notification.success({
              description: response.message,
              duration: 3,
            });
            // if(dashboard){
              dispatch(fetchFavorites({
                params: {
                  ...UrlParams,
                  page: Number(UrlParams?.page ? UrlParams?.page : 1),
                  favorable_type: favorable_type === "App\\Models\\Destination" ? "Destination" :  favorable_type === "App\\Models\\Tourevent" ? "Tourevent" :  favorable_type === "App\\Models\\Accommodation" ? "Accommodation" : null,
                  perpage: 6,
                },
              }));
            // }
          },
        })).unwrap();
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
      notification.error({
        description: error.message ||t('an_unexpected_error_occurred'),
        duration: 3,
      });

      dispatch(toggleFavorite(cardId));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`${favorable_type==="App\\Models\\Booking" ? 'relative top-0 inline-flex justify-end' : 'absolute flex'} p-1 z-2 cursor-pointer font-size-30 items-center ${favorable_type === "App\\Models\\Accommodation" ? 'end-0' : favorable_type === "App\\Models\\Tourevent" ? 'top-[10px]' :favorable_type != "App\\Models\\Booking" ? 'mx-5 top-[23px]' : null}`}
      onClick={handleToggleFavorite}
    >
      {loading ? (
        <Spin size="default" />
      ) : (
        isFavorited ? <i className='icon-heart-solid'></i> : <Image height={28} preview={false} src={heart} alt="heart" />
      )}
    </div>
  );
};
export default FavoriteToggle;
