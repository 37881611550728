import Destinations from 'components/Shared/Destinations';
import Filter from 'components/Shared/Destinations/filter';
import TitlePage from 'components/Shared/TitlePage';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
function DestinationsPage() {
  const {t} = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('destinations')}</title>
      </Helmet>
      <TitlePage title={t('destinations')} bg={true} />
      <section className='py-8 mt-6'>
        <div className='container'>
          <h2 className='font-bold text-primary-2 flex items-center mb-4'>
            <svg className='me-2' width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.2767 31.875L26.2654 35.6306C26.2636 36.5119 26.2617 36.9525 25.9861 37.2263C25.7104 37.5 25.2698 37.5 24.3848 37.5H18.7411C11.6536 37.5 8.10981 37.5 5.90668 35.3025C4.38793 33.7913 3.91731 31.6444 3.76918 28.1456C3.74106 27.4519 3.72606 27.1031 3.85543 26.8725C3.98668 26.6419 4.50231 26.3531 5.53731 25.7738C6.12031 25.4487 6.60594 24.9739 6.94401 24.3984C7.28208 23.8229 7.46033 23.1675 7.46033 22.5C7.46033 21.8325 7.28208 21.1772 6.94401 20.6016C6.60594 20.0261 6.12031 19.5513 5.53731 19.2263C4.50231 18.6488 3.98481 18.3581 3.85543 18.1275C3.72606 17.8969 3.74106 17.55 3.77106 16.8544C3.91731 13.3556 4.38981 11.2106 5.90668 9.6975C8.10793 7.5 11.6517 7.5 18.7411 7.5H25.3223C25.4454 7.49976 25.5674 7.52376 25.6812 7.57065C25.7951 7.61753 25.8985 7.68638 25.9858 7.77326C26.073 7.86014 26.1423 7.96335 26.1896 8.077C26.2369 8.19065 26.2614 8.31251 26.2617 8.43563L26.2767 13.125C26.2767 14.16 27.1186 15 28.1554 15V18.75C27.1186 18.75 26.2767 19.59 26.2767 20.625V24.375C26.2767 25.41 27.1186 26.25 28.1554 26.25V30C27.1186 30 26.2767 30.84 26.2767 31.875Z" fill="#462009" />
              <path opacity="0.5" d="M28.1562 30C29.1931 30 30.035 30.84 30.035 31.875V35.58C30.035 36.4838 30.035 36.9356 30.3256 37.2113C30.6144 37.4888 31.0569 37.47 31.9438 37.4325C35.4369 37.2844 37.5819 36.8119 39.095 35.3025C40.6119 33.7913 41.0825 31.6425 41.2306 28.1438C41.2587 27.45 41.2738 27.1031 41.1444 26.8725C41.015 26.64 40.4975 26.3513 39.4625 25.7738C38.8795 25.4487 38.3939 24.9739 38.0558 24.3984C37.7177 23.8229 37.5395 23.1675 37.5395 22.5C37.5395 21.8325 37.7177 21.1772 38.0558 20.6016C38.3939 20.0261 38.8795 19.5513 39.4625 19.2263C40.4975 18.6469 41.015 18.3581 41.1444 18.1275C41.2756 17.895 41.2587 17.5481 41.2306 16.8544C41.0825 13.3556 40.6119 11.2088 39.0931 9.69564C37.4488 8.05502 35.0544 7.64064 30.9894 7.53564C30.8651 7.53264 30.7415 7.55453 30.6258 7.60001C30.5102 7.6455 30.4048 7.71366 30.3158 7.8005C30.2269 7.88733 30.1563 7.99108 30.108 8.10563C30.0598 8.22018 30.035 8.34322 30.035 8.46752V13.125C30.035 14.16 29.1931 15 28.1562 15V18.75C28.4028 18.7495 28.647 18.7977 28.8749 18.8917C29.1029 18.9857 29.31 19.1237 29.4845 19.2979C29.659 19.472 29.7975 19.6789 29.8919 19.9066C29.9864 20.1343 30.035 20.3785 30.035 20.625V24.375C30.035 25.41 29.1931 26.25 28.1562 26.25V30Z" fill="#462009" />
            </svg>
            {t('choose_tourist_destination')}:</h2>
          <Filter filterButton={true}  />
          <Destinations numberCard={9} pagination={true} />
        </div>
      </section>
    </>
  );
};
export default DestinationsPage;