import { Image } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import weather from 'public/images/weather.svg'
import workplace from 'public/images/workplace.svg'
import people from 'public/images/people.svg'
import like from 'public/images/like.svg'
const DetailsDestinations = ({name , weather_description , amenities , annual_visitors_min , annual_visitors_max ,  recommended_visit_season}) => {
  const {t} = useTranslation();
  return (
    <div>
      <h2 className="font-bold text-black mb-7">{name}</h2>
      <div className="flex items-center mb-5 lg:mb-7">
        <Image src={weather} alt="weather" />
        <div className="ms-3">
          <h6 className="font-size-14">{t('weather')}</h6>
          <h6 className="font-size-14">{weather_description}</h6>
        </div>
      </div>
      <div className="flex items-center mb-5 lg:mb-7">
        <Image src={workplace} alt="weather" />
        <div className="ms-3">
          <h6 className="font-size-14">{t('available_facilities')}</h6>
          <h6 className="font-size-14">{amenities}</h6>
        </div>
      </div>
      <div className="flex items-center mb-5 lg:mb-7">
        <Image src={people} alt="weather" />
        <div className="ms-3">
          <h6 className="font-size-14">{t('average_annual_visitors')}</h6>
          <h6 className="font-size-14">{annual_visitors_min} <span className="mx-1 inline-block"></span> {annual_visitors_max}</h6>
        </div>
      </div>
      <div className="flex items-center">
        <Image src={like} alt="weather" />
        <div className="ms-3">
          <h6 className="font-size-14"> {t('visit_during_summer')} </h6>
          <h6 className="font-size-14">{recommended_visit_season}</h6>
        </div>
      </div>
    </div>
  )
}

export default DetailsDestinations