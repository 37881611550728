import React, { useEffect, useState } from 'react';
import { Table, Select, Space, Button, Image, Form, notification, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatusFlow } from 'features/booking/bookingSlice';
import { useNavigate } from 'react-router-dom';
import useAppParams from 'hooks/useAppParams';
import { fetchCategoriesCancellation } from 'features/faq/faqSlice';
import { fetchFavorites } from 'features/favorites/favoritesSlice';
import { switchfavourite } from 'features/home/homeSlice';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import remove_favorite from 'public/images/remove_favorite.svg'
import share from 'public/images/share-fill.svg'
import premium from 'public/images/premium.svg'



const  FavoritesBookings= () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSearch, UrlParams } = useAppParams({});
  const [data, setData] = useState();
  const { favorites } = useSelector((state) => state.favorites);
  const [loadingRows, setLoadingRows] = useState({}); // حالة التحميل لكل صف
  
  useEffect(() => {
    dispatch(fetchStatusFlow());
    dispatch(fetchCategoriesCancellation());

  }, []);

  useEffect(() => {
    if(UrlParams){
      dispatch(fetchFavorites({
        params: {
          ...UrlParams,
          page: Number(UrlParams?.page || 1),
          favorable_type: "Booking",
          perpage: 6,
        },
    }))
    }
  }, [UrlParams]);

  useEffect(() => {
    if (favorites) {
      setData(favorites?.data?.content || []);
    }
  }, [favorites]);

  const columns = [
    {
      title: t('destinations_2'),
      dataIndex: ['favorable', 'destination', 'name'],
      key: 'favorable.destination.name',
    },
    {
      title: t('thumbnail'),
      dataIndex: ['favorable', 'destination', 'image'], 
      key: 'favorable.destination.image',
      render: (image) => (
        <Image
          src={image} 
          alt={t('destinations_2')}
          width={50} 
          height={50} 
          preview={false} 
          style={{ objectFit: 'cover', borderRadius: '5px' }} 
        />
      ),
    },
    {
      title: t('date'),
      dataIndex: ['favorable', 'trip_type'],
      key: 'favorable.trip_type',
      render: (trip_type) => {
        return ( 
          <span>
            {trip_type === "TOURIST" ? t('tourist') : 
             trip_type === "BUSINESS" ? t('business') : 
             trip_type === "RELIGIOUS" ? t('religious') : 
             trip_type === "OTHER" ? t('other') :  
             null}
          </span>
        );
      },
    },
    {
      title: t('flight_status'),
      dataIndex: ['favorable', 'status'], 
      key: 'favorable.status',
      render: (status) => (
        <span
        className='px-1 inline-block py-1 font-size-12 font-bold rounded-md'
        style={{
          backgroundColor: status?.status[0]?.color, 
          color: "#FFF", 
        }}
      >
        {status?.status[0]?.value} 
      </span>
      ),
    },
    
    {
      title: t('flight_settings'),
      dataIndex: 'action',
      key: 'action',
      render: (value, record) => {
        const isLoading = !!loadingRows[record.favorable.id];
        return (
          <Space size="small" className='items-center'>
            <Button className="border-0 px-1 bg-transparent" onClick={() => editBooking({ record, type: "eye" })}>
              <i className='icon-eye font-size-22'></i>
            </Button>
            <Button className="border-0 px-1 bg-transparent" onClick={() => editBooking({ record, type: "edit" })}>
              <i className='icon-refresh font-size-22'></i>
            </Button>
            <Button className="border-0 px-1 bg-transparent flex" onClick={() =>removeFavourite(record)}>
            {/* {isLoading ? "Loading..." : "Remove"} */}
            {isLoading ? <Spin size='small' /> : <img src={remove_favorite} />}
            </Button>
            <Button className='border-0 px-1 bg-transparent'>
              <img src={share} />
            </Button>
          </Space>
        );
      },
    },
  ];

  const editBooking = ({ record, type }) => {
    const storedData = {
      booking_date: record.favorable.booking_date,
      arrival_time: record.favorable.arrival_time,
      num_adults: record.favorable.num_adults,
      num_children: record.favorable.num_children,
      destination_id: record.favorable.destination.id,
      accommodation_id: record.favorable.accommodation.id,
      room_type: record.favorable.room_type,
      num_rooms: record.favorable.num_rooms,
      num_meals: record.favorable.num_meals,
      vehicle_type: record.favorable.vehicle_type,
      food_allergies: record.favorable.food_allergies,
      events: record.favorable.events_id,
      tourevent_id: record.favorable.tourevent.id,
      price: record.favorable.price,
      id: record.favorable.id,
      eye: true,
    };
    localStorage.setItem("bookingFormData", JSON.stringify(storedData));
    if (type === "edit") {
      const storedData2 = {
        ...storedData,
        eye: false,
        edit: true,
      };
      localStorage.setItem("bookingFormData", JSON.stringify(storedData2));
      navigate("/booking/step1");
    } else {
      const storedData2 = {
        ...storedData,
        eye: true,
        edit: false,
      };
      localStorage.setItem("bookingFormData", JSON.stringify(storedData2));
      navigate("/booking/step6");
    }
  };

  
  
  const removeFavourite = (favourite) => {
    setLoadingRows((prev) => ({ ...prev, [favourite.favorable.id]: true })); 
    dispatch(
      switchfavourite({
        values: {
          favorable_id: favourite.favorable.id,
          id: favourite.favorable.id,
          favorable_type: "App\\Models\\Booking",
          _method: "delete",
        },
        callback: (response) => {
          notification.success({
            description: response.message,
            duration: 3,
          });
          setLoadingRows((prev) => {
            const newState = { ...prev };
            delete newState[favourite.favorable.id];
            return newState;
          });
          dispatch(
            fetchFavorites({
              params: {
                ...UrlParams,
                page: Number(UrlParams?.page || 1),
                favorable_type: "Booking",
                perpage: 6,
              },
            })
          );
        },
      })
    ).unwrap()
      .catch((error) => {
        notification.error({
          description: error ,
          duration: 3,
        });
        setLoadingRows((prev) => {
          const newState = { ...prev };
          delete newState[favourite.favorable.id]; 
          return newState;
        });
      });
  };
  


  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('favorites_packages_customized')}</title>
      </Helmet>
      <h2 className='font-bold flex items-center mb-6'> <img src={premium} alt="premium" className='me-2 pb-1' /> {t('favorites_packages_customized')} </h2>
      <Table
        columns={columns}
        locale={{ emptyText : t('no_data') }}
        loading={favorites.loading}
        dataSource={data}
        pagination={{
          pageSize: 6,
          current: Number(UrlParams?.page ? UrlParams?.page : 1),
          total: favorites?.data?.pagination?.total,
          onChange: (page) => {
            handleSearch({ fields: { page: page } });
          },
        }}
      />
    </>
  );
};

export default FavoritesBookings;
