import { setError } from 'features/error/errorSlice';
import axiosApp from 'api/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const fetchFavorites = createAsyncThunk(
  'data/fetchFavorites',
  async ({params }, { rejectWithValue ,dispatch }) => {

    try {
      const cleanObject = (obj) => {
        return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== ''));
      };
      
      const filters = cleanObject({
        name: params?.name || '', 
        favorable_type: params?.favorable_type || '',
      });
      
      const requestParams = {
        page: params?.page || 1,
        filters: Object.keys(filters).length > 0 ? filters : undefined, 
        filter_operator: Object.keys(filters).length > 0 ? "like" : undefined, 
      };

      const response = await axiosApp.get('/favorites', {
        params: requestParams, 
          headers: {
            perpage: params.perpage, 
          },
      });
      // callback?.();
      return { type: 'favorites', data: response.data }; 

    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage); 
    }
  }
);


// Initial State
const initialState = {
  favorites: {
    loading: false,
    data: [],
    error: null,
  },
};

// Slice
const dataSlice2 = createSlice({
  name: 'favorites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchFavorites
      .addCase(fetchFavorites.pending, (state) => {
        state.favorites.loading = true;
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.favorites.loading = false;
        state.favorites.data = action.payload.data.data;
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.favorites.loading = false;
        state.favorites.error = action.payload;
      })
  },
});

export default dataSlice2.reducer;
