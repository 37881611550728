import React, { useEffect, useState } from "react";
import { Form, Input, Button, Image, Col, Row, Radio, notification } from "antd";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import StepsIndicator from "./StepsIndicator.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearch} from "@fortawesome/free-solid-svg-icons";
import style from "./index.module.scss";
import useAppParams from "hooks/useAppParams.js";
import { useTranslation } from "react-i18next";
import arrowLeft from 'public/images/arrow-left.svg'
import arrowRight2 from 'public/images/arrow-right2.svg'
import Accommodation from "components/Shared/Accommodation/index.jsx";
import { Helmet } from "react-helmet";

const { Search } = Input;
const Step3 = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [accommodation_id, setSelectedAccommodation] = useState(null);
  const { handleSearch } = useAppParams({});
  const initialData = location.state || {};
  const [selectedFilter, setSelectedFilter] = useState("All");


  const submitSearch = (values) => {
    handleSearch({
      fields: { title: values },
      deletedFields: ["page"],
    });
  };


  const onChangeFilter = (values) => {
    setSelectedFilter(values.target.value);
    handleSearch({
      fields: { type: values.target.value },
      deletedFields: ["page"],
    });
  };


  useEffect(() => {
    const savedData = localStorage.getItem("bookingFormData");
    const parsedData = JSON.parse(savedData); 
    if (savedData) {
      setSelectedAccommodation(parsedData.accommodation_id);
    }
  }, [location, navigate])

  const handleCardSelection = (id) => {
    setSelectedAccommodation(id);
  };


  const onFinish = () => {
    if (!accommodation_id) {
      notification.warning({
        description:t('choose_stay_trip'),
        duration: 3, 
      });
    } else {

      const storedData = localStorage.getItem("bookingFormData");
      const parsedData = storedData ? JSON.parse(storedData) : {};

      const updatedData = {
        ...parsedData,
        ...initialData, 
        accommodation_id: accommodation_id || parsedData.accommodation_id,
      };
      localStorage.setItem("bookingFormData", JSON.stringify(updatedData));
      navigate("/booking/step4", { state: updatedData });
    }
  };


  if (!localStorage.getItem("bookingFormData")) {
    return <Navigate to="/booking/step1" />
  }
  else if (!JSON.parse(localStorage.getItem("bookingFormData")).destination_id || !JSON.parse(localStorage.getItem("bookingFormData")).edit) {
    return <Navigate to="/booking/step2" />
  }

  return (
    <>
    <Helmet>
      <title>{t('nbt')} | {t('customize_your_travel_package')}</title>
    </Helmet>
      <section className="py-8">
        <div className="container">
          <StepsIndicator currentStep={2} />
          <h2 className="text-primary-2 mb-4 font-bold"> <i className="icon-hotel me-2"></i> {t('choose_stay_trip')}:</h2>
          <div className="filter mb-10">
            <div className="mb-4">
              <Search onSearch={submitSearch} prefix={<FontAwesomeIcon icon={faSearch} className="me-2" />} placeholder={t('find_stay_your_trip')} enterButton={t('search')} size="large" />
            </div>
            <Radio.Group value={selectedFilter} onChange={onChangeFilter} className={`${style.filterAccommodation}`}>
              <Radio.Button value="All">{t('all_options')}</Radio.Button>
              <Radio.Button value="HOTEL">{t('hotel')}</Radio.Button>
              <Radio.Button value="CARAVAN">{t('caravan')}</Radio.Button>
              <Radio.Button value="CHALET">{t('chalet')}</Radio.Button>
              <Radio.Button value="CAMP">{t('camp')}</Radio.Button>
              <Radio.Button value="RESORT">{t('resort')}</Radio.Button>
            </Radio.Group>
          </div>
          
          <Form form={form} layout="vertical" onFinish={onFinish}>
            
            
            <Accommodation onCardSelect={handleCardSelection} numberCard={8} selectedCardId={accommodation_id} />

            <Row justify="center" className="mt-9">
              <Col span={24} lg={12}>
                <div className="flex items-center justify-around w-full">
                  <Form.Item>
                    <Button className="text-primary border-primary font-bold px-8 py-3 h-auto font-size-18"
                      onClick={() => navigate("/booking/step2")}
                    >
                      <Image src={arrowRight2} alt="arrowLeft" preview={false} className="me-1 translate-scale-1-ltr" />
                      {t('prev')}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button className="bg-primary-2 text-white font-bold px-8 py-3 h-auto font-size-18" htmlType="submit">
                      {t('next')}
                      <Image src={arrowLeft} alt="arrowLeft" preview={false} className="ms-1 translate-scale-1-ltr" />
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </section>
    </>
  );
};

export default Step3;
