import { Col, Input, Menu, Row } from "antd";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import style from '../index.module.scss';
import Breadcrumbs from "components/Ui/Breadcrumb";
import { useTranslation } from "react-i18next";

function DashboardLayout() {
  const location = useLocation();
  const {t} = useTranslation();

  // بيانات لكل صفحة
  const pageData = {
    "/dashboard": {
      breadcrumbsTitle: t('dashboard'),
    },
    "/dashboard/favorites/bookings": {
      breadcrumbsTitle: t('favorite_packages'),
      breadcrumbsNavLink: t('favorite'),
    },
    "/dashboard/favorites/destinations": {
      breadcrumbsTitle: t('favorite_destinations'),
      breadcrumbsNavLink: t('favorite'),
    },
    "/dashboard/favorites/accommodations": {
      breadcrumbsTitle: t('favorite_accommodations'),
      breadcrumbsNavLink: t('favorite'),
    },
    "/dashboard/favorites/tourevents": {
      breadcrumbsTitle: t('favorite_events'),
      breadcrumbsNavLink: t('favorite'),
    },
    "/dashboard/current-booking": {
      breadcrumbsTitle: t('my_current_trips'),
      breadcrumbsNavLink: t('manage_trips'),
    },
    "/dashboard/previous-booking": {
      breadcrumbsTitle: t('my_previous_trips'),
      breadcrumbsNavLink: t('manage_trips'),
    },
    "/dashboard/notifications": {
      breadcrumbsTitle: t('notifications'),
      breadcrumbsNavLink: t('notification_center'),
    },
    "/dashboard/activities": {
      breadcrumbsTitle: t('activities'),
      breadcrumbsNavLink: t('notification_center'),
    },
    "/dashboard/rewards": {
      breadcrumbsTitle: t('rewards_center'),
      breadcrumbsNavLink: t('offers_gifts'),
    },
    "/dashboard/points": {
      breadcrumbsTitle: t('my_points_record'),
      breadcrumbsNavLink: t('offers_gifts'),
    },
    "/dashboard/markets": {
      breadcrumbsTitle: t('marketing_link'),
      breadcrumbsNavLink: t('offers_gifts'),
    },
    "/dashboard/new-ticket": {
      breadcrumbsTitle: t('open_support_ticket'),
      breadcrumbsNavLink: t('technical_support'),
    },
    "/dashboard/tickets": {
      breadcrumbsTitle: t('tickets_history'),
      breadcrumbsNavLink: t('technical_support'),
    },
    "/dashboard/faq": {
      breadcrumbsTitle: t('faq_2'),
      breadcrumbsNavLink: t('technical_support'),
    },
    "/dashboard/invoices": {
      breadcrumbsTitle: t('payment_billing_history'),
      breadcrumbsNavLink: t('payment_billing'),
    },
  };

  const currentPage = pageData[location.pathname] || {
    breadcrumbsTitle: "الصفحة غير موجودة",
    breadcrumbsNavLink: "الصفحة الرئيسية",
    // inputPlaceholder: "بحث هنا",
  };

  const items = [
    {
      key: '1',
      label: <NavLink to="/dashboard">{t('dashboard')}</NavLink>,
      icon: <i className="icon-chart font-size-20"></i>,
      path: '/dashboard',
    },
    {
      key: '2',
      label: t('favorite'),
      icon: <i className="icon-favourite font-size-20"></i>,
      children: [
        {
          key: '3',
          label: <NavLink to="/dashboard/favorites/bookings">{t('favorite_packages')}</NavLink>,
          type: 'group',
          path: '/dashboard/favorites/bookings',
        },
        {
          key: '4',
          label: <NavLink to="/dashboard/favorites/destinations">{t('favorite_destinations')}</NavLink>,
          type: 'group',
          path: '/dashboard/favorites/destinations',
        },
        {
          key: '4',
          label: <NavLink to="/dashboard/favorites/accommodations">{t('favorite_accommodations')}</NavLink>,
          type: 'group',
          path: '/dashboard/favorites/accommodations',
        },
        {
          key: '4',
          label: <NavLink to="/dashboard/favorites/tourevents">{t('favorite_events')}</NavLink>,
          type: 'group',
          path: '/dashboard/favorites/tourevents',
        },
      ],
    },
    {
      key: '5',
      label: t('manage_trips'),
      icon: <i className="icon-calendar font-size-20"></i>,
      children: [
        {
          key: '6',
          label: <NavLink to="/dashboard/current-booking">{t('my_current_trips')}</NavLink>,
          type: 'group',
          path: '/dashboard/current-booking',
        },
        {
          key: '7',
          label: <NavLink to="/dashboard/previous-booking">{t('my_previous_trips')}</NavLink>,
          type: 'group',
          path: '/dashboard/previous-booking',
        },
        // {
        //   key: '8',
        //   label: <NavLink to="/">إلغاء رحلة </NavLink>,
        //   type: 'group',
        //   path: '/',
        // },
      ],
    },
    {
      key: '9',
      label: t('notification_center'),
      icon: <i className="icon-notifications font-size-20"></i>,
      children: [
        {
          key: '10',
          label: <NavLink to="/dashboard/notifications">{t('notifications')}</NavLink>,
          type: 'group',
          path: '/dashboard/notifications',
        },
        {
          key: '11',
          label: <NavLink to="/dashboard/activities">{t('activities')}</NavLink>,
          type: 'group',
          path: '/dashboard/activities',
        },
      ],
    },
    {
      key: '12',
      label: t('offers_gifts'),
      icon: <i className="icon-gift font-size-20"></i>,
      children: [
        {
          key: '13',
          label: <NavLink to="/dashboard/rewards">{t('rewards_center')}</NavLink>,
          type: 'group',
          path: '/dashboard/rewards',
        },
        {
          key: '14',
          label: <NavLink to="/dashboard/points">{t('my_points_record')}</NavLink>,
          type: 'group',
          path: '/dashboard/points',
        },
        {
          key: '15',
          label: <NavLink to="/dashboard/markets">{t('marketing_link')}</NavLink>,
          type: 'group',
          path: '/dashboard/markets',
        },
      ],
    },
    {
      key: '16',
      label: t('payment_billing'),
      icon: <i className="icon-card-pay font-size-20"></i>,
      children: [
        {
          key: '17',
          label: <NavLink to="/dashboard/invoices">{t('payment_billing_history')}</NavLink>,
          type: 'group',
          path: '/dashboard/invoices',
        },
      ],
    },
    {
      key: '18',
      label: t('technical_support'),
      icon: <i className="icon-helps font-size-20"></i>,
      children: [
        {
          key: '19',
          label: <NavLink to="/dashboard/new-ticket">{t('open_support_ticket')}</NavLink>,
          type: 'group',
          path: '/dashboard/new-ticket',
        },
        {
          key: '20',
          label: <NavLink to="/dashboard/tickets">{t('tickets_history')}</NavLink>,
          type: 'group',
          path: '/dashboard/tickets',
        },
        {
          key: '21',
          label: <NavLink to="/dashboard/faq">{t('faq_2')}</NavLink>,
          type: 'group',
          path: '/dashboard/faq',
        },
      ],
    },
  ];

  const getOpenKeys = (items, pathname) => {
    let openKeys = [];

    items.forEach(item => {
      if (item.path && item.path === pathname) {
        openKeys.push(item.key);
      }

      if (item.children) {
        const childOpenKeys = getOpenKeys(item.children, pathname);
        if (childOpenKeys.length > 0) {
          openKeys.push(item.key); 
          openKeys = [...openKeys, ...childOpenKeys];
        }
      }
    });

    return openKeys;
  };

  const openKeys = getOpenKeys(items, location.pathname);

  const getSelectedKey = (items) => {
    for (let item of items) {
      if (item.path === location.pathname) {
        return item.key;
      }

      if (item.children) {
        const selectedChildKey = getSelectedKey(item.children);
        if (selectedChildKey) {
          return selectedChildKey;
        }
      }
    }
    return null;
  };

  const selectedKey = getSelectedKey(items);

  return (
    <section className="py-14">
      <div className="container">
        <Row className="mb-7 lg:mb-10">
          <Col span={24} lg={12}>
            <Breadcrumbs title={currentPage.breadcrumbsNavLink} NavLink={currentPage.breadcrumbsTitle} /> 
          </Col>
          {/* <Col span={24} lg={12}>
            <Input placeholder={currentPage.inputPlaceholder} />
          </Col> */}
        </Row>
        <Row>
          <Col span={24} lg={5}>
            <Menu 
              className={`${style.menuDash}`}
              selectedKeys={selectedKey ? [selectedKey] : []}  
              defaultOpenKeys={openKeys}  
              mode="inline"
              items={items}
            />
          </Col>
          <Col span={24} lg={19}>
            <Outlet />
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default DashboardLayout;
