import TitlePage from "components/Shared/TitlePage";
import { useDispatch, useSelector } from "react-redux";
import { Col, Image, Row } from "antd";
import { useEffect } from "react";
import { fetchAbout  ,fetchNumber } from "features/about/aboutSlice";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import style from './index.module.scss';
import { useTranslation } from "react-i18next";
import { fetchAboutUs } from "features/home/homeSlice";
import bg from "public/images/bg.png"
import { Helmet } from "react-helmet";

function About() {
  const dispatch = useDispatch();
  const { about , number } = useSelector((state) => state.about);
  const { i18n , t} = useTranslation();
  const { loading: aboutUsLoading, data: aboutUs } = useSelector((state) => state.data.aboutUs);

  useEffect(() => {
    dispatch(fetchAbout())
    dispatch(fetchNumber())
    dispatch(fetchAboutUs());
  }, [i18n.language])


  return (
    <div>
      <Helmet>
        <title>{t('nbt')} | {t('about')}</title>
      </Helmet>
      <TitlePage title={t('about')} />
      {/* <section className={`${style.sectionBboutBg} bg-primary relative z-1 pt-20 pb-20`}>
        <div className="container">
          <Row>
            <Col span={24}>
              <div className="text-white font-size-24" dangerouslySetInnerHTML={{ __html: about?.data?.title }}></div>
            </Col>
          </Row>
        </div>
      </section> */}
      <section className="py-10 lg:py-20" id="aboutUs">
        <div className="container">
          <Row gutter={20}>
            <Col span={24} lg={12}>
              {aboutUsLoading ? <>
                <Skeleton className="mb-4" style={{ borderRadius: "6px" }} height={30} />
                <Skeleton className="mb-4" style={{ borderRadius: "6px" }} height={30} /></> :
                <>
                  <div className="text-center mb-12">
                    <h4 className={`${style.entryTitle} bg-primary text-white rounded-full inline-block font-size-20 font-bold px-5 py-1`}>{aboutUs?.title}</h4>
                  </div>
                  <div className="mt-10 mb-10" dangerouslySetInnerHTML={{ __html: aboutUs?.body }}></div>

                </>

              }

            </Col>
            <Col span={24} lg={12}>
              {aboutUsLoading ? <Skeleton style={{ borderRadius: "16px" }} height={400} /> : <Image preview={false} src={aboutUs?.image} />}
            </Col>
          </Row>
        </div>
      </section>
      <section className="py-10" style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
        <div className="container">
          <h2 className="font-bold text-white mb-4">{t('our_success_figures')}</h2>
          <div className="bg-primary-2 pt-5 pb-6 rounded-lg">
            
              {number?.loading ? 
              
              <Row className="px-5" gutter={20}>
                {window.innerWidth > 992 ? <>{Array.from({ length: 3 }).map((_, index) => (
                  <Col span={24} lg={8} md={12} key={index}>
                    <Skeleton baseColor="#000" height={100} />
                  </Col>
                ))}</> : <>{Array.from({ length: 1 }).map((_, index) => (
                  <Col span={24} lg={8} md={12} key={index}>
                    <Skeleton baseColor="#000" height={100} />
                  </Col>
                ))}</> }
                
              </Row>
              : 
              <Row>
              {number?.data?.content?.map((item)=>(
                <Col span={24} lg={8} key={item.id}>
                <div className="text-center">
                <img className="mb-4 mx-auto" src={item?.image} alt={item?.title} />
                <h2 className="text-white font-bold text-5xl mb-8">{item?.value}</h2>
                <h4 className="text-white">{item?.title}</h4>
                </div>
                </Col>
              ))}
              </Row>
            }
          </div>
        </div>
      </section>
      <section className="py-10  relative">
        <div className="container">
          <Row gutter={25} className="mb-8 lg:mb-5">
            <Col className="mb-3 lg:mb-0" span={24} md={8}>
              {about?.loading ? <Skeleton height={270} style={{ borderRadius: "15px" }} /> : <Image className={`${style.imageAbout}`} preview={false} src={about?.data?.vision_image} height={270} />}
            </Col>
            <Col span={24} lg={16}>
              <div className={`${style.contenAbout}`}>
                {about?.loading ? <div><Skeleton height={20} style={{ height: "100%" }} /> <Skeleton height={20} style={{ height: "100%", width: "50%" }} /></div> : <div className="text-about" dangerouslySetInnerHTML={{ __html: about?.data?.vision_body }}></div>}
              </div>
            </Col>
          </Row>
          <Row gutter={25} className="mb-8 lg:mb-5">
            <Col className="mb-3 lg:mb-0" span={24} lg={14}>
              <div className={`${style.contenAbout}`}>
                {about?.loading ? <div><Skeleton height={20} style={{ height: "100%" }} /> <Skeleton height={20} style={{ height: "100%", width: "50%" }} /></div> : <div className="text-about" dangerouslySetInnerHTML={{ __html: about?.data?.values_body }}></div>}
              </div>
            </Col>
            <Col span={24} lg={10}>
              {about?.loading ? <Skeleton height={270} style={{ borderRadius: "15px" }} /> : <Image className={`${style.imageAbout}`} preview={false} src={about?.data?.values_image} height={270} />}
            </Col>
          </Row>
          <Row gutter={25} className="mb-8 lg:mb-5">
            <Col className="mb-3 lg:mb-0" span={24} md={8}>
              {about?.loading ? <Skeleton height={270} style={{ borderRadius: "15px" }} /> : <Image className={`${style.imageAbout}`} preview={false} src={about?.data?.goals_image} height={270} />}
            </Col>
            <Col span={24} lg={16}>
              <div className={`${style.contenAbout}`}>
                {about?.loading ? <div><Skeleton height={20} style={{ height: "100%" }} /> <Skeleton height={20} style={{ height: "100%", width: "50%" }} /></div> : <div className="text-about" dangerouslySetInnerHTML={{ __html: about?.data?.goals_body }}></div>}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
};
export default About;