import { faArrowLeft, faArrowRight, faCheck, faEye, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Image, Pagination, Row, Spin, Tabs } from "antd";
import { fetchTourevents, fetchToureventsSingle } from "features/home/homeSlice";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
import ModalComponent from "components/Ui/ModalComponent";
import logo from 'public/images/logo.svg';
import { useLocation, useNavigate } from "react-router-dom";
import useAppParams from "hooks/useAppParams";
import bg from "public/images/bg-layer.png";
import TabPane from "antd/es/tabs/TabPane";
import { fetchFavorites } from "features/favorites/favoritesSlice";
import FavoriteToggle from "components/Shared/FavoriteToggle";
import SliderThumb from "components/Shared/SliderThumb";
import TestimonialSingle from "components/Shared/TestimonialSingle";
import EmptyData from "components/Shared/EmptyData";
import CustomLazyImage from "hooks/customLazyImage";

const Tourevent = ({ booking, onCardSelect, selectedCardId, favorable_type, numberCard, pagination, distance , dashboard }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const location = useLocation();
  const { loading: toureventsLoading, data: tourevents, pagination: paginations } = useSelector((state) => state.data.tourevents);
  const { loading: toureventsSingleLoading, data: toureventsSingle } = useSelector((state) => state.data.toureventsSingle);

  const [status5, setStatus5] = useState(1);
  const { UrlParams, handleSearch } = useAppParams({});

  const handleIncrement5 = () => setStatus5(status5 + 1);
  const handleDecrement5 = () => setStatus5(status5 > 1 ? status5 - 1 : 1);

  const [activeCard, setActiveCard] = useState(null);
  const { favorites } = useSelector((state) => state.favorites);



  useEffect(() => {
    if (favorable_type === "Tourevent" && UrlParams) {
      dispatch(
        fetchFavorites({
          params: {
            ...UrlParams,
            page: Number(UrlParams?.page ? UrlParams?.page : 1),
            favorable_type: favorable_type,
            perpage: numberCard,
          },
        })
      )
    } else if (UrlParams) {
      dispatch(fetchTourevents({
        params: { page: status5, perpage: numberCard },
        bookings: false
      }));
    }
  }, [status5, i18n.language, UrlParams])



  const showModal = (id) => {
    setIsModalVisible(true);
    dispatch(fetchToureventsSingle(id));
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };


  const handleCardClick = (id) => {
    setActiveCard(id);
    onCardSelect(id);
  };

  useEffect(() => {
    if (selectedCardId) {
      setActiveCard(selectedCardId); 
    }
  }, [selectedCardId]);


  const pageinate = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };


  const saveStorage = (id)=>{

    if(location.pathname === "/booking/step5"){
      setIsModalVisible(false);
      handleCardClick(id)
    }else{

      const storedData = localStorage.getItem("bookingFormData");
      const parsedData = storedData ? JSON.parse(storedData) : {};
      const updatedData = {
        ...parsedData, 
        tourevent_id: id,
      };
      localStorage.setItem("bookingFormData", JSON.stringify(updatedData));
      navigate("/booking/step1")
    }
  }
  
  return (
    <>
      {toureventsLoading || favorites.loading ? (
        <Row gutter={25}>
          {window.innerWidth > 992 ? <>{Array.from({ length: 3 }).map((_, index) => (
            <Col span={24} lg={8} md={12} key={index}>
              <div style={{ position: "relative", zIndex: "1", textAlign: "center" }}>
                <Skeleton style={{ borderRadius: "50%" }} height={240} width={240} />
              </div>
              <div
                style={{
                  borderRadius: "16px",
                  marginTop: "-55px",
                  marginBottom: "10px",
                }}
              >
                <Skeleton style={{ borderRadius: "16px" }} height={280} />
              </div>
              <Skeleton style={{ borderRadius: "10px" }} height={46} />
            </Col>
          ))}</> : <>{Array.from({ length: 1 }).map((_, index) => (
            <Col span={24} lg={8} md={12} key={index}>
              <div style={{ position: "relative", zIndex: "1", textAlign: "center" }}>
                <Skeleton style={{ borderRadius: "50%" }} height={240} width={240} />
              </div>
              <div
                style={{
                  borderRadius: "16px",
                  marginTop: "-55px",
                  marginBottom: "10px",
                }}
              >
                <Skeleton style={{ borderRadius: "16px" }} height={280} />
              </div>
              <Skeleton style={{ borderRadius: "10px" }} height={46} />
            </Col>
          ))}</> }
          
        </Row>
      ) : (
        <>
          {((tourevents?.length > 0 || favorites?.data?.content?.length > 0) ? (
            <Row gutter={25}>
              {(tourevents?.length > 0 ? tourevents : favorites?.data?.content || []).map((item) => (
                <Col span={24} lg={8} md={12} key={item.id}>
                  <div
                    className={`${style.widget_itemEvent} ${favorites?.data?.content ? 'mb-10' : distance ? 'mb-10' : 'mb-14 lg:mb-0'} relative text-center ${activeCard === item.id ? style.selected : ''} ${booking ? style.booking : ''}`}
                    onClick={() => (booking ? handleCardClick(item.id) : null)}
                  >
                    {localStorage.getItem("user") && (
                      <FavoriteToggle
                        cardId={item?.favorable?.id ? item?.favorable?.id : item.id} 
                        isFavorited={item?.isFavorited || item?.favorable?.isFavorited} 
                        loading={item.loading}
                        favorable_type={"App\\Models\\Tourevent"}
                        dashboard={dashboard}
                      />
                    )}

                    {booking && (
                      <div
                        className={`${activeCard === item.id ? style.checked : ''} ${style.widget_itemCheck} bg-white top-[15px] w-[30px] h-[30px] rounded-full absolute flex items-center justify-center font-size-26 z-2`}
                      >
                        {activeCard === item.id && <FontAwesomeIcon icon={faCheck} />}
                      </div>
                    )}

                    <div className={`${style.widget_itemImage}`}>
                      <CustomLazyImage
                        src={item?.favorable?.image || item?.image} 
                        alt={item?.favorable?.name || item?.name}
                        height={240}
                        width={240}
                      />
                    </div>

                    <div className={`${style.widget_itemContent} mb-2`}>
                      <h5 className="font-bold mb-4">{item?.favorable?.name || item?.name}</h5>
                      <h5 className={`${style.widget_itemText} mb-5`}>
                        {item?.favorable?.body || item?.body}
                      </h5>

                      {!booking && (
                        <Button onClick={() => showModal(item?.favorable?.id || item.id)} className="btn btn-primary p-3 rounded-md h-auto">
                          <FontAwesomeIcon icon={faEye} /> {t('more_read')}
                        </Button>
                      )}
                    </div>

                    <div className={`${style.widget_itemFooter} px-8`}>
                      {booking ? (
                        <Button onClick={(e) => {e.stopPropagation();  showModal(item?.favorable?.id || item.id)}} className="btn btn-primary w-full text-center font-size-20 block p-2 rounded-lg h-auto">
                          {t('more_read')}
                        </Button>
                      ) : (
                        <Button onClick={(e)=>{e.stopPropagation(); saveStorage(item.id)}} className="btn btn-primary-2 w-full text-center font-size-20 block p-2 rounded-lg h-auto">
                          {t('participate_event')}
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <EmptyData />
          ))}
        </>
      )}

      {!toureventsLoading ? <>
        {favorable_type ?
        <Pagination
          align="end"
          className="my-3"
          onChange={pageinate}
          defaultCurrent={1}
          current={UrlParams?.page ? Number(UrlParams?.page) : 1}
          total={paginations?.total || favorites?.data?.pagination?.total}
          pageSize={numberCard}
        />
        :


        <Row>
          <Col span={24}>
            <div className="flex justify-center items-center mt-10">
              <button
                onClick={handleDecrement5}
                disabled={status5 === 1 || toureventsLoading}
                className="prev-pageination bg-primary text-white w-12 h-12 rounded-tl-xl rounded-br-xl flex items-center justify-center mx-10 cursor-pointer"
              >
                <FontAwesomeIcon className="text-lg" icon={faArrowRight} />
              </button>
              <button
                onClick={handleIncrement5}
                disabled={tourevents?.pagination?.has_next === false || toureventsLoading}
                className="next-pageination bg-primary text-white w-12 h-12 rounded-tr-xl rounded-bl-xl flex items-center justify-center mx-10 cursor-pointer"
              >
                <FontAwesomeIcon className="text-lg" icon={faArrowLeft} />
              </button>
            </div>
          </Col>
        </Row>
      }
      
      </> : null}
      

      <ModalComponent width={1000}
        isVisible={isModalVisible}
        onClose={handleClose}
        title={false}
        footer={false}
      >
        <div className="cursor-pointer absolute font-bold top-9 border border-primary rounded-lg px-7 py-3  text-primary inline-flex items-center font-size-16" onClick={() => handleClose()} style={{ insetInlineStart: "25px", zIndex: "2" }}> <FontAwesomeIcon icon={faArrowRight} className="me-2" /> {t('back')}</div>
        <div className="text-center mb-5">
          <Image src={logo} alt="logo" height={80} preview={false} className="mx-auto" />
        </div>
        {toureventsSingleLoading ? <div className="text-center"><Spin /></div> : (
          <div className="p-4" >
            <h2 className="mb-5 font-bold">{t('detailed_information_about')}  <span className="text-primary">{toureventsSingle?.name} :</span></h2>

            <Tabs defaultActiveKey="1" centered className="tab-napt mb-6" style={{
              backgroundImage: `url(${bg})`,
              backgroundSize: '80%',
              backgroundPosition: 'center',
              backgroundRepeat: "no-repeat",
            }}>
              <TabPane tab={t('general_information')} key="1">
                <Row align="middle">
                  <Col span={24} lg={12} className="mb-5 lg:mb-0" dir="rtl">
                    <SliderThumb images={toureventsSingle?.images?.content} />
                  </Col>
                  <Col span={24} lg={12} className="lg:ps-6">
                    <div className="p-5 text-center">
                      <div className="flex items-center mb-4 lg:mb-9 justify-center">
                        <h2 className="w-[140px]">{t('event_name')}</h2>
                        <h2 className="font-bold text-primary">{toureventsSingle?.name}</h2>
                      </div>
                      <div className="flex items-center mb-4 lg:mb-9 justify-center">
                        <h4 className="w-[140px]">{t('event_venue')}</h4>
                        <h4 className="font-bold text-black">{toureventsSingle?.position}</h4>
                      </div>
                      <div className="flex items-center mb-4 justify-center">
                        <h4 className="w-[140px]">{t('event_time')}</h4>
                        <h4 className="font-bold text-black">{toureventsSingle?.at}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={t('event_description')} key="2">
                <div className="mb-4" style={{minHeight:"100px"}}>
                  <div dangerouslySetInnerHTML={{ __html: toureventsSingle?.description }} />
                </div>
              </TabPane>
              <TabPane tab={t('reviews')} key="3">
                <Row gutter={20}>
                  <Col span={24} className="mb-5 lg:mb-0">
                    <h6 className="text-primary mb-4 font-bold">{t('reviews')}</h6>
                    <TestimonialSingle testimonial={toureventsSingle?.testimonials?.content} limit={true} />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
            <Button onClick={()=>saveStorage(toureventsSingle.id)} className="h-auto w-full bg-primary-2 block rounded-lg py-3 font-bold font-size-18 text-white text-center">{t('select_venue')}</Button>
          </div>
        )}
      </ModalComponent>
    </>
  );
};

export default Tourevent;
