import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axiosApp from 'api';
import { setError } from 'features/error/errorSlice';

export const fetchCategoriesTicket = createAsyncThunk(
  'data/fetchCategoriesTicket',
  async (_, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`categories/ticket`);
      return { type: 'categories', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const postTickets = createAsyncThunk(
  'data/postTickets',
  async ({values , callback}, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.post(`tickets` , values);
      notification.success({
        message:response?.data?.message,
        duration:3
      });
      callback?.()
      return { type: 'postTickets', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchTickets = createAsyncThunk(
  'data/fetchTickets',
  async (_, { rejectWithValue , dispatch }) => {
    try {
      const response = await axiosApp.get(`tickets`);
      return { type: 'tickets', data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.data || error.response?.data?.message || error?.message;
      dispatch(setError({ message: errorMessage })); 
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial State
const initialState = {
  categories:{
    loading: false,
    data: [], 
    error: null,
  },
  postTicket:{
    loading: false,
    error: null,
  },
  tickets:{
    loading: false,
    data: [], 
    error: null,
  },
};

// Slice
const dataSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesTicket.pending, (state) => {
        state.categories.loading = true;
      })
      .addCase(fetchCategoriesTicket.fulfilled, (state, action) => {
        state.categories.loading = false;
        state.categories.data = action.payload.data.data;
      })
      .addCase(fetchCategoriesTicket.rejected, (state, action) => {
        state.categories.loading = false;
        state.categories.error = action.payload;
      })
      // postTickets
      .addCase(postTickets.pending, (state) => {
        state.postTicket.loading = true;
      })
      .addCase(postTickets.fulfilled, (state, action) => {
        state.postTicket.loading = false;
      })
      .addCase(postTickets.rejected, (state, action) => {
        state.postTicket.loading = false;
        state.postTicket.error = action.payload;
      })
      // Tickets
      .addCase(fetchTickets.pending, (state) => {
        state.tickets.loading = true;
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        state.tickets.loading = false;
        state.tickets.data = action.payload.data.data;
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.tickets.loading = false;
        state.tickets.error = action.payload;
      })
      ;
  },
});

export default dataSlice.reducer;
