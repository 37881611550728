import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Select, Image, Tabs, TimePicker } from "antd";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import StepsIndicator from "./StepsIndicator.jsx";
import arrowLeft from 'public/images/arrow-left.svg'
import arrowRight2 from 'public/images/arrow-right2.svg'
import { useTranslation } from "react-i18next";
import ModalComponent from "components/Ui/ModalComponent/index.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import logo from 'public/images/logo.svg';
import bg from "public/images/bg-layer.png";
import { useDispatch, useSelector } from "react-redux";
import { postWheelies, postRestaurants } from "features/booking/bookingSlice.js";
import { Helmet } from "react-helmet";
const { TabPane } = Tabs;
const Step4 = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const initialData = location.state || {};
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState();
  const dispatch = useDispatch();
  const { postWheelie, postRestaurant } = useSelector((state) => state.booking);


  useEffect(() => {
    const savedData = localStorage.getItem("bookingFormData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      form.setFieldsValue({
        room_type: parsedData.room_type || undefined,
        num_rooms: parsedData.num_rooms || "",
        num_meals: parsedData.num_meals || "",
        vehicle_type: parsedData.vehicle_type || undefined,
        food_allergies: parsedData.food_allergies || undefined,
      });
    }
  }, [initialData, form]);

  const onFinish = (values) => {
    const storedData = localStorage.getItem("bookingFormData");
    const parsedData = storedData ? JSON.parse(storedData) : {};

    const updatedData = {
      ...parsedData,
      ...initialData,
      ...values,
    };
    localStorage.setItem("bookingFormData", JSON.stringify(updatedData));
    navigate("/booking/step5", { state: updatedData });
  };


  const showModal = (id) => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const onFinishWheelies = (values) => {
    values = {
      ...values,
      user_id: JSON.parse(localStorage.getItem("user")).id,
      start_time: values.start_time.format("HH:mm"),

    }
    dispatch(postWheelies({values , callback:()=>{
      form2.resetFields();
    }}));
  };

  const onFinishRestaurants = (values) => {
    dispatch(postRestaurants({values , callback:()=>{
      form3.resetFields();
    }}));
  };


  if (!localStorage.getItem("bookingFormData")) {
    return <Navigate to="/booking/step1" />
  }
  else if (!JSON.parse(localStorage.getItem("bookingFormData")).accommodation_id || !JSON.parse(localStorage.getItem("bookingFormData")).edit) {
    return <Navigate to="/booking/step3" />
  }

  return (
    <>
    <Helmet>
      <title>{t('nbt')} | {t('customize_your_travel_package')}</title>
    </Helmet>
    <section className="py-8">
      <div className="container">
        <StepsIndicator currentStep={3} />
        <h2 className="text-primary-2 font-size-30 font-bold mb-3"> {t('select_trip_needs')}: </h2>
        <h3 className="mb-2">{t('you_select_trip_needs_from')}</h3>
        <ul className="flex flex-wrap mb-5">
          <li className="me-7"><i className="icon-car me-2 text-primary"></i> <span>{t('transportation')}</span></li>
          <li className="me-7"><i className="icon-food me-2 text-primary"></i> <span>{t('food')}</span></li>
          <li className="me-7"><i className="icon-guest-room me-2 text-primary"></i> <span>{t('bedrooms')}</span></li>
        </ul>
        <Form form={form} layout="vertical" size="large" onFinish={onFinish}>
          <Row gutter={30}>
            <Col span={24} lg={12}>
              <Form.Item
                label={t('room_type')}
                name="room_type"
                rules={[{ required: true, message: t('field_required') }]}
              // rules={[{ required: true, message: "Please enter your age" }]}
              >
                <Select placeholder={t('select_room_type_whether_single_double')}>
                  <Select.Option value="SINGLE">{t('single')}</Select.Option>
                  <Select.Option value="DOUBLE">{t('double')}</Select.Option>
                  <Select.Option value="SUITE">{t('suite')}</Select.Option>
                  <Select.Option value="FAMILY">{t('family')}</Select.Option>
                  <Select.Option value="OTHER">{t('other')}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label={t('number_rooms')}
                name="num_rooms"
                rules={[{ required: true, message: t('field_required') },
                {
                  type: "number",
                  message: t('enter_valid_number'),
                  transform: (value) => (value ? Number(value) : undefined), // Transform input to a number
                },
                ]}
              >
                <Input placeholder={t('select_number_rooms_want_book_trip')} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label={t('choose_appropriate_car')}
                name="vehicle_type"
                rules={[{ required: true, message: t('field_required') }]}
              >
                <Select placeholder={t('choose_appropriate_car_2')}>
                  <Select.Option value="CAR">{t('car')}</Select.Option>
                  <Select.Option value="BUS">{t('bus')}</Select.Option>
                  <Select.Option value="VAN">{t('van')}</Select.Option>
                  <Select.Option value="MOTORCYCLE">{t('motorcycle')}</Select.Option>
                  <Select.Option value="OTHER">{t('other')}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} lg={12}></Col>
            <Col span={24} lg={12}>
              <Form.Item
                label={t('number_meals')}
                name="num_meals"
              >
                <Input placeholder={t('number_meals_2')} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label={t('do_you_have_food_allergy')}
                name="food_allergies"
                rules={[{ required: true, message: t('field_required') }]}
              >
                <Select placeholder={t('if_have_food_allergy_avoid_food_harms_you_2')}>
                  <Select.Option value="0">{t('no')}</Select.Option>
                  <Select.Option value="1">{t('yes')}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {localStorage.getItem("user") ?
            <Row>
              <Col span={24}>
                <div className="flex items-center justify-between">
                  <h5>{t('if_you_want_other_options')}</h5>
                  <Button onClick={showModal} className="py-3 px-12 h-auto rounded-lg" type="primary">{t('customize_needs')}</Button>
                </div>
              </Col>
            </Row>
            : null}
          <Row justify="center" className="mt-9">
            <Col span={24} lg={12}>
              <div className="flex items-center justify-around w-full">
                <Form.Item>
                  <Button className="text-primary border-primary font-bold px-8 py-3 h-auto font-size-18"
                    onClick={() => navigate("/booking/step3")}
                  >
                    <Image src={arrowRight2} alt="arrowLeft" preview={false} className="me-1 translate-scale-1-ltr" />
                    {t('prev')}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button className="bg-primary-2 text-white font-bold px-8 py-3 h-auto font-size-18" htmlType="submit">
                    {t('next')}
                    <Image src={arrowLeft} alt="arrowLeft" preview={false} className="ms-1 translate-scale-1-ltr" />
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>


      <ModalComponent width={1000}
        isVisible={isModalVisible}
        onClose={handleClose}
        title={false}
        footer={false}
      >
        <div className="cursor-pointer absolute font-bold top-9 border border-primary rounded-lg px-7 py-3  text-primary inline-flex items-center font-size-16" onClick={() => handleClose()} style={{ insetInlineStart: "25px", zIndex: "2" }}> <FontAwesomeIcon icon={faArrowRight} className="me-2" /> {t('back')}</div>
        <div className="text-center mb-5">
          <Image src={logo} alt="logo" height={80} preview={false} className="mx-auto" />
        </div>
        <h2 className="text-primary font-bold text-center">{t('customize_needs_2')}</h2>
        <Tabs
          defaultActiveKey="1" centered className="tab-napt p-6 pb-0" style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: '80%',
            backgroundPosition: 'center',
            backgroundRepeat: "no-repeat",
          }}>
          <TabPane tab={t('luxury_car_reservation_request')} key="1">
            <h4 className="font-bold text-primary mt-4 mb-4"> {t('fill_following_information_request_needs_desire_trip')} :</h4>
            <Form form={form2} layout="vertical" size="large" onFinish={onFinishWheelies}>
              <Form.Item
                label={t('car_description')}
                name="car_description"
                rules={[{ required: true, message: t('field_required') }]}
              >
                <Input placeholder={t('car_description_2')} />
              </Form.Item>
              <Form.Item
                label={t('booking_duration')}
                name="booking_duration"
                rules={[{ required: true, message: t('field_required') },
                ]}
              >
                <Input placeholder={t('hours_days_weeks')} />
              </Form.Item>
              <Form.Item
                label={t('start_time')}
                name="start_time"
                rules={[{ required: true, message: t('field_required') }]}
              >
                <TimePicker placeholder={t('start_time')} style={{ width: '100%' }} use12Hours />
              </Form.Item>
              <Form.Item
                label={t('car_pickup_location')}
                name="pickup_location"
                rules={[{ required: true, message: t('field_required') }]}
              >
                <Input placeholder={t('car_pickup_location')} />
              </Form.Item>
              <Form.Item
                label={t('car_drop_location')}
                name="dropoff_location"
                rules={[{ required: true, message: t('field_required') }]}
              >
                <Input placeholder={t('car_drop_location')} />
              </Form.Item>
              <Form.Item>
                <Button loading={postWheelie.loading} block className="bg-primary-2 text-white font-bold px-8 py-3 h-auto font-size-18" htmlType="submit">
                {t('send')}
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab={t('reservation_request_certain_restaurant')} key="2">
            <h4 className="font-bold text-primary mt-4 mb-4"> {t('fill_following_information_request_needs_desire_trip')} :</h4>
            <Form form={form3} layout="vertical" size="large" onFinish={onFinishRestaurants}>
              <Row gutter={20}>
                <Col span={24} lg={12}>
                  <Form.Item
                    label={t('choosing_food_want_restaurant')}
                    name="restaurant_name"
                    rules={[{ required: true, message: t('field_required') }]}
                  >
                    <Input placeholder={t('choosing_food_want_restaurant_2')} />
                  </Form.Item>
                </Col>
                <Col span={24} lg={12}>
                  <Form.Item
                    label={t('select_specific_restaurant_book')}
                    name="desired_food"
                    rules={[{ required: true, message: t('field_required') },
                    ]}
                  >
                    <Input placeholder={t('select_specific_restaurant_book_2')} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={t('choose_time_date_would_like_book_restaurant')}
                name="reservation_time"
                rules={[{ required: true, message: t('field_required') }]}
              >
                <TimePicker placeholder={t('hours_days_weeks')} style={{ width: '100%' }} use12Hours />
              </Form.Item>
              <Form.Item>
                <Button loading={postRestaurant.loading} block className="bg-primary-2 text-white font-bold px-8 py-3 h-auto font-size-18" htmlType="submit">
                {t('send')}
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </ModalComponent>
    </section>
    </>
  );
};

export default Step4;
