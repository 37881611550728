import { Breadcrumb, Col, Row } from 'antd';
import SliderThumb from 'components/Shared/SliderThumb';
import TestimonialSingle from 'components/Shared/TestimonialSingle';
import { fetchAccommodationSingle } from 'features/home/homeSlice';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TitlePage from 'components/Shared/TitlePage';
import DetailsAccommodation from 'components/Shared/Accommodation/details';
import Skeleton from 'react-loading-skeleton';
const AccommodationSingle = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const { loading: accommodationsSingleLoading, data: accommodationSingle } = useSelector((state) => state.data.accommodationSingle);

  useEffect(() => {
    dispatch(fetchAccommodationSingle(id));
  }, [])


  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('accommodations')}</title>
      </Helmet>
      <TitlePage title={t('accommodations')} bg={true} />
      <section className='py-14'>
        <div className='container'>
          <Breadcrumb className='font-size-20'>
            <Breadcrumb.Item>
              <Link to="/">
                <i className='icon-home me-2'></i>
                {t('home')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/booking/step3">
                {t('accommodations')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{accommodationSingle?.name}</Breadcrumb.Item>
            {/* <Breadcrumb.Item>وجهة الطنطورة السياحية</Breadcrumb.Item> */}
          </Breadcrumb>
          {accommodationsSingleLoading ? <div className='mt-5'><Skeleton height={30} className="mb-4" style={{ width: "50%" }} /> <Skeleton height={30} className="mb-4" /></div> : <>

            <Row align="middle">
              <Col span={24} lg={12} className="mb-5 lg:mb-0" dir="rtl">
                <SliderThumb images={accommodationSingle?.images?.content} />
              </Col>
              <Col span={24} lg={12} className="lg:ps-6">
                <div className="p-5 text-center">
                  <DetailsAccommodation wifi={accommodationSingle?.data?.wifi} rooms={accommodationSingle?.data?.rooms}
                    restaurants={accommodationSingle?.data?.restaurants} cafes={accommodationSingle?.data?.cafes}
                    type={accommodationSingle?.data?.type} rating={accommodationSingle?.data?.rating}
                  />
                  {/* <div className="flex items-center mb-5 lg:mb-7">
                <Image src={wifi} alt="wifi" />
                <h5 className="ms-4">{Number(accommodationSingle?.data?.wifi) === 0 ? t('wifi_not_free_everywhere') : t('wifi_free_everywhere')}</h5>
              </div>
              <div className="flex items-center mb-5 lg:mb-7">
                <Image src={room} alt="room" />
                <h5 className="ms-3 w-[130px]">{t('number_rooms')}   </h5>
                <div className="flex items-center justify-between">
                  <h4 className="me-3">{accommodationSingle?.data?.rooms}</h4>
                  <h5>{t('rooms')} </h5>
                </div>
              </div>
              <div className="flex items-center mb-5 lg:mb-7">
                <Image src={food} alt="fluent_food" />
                <h5 className="ms-3 w-[130px]">{t('number_restaurants')}   </h5>
                <div className="flex items-center justify-between">
                  <h4 className="me-3">{accommodationSingle?.data?.restaurants}</h4>
                  <h5>{t('restaurants')} </h5>
                </div>
              </div>
              <div className="flex items-center mb-5 lg:mb-7">
                <Image src={coffee} alt="coffee" />
                <h5 className="ms-3 w-[130px]">{t('number_cafes')}   </h5>
                <div className="flex items-center justify-between">
                  <h4 className="me-3">{accommodationSingle?.data?.cafes}</h4>
                  <h5>{t('cafes')} </h5>
                </div>
              </div>
              <div className="flex items-center mb-5 lg:mb-7">
                <h5>{t('category')}   </h5>
                <h4>
                  {accommodationSingle?.data?.type === 'HOTEL' ? 'المنتجعات' :
                    accommodationSingle?.data?.type === 'CARAVAN' ? 'الشاليهات' :
                      accommodationSingle?.data?.type === 'CHALET' ? 'الفنادق' :
                        accommodationSingle?.data?.type === 'CAMP' ? 'كرفانات' :
                          accommodationSingle?.data?.type === 'RESORT' ? 'خيام' : null}
                </h4>
              </div>
              <div className="flex items-center mb-5 lg:mb-7">
                <h5>{t('rating')}   </h5>
                <h4>{accommodationSingle?.data?.rating} <FontAwesomeIcon icon={faStar} className="text-primary" /> </h4>
              </div> */}
                </div>
              </Col>
            </Row>
            <Row align="middle">
              <Col span={24} className="mb-5">
                <div className="mb-4" style={{ minHeight: "100px" }}>
                  <div dangerouslySetInnerHTML={{ __html: accommodationSingle?.description }} />
                </div>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24} className="mb-5 lg:mb-0">
                <h6 className="text-primary mb-4 font-bold">{t('reviews')}</h6>
                <TestimonialSingle testimonial={accommodationSingle?.testimonials?.content} limit={true} />
              </Col>
            </Row>
          </>}

        </div>
      </section>
    </>

  )
}

export default AccommodationSingle