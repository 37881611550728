import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Col, Row } from "antd";
import { faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "features/profile/profileSlice";
import style from "./index.module.scss"
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
const Profile = ({ userDashboard }) => {
  const { profile } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { t , i18n } = useTranslation();

  useEffect(() => {
    dispatch(fetchProfile());
  }, [i18n.language]);

  return (
    <div className={`${userDashboard ? style.userDashboard : ''}`}>
      <Row className="mb-8">
        <Col span={24}>
          <div className={`bg-light-primary p-5`}>
            {profile.loading ?
              <>
                <Skeleton height={20} className="mb-3" style={{ width: "20%" }} />
                <Skeleton height={20} className="mb-3" style={{ width: "50%" }} />
                <Skeleton height={20} className="mb-3" />
              </>
              : <>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="flex items-center">
                      <Avatar src={profile?.data?.image} alt={profile?.data?.name} size={70} className="border-primary border-2" />
                      <h2 className="font-bold ms-4 font-size-30">{t('user_id_card')}</h2>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} lg={20}>
                    <Row>
                      <Col className="mb-5" span={24} lg={12}>
                        <Row>
                          <Col span={10}>
                            <div className="flex items-center">
                              <FontAwesomeIcon icon={faUser} className="text-primary me-3 font-size-16" /><h2>{t('user_name')}</h2>
                            </div>
                          </Col>
                          <Col span={14}><h2 className="text-primary-2">{profile?.data?.name}</h2></Col>
                        </Row>
                      </Col>
                      <Col className="mb-5" span={24} lg={12}>
                        <Row>
                          <Col span={10}>
                            <div className="flex items-center">
                              <FontAwesomeIcon icon={faEnvelope} className="text-primary me-3 font-size-16" /><h2>{t('email')}</h2>
                            </div>
                          </Col>
                          <Col span={14}><h2 className="text-primary-2">{profile?.data?.email}</h2></Col>
                        </Row>
                      </Col>
                      <Col className="mb-5" span={24} lg={12}>
                        <Row>
                          <Col span={10}>
                            <div className="flex items-center">
                              <FontAwesomeIcon icon={faPhone} className="text-primary me-3 font-size-16" /><h2>{t('phone')}</h2>
                            </div>
                          </Col>
                          <Col span={14}><h2 className="text-primary-2 font-bold">{profile?.data?.phone}</h2></Col>
                        </Row>
                      </Col>
                      {!userDashboard && profile?.data?.age ?
                        <Col className="mb-5" span={24} lg={12}>
                          <Row>
                            <Col span={10}>
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faPhone} className="text-primary me-3 font-size-16" /><h2>{t('user_age')}</h2>
                              </div>
                            </Col>
                            <Col span={14}><h2 className="text-primary-2 font-bold">{profile?.data?.age}</h2></Col>
                          </Row>
                        </Col>
                        : null}
                      {profile?.data?.address ? <Col className="mb-5" span={24} lg={12}>
                        <Row>
                          <Col span={10}>
                            <div className="flex items-center">
                              <FontAwesomeIcon icon={faPhone} className="text-primary me-3 font-size-16" /><h2>{t('address')}</h2>
                            </div>
                          </Col>
                          <Col span={14}><h2 className="text-primary-2 font-bold">{profile?.data?.address}</h2></Col>
                        </Row>
                      </Col> : null}
                      
                      {!userDashboard && profile?.data?.job ?
                        <Col className="mb-5" span={24} lg={12}>
                          <Row>
                            <Col span={10}>
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faPhone} className="text-primary me-3 font-size-16" /><h2>{t('jop')} </h2>
                              </div>
                            </Col>
                            <Col span={14}><h2 className="text-primary-2 font-bold">{profile?.data?.job}</h2></Col>
                          </Row>
                        </Col> : null}

                    </Row>
                  </Col>
                  <Col span={24} lg={4}>
                    {/* qrCode */}
                  </Col>
                </Row>
              </>}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
