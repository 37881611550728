import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Col, DatePicker, Form, Input, notification, Row, Select, Tabs, Upload } from "antd";
import { useEffect, useState } from "react";
import { fetchProfile, fetchCountries, fetchCountriesSingle, sendUpdate_user_address, updateProfilePicture, sendUpdateProfile } from "features/profile/profileSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import FormItem from "antd/es/form/FormItem";
import Skeleton from "react-loading-skeleton";
import { Navigate } from "react-router-dom";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
const { TabPane } = Tabs;
function Profile() {
  const dispatch = useDispatch();
  const { profile, countries, countriesSingle, update_user_address, profilePicture, updateProfile } = useSelector((state) => state.profile);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [avatarUrl, setAvatarUrl] = useState();
  const { t ,  i18n} = useTranslation();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchCountries());
  }, [i18n.language]);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(fetchCountriesSingle({ countryId: selectedCountry }));
    }
  }, [selectedCountry]);


  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };



  useEffect(() => {
    if (profile) {
      setAvatarUrl(profile?.data?.image)
      form.setFieldsValue({
        name: profile?.data?.name || "",
        email: profile?.data?.email || "",
        job: profile?.data?.job || "",
        address: profile?.data?.address || "",
        phone: profile?.data?.phone || "",
        prefix: profile?.data?.prefix || "",
        dob: profile?.data?.dob ? dayjs(profile?.data?.dob) : null,
      });
      form2.setFieldsValue({
        country_id: profile?.data?.country?.id || "",
        city_id: profile?.data?.city?.id || "",
        address: profile?.data?.address || "",
      });
      setSelectedCountry(profile?.data?.country?.id);
      setSelectedCity(profile?.data?.city?.id);
    }
  }, [profile]);



  const handleUploadChange = (info) => {
    if (info.file.status === "uploading") {
      setIsUploading(true);
      return;
    }

    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("image", info.file.originFileObj);
      dispatch(updateProfilePicture(formData))
        .then((response) => {
          setIsUploading(false);
          if (response?.error) {
            notification.error({
              description: response.payload.data,
              duration: 3,
            });
          } else {
            notification.success({
              description: response.payload.data.message,
              duration: 3,
            });

            const newImageUrl = response?.payload?.data?.data?.image;
            if (newImageUrl) {
              setAvatarUrl(newImageUrl);
            }

            let storedUser = localStorage.getItem('user');
            if (storedUser) {
              let userData = JSON.parse(storedUser);
              userData.image = newImageUrl;
              localStorage.setItem("user", JSON.stringify(userData));
            }

          }
        })
        .catch((response) => {
          setIsUploading(false);
          notification.error({
            description: response.payload.data,
            duration: 3,
          });
        });
    }
  };



  const onFinish = (values) => {
    values = {
      ...values,
      city_id: selectedCity
    }
    dispatch(sendUpdate_user_address({
      values, callback: (response) => {
        notification.success({
          description: response.message,
          duration: 3,
        });
      },
    }));
  };


  const onFinishProfile = (values) => {
    values = {
      ...values,
      dob: values.dob ? values.dob.format("YYYY-MM-DD") : null,
    }


    dispatch(sendUpdateProfile({
      values, callback: (response) => {
        notification.success({
          description: response.message,
          duration: 3,
        });
      },
    }));
  };

  if (!localStorage.getItem("user")) {
    return <Navigate to="/signin" />
  }

  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('profile')}</title>
      </Helmet>
      <section className={`relative z-1 pt-20 pb-20`}>
        <div className="container">
          <Row className="mb-8">
            <Col span={24}>
              <div className="flex items-center">
                <FontAwesomeIcon className="text-gray-500" icon={faUser} />
                <h2 className="ms-2 text-gray-500">{t('profile')}</h2>
              </div>
            </Col>
          </Row>
          <Row className="mb-8">
            <Col span={24}>
              <div className="flex items-center">
                <Upload disabled={profilePicture?.loading}
                  showUploadList={false}
                  onChange={handleUploadChange}
                  accept="image/*"
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
                >
                  <div className="relative cursor-pointer">
                    {isUploading || profilePicture?.loading ? <Skeleton width={150} height={150} style={{ borderRadius: "50%" }} /> : <Avatar shape="circle" src={avatarUrl} size={150} />}

                    <FontAwesomeIcon
                      icon={faEdit}
                      className="absolute top-4 right-0 text-white bg-primary rounded-full p-2"
                    />
                  </div>
                </Upload>
                <div className="ms-3">
                  <h4 className="text-black font-bold">{profile?.data?.name}</h4>
                  <h4 className="text-gray-500">{profile?.data?.email}</h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Tabs defaultActiveKey="1" className="tab-napt">
                <TabPane tab={t('personal_data')} key="1" >
                  <Form
                    form={form}
                    size="large"
                    name="basic"
                    labelCol={{
                      span: 24,
                    }}
                    onFinish={onFinishProfile}
                    autoComplete="off"
                  >

                    <Row gutter={20}>
                      <Col span={24} lg={24}>
                        <div className="text-end">
                          <Form.Item className="mb-0 mt-4">
                            <Button
                              type="primary"
                              loading={updateProfile?.loading}
                              htmlType="submit"
                              className="py-3 rounded-lg font-bold"
                            >
                              {t('edit_data')}
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={24} lg={12}>
                        <Form.Item
                          label={t('name')}
                          name="name"
                        >
                          <Input suffix={profile?.loading && <FontAwesomeIcon icon={faSpinner} style={{ color: "#B57134" }} spin />} placeholder={t('name')} />
                        </Form.Item>
                      </Col>
                      <Col span={24} lg={12}>
                        <Form.Item
                          label={t('date_of_birth')}
                          name="dob"
                        >
                          <DatePicker suffixIcon={profile?.loading && <FontAwesomeIcon icon={faSpinner} style={{ color: "#B57134" }} spin />} placeholder={t('date_of_birth')} style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col span={24} lg={12}>
                        <Form.Item
                          label={t('email')}
                          name="email"
                        >
                          <Input suffix={profile?.loading && <FontAwesomeIcon icon={faSpinner} style={{ color: "#B57134" }} spin />} placeholder={t('email')} />
                        </Form.Item>
                      </Col>
                      <Col span={24} lg={12}>
                        <Row gutter={10}>
                          <Col span={8} lg={6}>
                            <Form.Item
                              label={t('phone')}
                              name="prefix"
                              rules={[{ required: true, message: t('field_required') }]}
                            >
                              <Select
                                placeholder={t('country')}
                                loading={countries?.loading}
                                // disabled={countries?.loading}
                                style={{ width: '100%' }}
                              >
                                {countries?.data?.content?.length > 0 ? (
                                  countries.data.content.map((country) => (
                                    <Select.Option key={country?.id} value={country?.prefix}>
                                      <span className="flex items-center">

                                        <img
                                          src={country?.flag}
                                          alt={`${country?.name} flag`}
                                          style={{
                                            width: '20px',
                                            height: '15px',
                                            marginInlineEnd: '8px',
                                            verticalAlign: 'middle',
                                          }}
                                        />
                                        {country.prefix}
                                      </span>
                                    </Select.Option>
                                  ))
                                ) : null}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={16} lg={18}>
                            <Form.Item
                              className="mt-[47px]"
                              label=""
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: t('field_required'),
                                },
                                {
                                  type: "number",
                                  message: t('eter_valid_number'),
                                  transform: (value) => (value ? Number(value) : undefined),
                                },
                              ]}
                            >
                              <Input suffix={profile?.loading && <FontAwesomeIcon icon={faSpinner} style={{ color: "#B57134" }} spin />} placeholder={t('phone')} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t('jop')}
                          name="job"
                        >
                          <Input suffix={profile?.loading && <FontAwesomeIcon icon={faSpinner} style={{ color: "#B57134" }} spin />} placeholder={t('jop')} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
                <TabPane tab={t('residential_data')} key="2">
                  <Form
                    form={form2}
                    size="large"
                    name="basic"
                    labelCol={{
                      span: 24,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                  >

                    <Row gutter={20}>
                      <Col span={24} lg={24}>
                        <div className="text-end">
                          <Form.Item className="mb-0 mt-4">
                            <Button
                              type="primary"
                              loading={update_user_address?.loading}
                              htmlType="submit"
                              className="py-3 rounded-lg font-bold"
                            >
                              {t('edit_data')}
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={24} lg={12}>
                        <Form.Item
                          label={t('country')}
                          name="country_id"
                          rules={[{ required: true, message: t('field_required') }]}
                        >
                          <Select
                            placeholder={t('country')}
                            onChange={handleCountryChange}
                            value={selectedCountry}
                            loading={countries?.loading}
                            disabled={countries?.loading}
                            style={{ width: '100%' }}
                          >
                            {countries?.data?.content?.length > 0 ? (
                              countries.data.content.map((country) => (
                                <Select.Option key={country?.id} value={country?.id}>
                                  {country.name}
                                </Select.Option>
                              ))
                            ) : null}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24} lg={12}>
                        <FormItem
                          label={t('city')}
                          name="city_id"
                          rules={[{ required: true, message: t('field_required') }]}
                        >
                          <Select
                            placeholder={t('city')}
                            onChange={handleCityChange}
                            value={selectedCity}
                            loading={countriesSingle?.loading}
                            style={{ width: '100%' }}
                            disabled={!selectedCountry}
                          >
                            {countriesSingle?.data?.cities?.length > 0 ? (
                              countriesSingle.data.cities.map((city) => (
                                <Select.Option key={city.id} value={city.id}>
                                  {city.name}
                                </Select.Option>
                              ))
                            ) : null}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t('address')}
                          name="address"
                        >
                          <Input suffix={profile?.loading && <FontAwesomeIcon icon={faSpinner} style={{ color: "#B57134" }} spin />} placeholder={t('address')} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </section>
    </>

  );
}

export default Profile;
