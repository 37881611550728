import { Image } from "antd";
import { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import placeholder from "public/images/placeholder.png";
import placeholderHero from "public/images/placeholder-hero.png";

const CustomLazyImage = ({ src, alt, height, width, hero }) => {
  const [loaded, setLoaded] = useState(false);
  const [compressedSrc, setCompressedSrc] = useState(null);

  useEffect(() => {
    const compressImage = async () => {
      if (!src || typeof src !== "string") {
        console.error("❌ Invalid image source:", src);
        setCompressedSrc(src); // استخدم الصورة الأصلية عند الفشل
        return;
      }

      try {
        const img = new window.Image();
        img.crossOrigin = "anonymous";
        img.src = src;

        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          canvas.toBlob(async (blob) => {
            if (!blob) {
              console.error("❌ Failed to create blob from image");
              setCompressedSrc(src);
              return;
            }

            try {
              const compressedBlob = await imageCompression(blob, {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
              });

              const compressedUrl = URL.createObjectURL(compressedBlob);
              setCompressedSrc(compressedUrl);
            } catch (compressionError) {
              console.error("❌ Error compressing image:", compressionError);
              setCompressedSrc(src); // استخدم الصورة الأصلية عند الفشل
            }
          }, "image/jpeg");
        };

        img.onerror = () => {
          console.error("❌ Error loading image:", src);
          setCompressedSrc(src);
        };
      } catch (error) {
        console.error("❌ Unexpected error:", error);
        setCompressedSrc(src);
      }
    };

    compressImage();
  }, [src]);

  return (
    <div style={{ width: width ? width : '', margin: "auto" }}>
      {!loaded && (
        <img
          src={hero ? placeholderHero : placeholder}
          alt={alt}
          style={{
            height: height,
            width: width,
            objectFit: "cover",
            backgroundColor: "#f0f0f0",
          }}
        />
      )}

      <Image
        src={compressedSrc || src} // استخدم الصورة المضغوطة إذا كانت متاحة، وإلا استخدم الأصلية
        alt={alt}
        preview={false}
        style={{
          display: loaded ? "block" : "none",
          objectFit: "cover",
          height: height,
          width: width,
        }}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

export default CustomLazyImage;