import TitlePage from "components/Shared/TitlePage";
import { Avatar, Col, Image, Pagination, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import style from './index.module.scss';
import { fetchResentBlogs } from "features/blog/blogSlice";
import Skeleton from 'react-loading-skeleton'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Filter from "components/Shared/Destinations/filter";
import EmptyData from "components/Shared/EmptyData";
import useAppParams from "hooks/useAppParams";

export default function Blogs() {
  const dispatch = useDispatch();
  const { resentBlogs } = useSelector((state) => state.blogs);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { handleSearch, UrlParams } = useAppParams({});



  useEffect(() => {
    // dispatch(fetchBlogs());
    if(UrlParams){
      dispatch(fetchResentBlogs(
        {
          params:{
            ...UrlParams,
            page: Number(UrlParams?.page || 1),
            order_by: "created_at",
            order_by_direction: "desc",
            perpage:9
          }
        }
      ));
    }
  }, [i18n.language , UrlParams])



  const pageinate = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };


  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('blog')}</title>
      </Helmet>
      <TitlePage title={t('blog')} />
      <div className={`${style.pageHeader} bg-primary py-2 mt-12`}>
        <h2 className="font-size-24 font-bold text-white text-center">{t('latest_posts')}</h2>
      </div>
      <section className="relative py-6">
        <div className="container">
          <Filter filterBlog={true} />
          {resentBlogs?.loading ? (
            <div className="px-2">
              <Row gutter={20}>
                {window.innerWidth > 992 ? <>{Array.from({ length: 3 }).map((_, index) => (
                  <Col span={24} lg={8} md={12} key={index}>
                    <div className={`${style.widget_itemBlog}`}>
                      <Skeleton height={260} />
                      <div className="px-6 py-9 border-t-0 border-4">
                        <Skeleton className="mb-3" style={{ borderRadius: "5px" }} height={33} />
                        <Skeleton style={{ borderRadius: "5px" }} height={84} />
                      </div>
                    </div>
                  </Col>
                ))}</> : <>{Array.from({ length: 1 }).map((_, index) => (
                  <Col span={24} lg={8} md={12} key={index}>
                    <div className={`${style.widget_itemBlog}`}>
                      <Skeleton height={260} />
                      <div className="px-6 py-9 border-t-0 border-4">
                        <Skeleton className="mb-3" style={{ borderRadius: "5px" }} height={33} />
                        <Skeleton style={{ borderRadius: "5px" }} height={84} />
                      </div>
                    </div>
                  </Col>
                ))}</> }
                
              </Row>
            </div>

          ) : resentBlogs?.data?.content?.length > 0 ? (
            <Row gutter={27}>
              {resentBlogs.data.content.map((blog) => (
                <Col span={24} lg={8} md={12}>
                  <div key={blog.id} className={`${style.widget_itemBlog} rounded-md relative`}>
                    <div className="absolute top-4 flex items-center flex-wrap" style={{ insetInlineEnd: "15px", zIndex: "2" }}>
                      {blog?.categories?.map((item) => (
                        <div className={`${style.widget_itemCategory} text-white ms-2 border border-white rounded-lg px-3 py-2 font-size-13`}>{item?.name}</div>
                      ))}
                      {/* <div className={`${style.widget_itemCategory} text-white ms-2 border border-white rounded-lg px-3 py-2 font-size-13`}>تعليمي</div> */}
                    </div>
                    <div className={`${style.widget_itemImage}`}>
                      <Link to={`/blogs/${blog.id}`}>
                        <Image
                          preview={false}
                          src={blog.image}
                          height={260}
                          alt={blog.title}
                        />
                      </Link>
                    </div>
                    <div className={`${style.widget_itemContent} rounded-b-sm px-6 py-4`}>
                      <h4 className={`${style.widget_itemTitle} mb-3 font-bold`}>
                        <Link className="text-black" to={`/blogs/${blog.id}`}>{blog.title}</Link>
                      </h4>
                      <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center">
                          <Avatar size={26} shape="circle" src={blog?.systemAdmin?.avatar} />
                          <h5 className="font-bold text-black ms-3">{blog?.systemAdmin?.name}</h5>
                        </div>
                        <h6 className="text-gray-500 font-size-12">{blog?.systemAdmin?.created_at}</h6>
                      </div>
                      <div
                        className={`${style.widget_itemText} mb-3 font-bold`}
                        dangerouslySetInnerHTML={{ __html: blog.body }}
                      ></div>
                      <h5 className="text-center">
                        <Link className="text-primary font-bold" to={`/blogs/${blog.id}`}>{t('view_article')}</Link>
                      </h5>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <EmptyData />
          )}
          <Pagination
            align="end"
            className="my-3"
            onChange={(number) => pageinate(number)}
            defaultCurrent={1}
            current={UrlParams?.page ? Number(UrlParams?.page) : 1}
            total={resentBlogs?.data?.pagination?.total}
            pageSize={9}
        />
        </div>

      </section>
    </>
  );
}
