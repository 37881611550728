import Testimonial from 'components/Shared/Testimonial';
import TitlePage from 'components/Shared/TitlePage';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
function TestimonialsPage() {
  const {t} = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('nbt')} | {t('customer_reviews')}</title>
      </Helmet>
      <TitlePage title={t('customer_reviews')} bg={true} />
      <section className='py-8 mt-20'>
        <div className='container'>
          <Testimonial numberCard={9} pagination={true} distance={true} />
        </div>
      </section>
    </>
  );
};
export default TestimonialsPage;