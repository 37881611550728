import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';


const Breadcrumbs = ({title , NavLink}) => {
  const {t} = useTranslation();
  const breadcrumbItems = [
    {
      title: (
        <Link to="/dashboard">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          {t("dashboard_2")}
        </Link>
      ),
    },
    title && { title },
    NavLink && { title: NavLink },
  ].filter(Boolean);
  return <Breadcrumb items={breadcrumbItems} />;
};

export default Breadcrumbs;
